import { Component, OnInit, ViewChildren } from '@angular/core';
import { StepperComponent } from '../stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StudentFormComponent } from './student-form/student-form.component';
import { TutorFormComponent } from './tutor-form/tutor-form.component';
import { MentorFormComponent } from './mentor-form/mentor-form.component';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from '../../../components/date-picker/date-picker.component';
import { ContactFormComponent } from './contact-form/contact-form.component';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerComponent,
    StepperComponent,
    CdkStepperModule,
    TranslateModule,
    StudentFormComponent,
    MentorFormComponent,
    TutorFormComponent,
    ContactFormComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent implements OnInit {
  roleSelectionForm!: FormGroup;
  contactForm: FormGroup;

  @ViewChildren(StudentFormComponent)
  studentFormComponent!: StudentFormComponent;
  @ViewChildren(MentorFormComponent) mentorFormComponent!: MentorFormComponent;
  @ViewChildren(TutorFormComponent) tutorFormComponent!: TutorFormComponent;

  dataModal: any;

  qualifications: string[] = [
    'qualifications.Bac_or_equivalent',
    'qualifications.DUT',
    'qualifications.BTS',
    'qualifications.Licence',
    'qualifications.Master',
    'qualifications.Doctorate',
    'qualifications.Other',
    'qualifications.No_diploma',
  ];

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      // Fields for Person
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthAt: [new Date()],
      gender: ['', Validators.required],
      phone: [''],
      arrivedFrenchAt: [new Date()],
      email: ['', [Validators.required, Validators.email]],

      // Fields for Address
      postalAddress: ['', Validators.required],
      additionalAddress: [''],
      city: [''],
      zipCode: [''],
      region: [''],
      country: [''],
    });

    this.roleSelectionForm = this.fb.group({
      selectedRole: ['', Validators.required],
    });
  }

  ngOnInit(): void {}
}
