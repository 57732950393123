import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NewsComponent } from './news-item/news-item.component';
import { NewsModule } from '../../services/news-item/news-item.module';
import { NgFor, NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadContentComponent } from '../../components/load-content/load-content.component';
import { ActualitesService } from './actualites.service';
import { AppNews } from '../../models/app-news.model';

@Component({
  selector: 'app-actualites',
  standalone: true,
  imports: [
    TranslateModule,
    NewsComponent,
    NewsModule,
    NgFor,
    NgIf,
    MatProgressSpinnerModule,
    LoadContentComponent,
  ],
  templateUrl: './actualites.component.html',
  styleUrl: './actualites.component.scss',
})
export class ActualitesComponent {
  newsList: AppNews[] = [];
  isLoading = true;
  showError = false;

  constructor(private actualiteService: ActualitesService) {}

  ngOnInit() {
    this.actualiteService.getCollection().subscribe({
      next: (newsList) => {
        this.newsList = newsList;
        this.isLoading = false;
      },
      error: () => {
        this.showError = true;
        this.isLoading = false;
      },
    });
  }
}
