import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TutorMockDataService } from './tutor-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { ITutorService } from './I-tutor.service';
import { TutorService } from '../api/tutor.service';

@NgModule({
  providers: [
    {
      provide: ITutorService,
      useClass: environment.useMockData ? TutorMockDataService : TutorService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class TutorModule {}
