import { Student } from 'projects/front-end/src/app/models/DTO/student';
import { Observable } from 'rxjs';

export abstract class IStudentService {
  /**
   * Retrieves a Student resource.
   * Retrieves a Student resource.
   * @param id Student identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiStudentsIdGet(id: string): Observable<any>;

  /**
   * Creates a Student resource.
   * Creates a Student resource.
   * @param student The new Student resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiStudentsPost(student: Student): Observable<any>;

  /**
   * Updates a Student resource.
   * Updates a Student resource.
   * @param student The Student resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiStudentsIdPut(
    id: string,
    student: Student,
  ): Observable<any>;

  /**
   * Deletes a Student resource.
   * Deletes a Student resource.
   * @param id Student identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiStudentsDelete(id: string): Observable<any>;
}
