import { Injectable } from '@angular/core';
import { Staff } from 'projects/front-end/src/app/models/DTO/staff';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { StaffService } from '../../services/api/staff.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private data: Staff[] | null = null;

  constructor(private staffService: StaffService) {}

  getCollection(): Observable<Staff[]> {
    if (this.data) {
      return of(this.data);
    } else {
      const subject = new Subject<Staff[]>();

      this.staffService.apiStavesGetCollection().subscribe({
        next: (staff) => {
          this.data = staff;
          subject.next(staff);
        },
        error: (error) => {
          subject.error(error);
        },
      });

      return subject.asObservable();
    }
  }
}
