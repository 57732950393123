import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-office-hours',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './office-hours.component.html',
  styleUrl: './office-hours.component.scss',
})
export class OfficeHoursComponent {
  officeHoursPictures = [
    {
      name: 'Quatre étudiants accompagnés par Daradja',
      url: 'assets/images/officeHours/permanence-1.webp',
    },
    {
      name: 'Flyer des permanences de Daradja',
      url: 'assets/images/officeHours/permanence-2.webp',
    },
  ];
}
