<div class="container">
  <h1
    i18n="@@HOME_DARADJA_DESCRIPTION"
    class="font-title text-center text-4xl m-5 text-white bg-daradjaMagenta p-2"
  >
    {{ "HOME_DARADJA_DESCRIPTION" | translate }}
  </h1>
  <div class="flex flex-wrap">
    <div class="m-5 text-center">
      <img
        class="w-full m-auto rounded-lg"
        src="/assets/images/Etudiants-étrangers-Daradja.jpg"
      />
    </div>
  </div>
  <h2 i18n="@@HOME_JOIN_COMMUNITY" class="text-center text-3xl font-semibold m-5 text-daradjaMagenta">
    {{ "HOME_JOIN_COMMUNITY" | translate }}
  </h2>

  <div class="flex flex-wrap">
    <div class="actor p-2" *ngFor="let actor of communityActors">
      <app-community-actor
        [name]="actor + '.name'"
        [description]="actor + '.description'"
        [textButton]="actor + '.textButton'"
        [imageUrl]="actor + '.imageUrl'"
        [siteUrl]="actor + '.siteUrl'"
      ></app-community-actor>
    </div>
  </div>
  <h2 i18n="@@HOME_WHY_THIS_PROGRAM" class="text-center text-3xl font-semibold m-5 text-daradjaMagenta">
    {{ "HOME_WHY_THIS_PROGRAM" | translate }}
  </h2>
  <div class="text-center">
    <iframe
      class="rounded-lg"
      width="100%"
      height="500"
      src="https://www.youtube.com/embed/BTl64cbxO4c?si=SvJX4k22mF1IKDFm"
      title="Qu'est ce que Daradja ?"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <h2 i18n="@@HOME_THEY_SPEAK" class="text-center text-3xl font-semibold m-5 text-daradjaMagenta">
    {{ "HOME_THEY_SPEAK" | translate }}
  </h2>
  <div class="flex flex-wrap">
    <div class="witness" *ngFor="let witness of witnesses">
      <app-witness
        [name]="witness + '.name'"
        [role]="witness + '.role'"
        [description]="witness + '.description'"
        [imageUrl]="witness + '.imageUrl'"
      ></app-witness>
    </div>
  </div>

  <h2
    i18n="@@HOME_DARADJA_IN_FIGURES"
    class="text-center text-3xl font-semibold m-5 text-daradjaMagenta"
  >
    {{ "HOME_DARADJA_IN_FIGURES" | translate }}
  </h2>

  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt
            class="text-base leading-7 text-gray-600"
            i18n="@@HOME_SUPPORTED_STUDENTS"
          >
            {{ "HOME_SUPPORTED_STUDENTS" | translate }}
          </dt>
          <dd
            class="order-first text-3xl font-semibold tracking-tight text-daradjaMagenta sm:text-5xl"
          >
            {{ "HOME_SUPPORTED_STUDENTS_NUMBER" | translate }}
          </dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt
            class="text-base leading-7 text-gray-600"
            i18n="@@HOME_OFFICE_HOURS"
          >
            {{ "HOME_OFFICE_HOURS" | translate }}
          </dt>

          <dd
            class="order-first text-3xl font-semibold tracking-tight text-daradjaMagenta sm:text-5xl"
          >
            {{ "HOME_OFFICE_HOURS_NUMBER" | translate }}
          </dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt
            class="text-base leading-7 text-gray-600"
            i18n="@@HOME_ENGAGED_VOLUNTEERS"
          >
            {{ "HOME_ENGAGED_VOLUNTEERS" | translate }}
          </dt>

          <dd
            class="order-first text-3xl font-semibold tracking-tight text-daradjaMagenta sm:text-5xl"
            i18n="@@HOME_VOLUNTEERS_NUMBER"
          >
            {{ "HOME_VOLUNTEERS_NUMBER" | translate }}
          </dd>
        </div>
        <div class="mx-auto flex max-w-xs flex-col gap-y-4">
          <dt
            class="text-base leading-7 text-gray-600"
            i18n="@@HOME_REPRESENTED_NATIONALITIES"
          >
            {{ "HOME_REPRESENTED_NATIONALITIES" | translate }}
          </dt>

          <dd
            class="order-first text-3xl font-semibold tracking-tight text-daradjaMagenta sm:text-5xl"
            i18n="@@HOME_VOLUNTEERS_NATIONALITIES"
          >
            {{ "HOME_VOLUNTEERS_NATIONALITIES" | translate }}
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-semibold leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@HOME_BECOME_PARTNER">
        {{ "HOME_BECOME_PARTNER" | translate }}</span
      >

      <a class="text-daradjaMagenta" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>
</div>
