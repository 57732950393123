<div *ngIf="roleSelectionForm.get('selectedRole')!.value === 'ROLE_MENTOR'">
  <!-- Début Formulaire -->
  <form [formGroup]="mentorForm" class="mx-auto">
    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">
      <!-- Début Métier actuel -->
      <div class="col-span-full">
        <label
          for="work"
          i18n="@@FORM_MENTOR_WORK"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_WORK" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="work"
            id="work"
            placeholder="Ingénieur d'affaires"
            formControlName="occupation"
            autocomplete="work"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin Métier actuel -->
      <!-- Début Nombre d'années -->
      <div class="col-span-full">
        <label
          for="workingYears"
          i18n="@@FORM_MENTOR_WORKING_YEARS"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_WORKING_YEARS" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="number"
            name="workingYears"
            id="workingYears"
            placeholder="9"
            formControlName="yearExperience"
            autocomplete="workingYears"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin Nombre d'années -->
      <!-- Début Autres expériences -->
      <div class="col-span-full">
        <label
          for="experiences"
          i18n="@@FORM_MENTOR_EXPERIENCES"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_EXPERIENCES" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="experiences"
            id="experiences"
            placeholder="Ingénieur d'affaires"
            formControlName="otherExperience"
            autocomplete="experiences"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin Autres expériences -->
      <!-- Début Plus de détails -->
      <div class="sm:col-span-6">
        <label
          for="moreDetails"
          i18n="@@FORM_MENTOR_MORE"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_MORE" | translate }}
        </label>
        <div class="mt-2.5">
          <textarea
            name="moreDetails"
            id="moreDetails"
            formControlName="comment"
            rows="4"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          ></textarea>
        </div>
      </div>
      <!-- Fin  Plus de détails -->
      <!-- Début Hobbies -->
      <div class="sm:col-span-6">
        <label
          for="hobbies"
          i18n="@@FORM_MENTOR_HOBBIES"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_HOBBIES" | translate }}
        </label>
        <div class="mt-2.5">
          <textarea
            name="hobbies"
            id="hobbies"
            formControlName="hobbies"
            rows="4"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          ></textarea>
        </div>
      </div>
      <!-- Fin Hobbies -->
      <!-- Début Autre type de soutien à préciser -->
      <div class="col-span-full">
        <label
          for="moreHelp"
          i18n="@@FORM_MENTOR_MORE_HELP"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_MORE_HELP" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="moreHelp"
            id="moreHelp"
            placeholder="Comptabilité, démarches administratives, ..."
            formControlName="otherTypeSupport"
            autocomplete="moreHelp"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin Autre type de soutien à préciser -->
      <!-- Début  Heures à consacrer -->
      <div class="col-span-full">
        <label
          for="availableHours"
          i18n="@@FORM_MENTOR_AVAILABLE_HOURS"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_MENTOR_AVAILABLE_HOURS" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="number"
            name="availableHours"
            id="availableHours"
            placeholder="9"
            formControlName="workHours"
            autocomplete="availableHours"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin  Heures à consacrer -->

      <!-- Début Vous pouvez apporter du soutien à un étudiant par -->
      <div class="col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_MENTOR_HELP_BY"
          >
            {{ "FORM_MENTOR_HELP_BY" | translate }}
          </legend>
          <app-load-content
            [isLoading]="isLoading"
            [showError]="showError"
          ></app-load-content>
          <app-help-domain-list
            *ngIf="!isLoading"
            [canHelpStudentBy]="canHelpStudentBy"
          ></app-help-domain-list>
        </fieldset>
      </div>
      <!-- Fin Vous pouvez apporter du soutien à un étudiant par  -->

      <!-- Début Comment souhaitez-vous être recontacté·e par Daradja  -->
      <div class="col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_STUDENT_CONTACT_WAY"
          >
            {{ "FORM_STUDENT_CONTACT_WAY" | translate }}
          </legend>
          <div class="mt-6 space-y-6">
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="contactByMail"
                  name="contactByMail"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                  formControlName="contactByMail"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  i18n="@@FORM_STUDENT_WAY_MAIL"
                  for="comments"
                  class="font-medium text-gray-900"
                >
                  {{ "FORM_STUDENT_WAY_MAIL" | translate }}
                </label>
                <p class="text-gray-500" i18n="@@FORM_STUDENT_WAY_MAIL_MORE">
                  {{ "FORM_STUDENT_WAY_MAIL_MORE" | translate }}
                </p>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="contactByPhone"
                  name="contactByPhone"
                  formControlName="contactByPhone"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="contactByPhone"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_STUDENT_WAY_PHONE"
                >
                  {{ "FORM_STUDENT_WAY_PHONE" | translate }}</label
                >
                <p class="text-gray-500" i18n="@@FORM_STUDENT_WAY_PHONE_MORE">
                  {{ "FORM_STUDENT_WAY_PHONE_MORE" | translate }}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <!-- Fin Comment souhaitez-vous être recontacté·e par Daradja  -->

      <div>
        <label
          for="newpassword"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW"
          >{{ "CHANGE_PWD_NEW" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newpassword"
            name="newpassword"
            [type]="inputType"
            [(ngModel)]="newPassword"
            formControlName="newPassword"
            required
            (input)="onPasswordInput($event)"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
        <div
          *ngIf="strengthEnum"
          [ngClass]="{
            'text-daradjaAlert': strengthEnum === PasswordStrengthEnum().WEAK,
            'text-daradjaYellow':
              strengthEnum === PasswordStrengthEnum().MODERATE,
            'text-daradjaSuccess':
              strengthEnum === PasswordStrengthEnum().STRONG
          }"
        >
          Force du mot de passe : {{ strengthLabel | translate }}
        </div>
        <app-error-validation-label
          [control]="getNewPasswordControl"
        ></app-error-validation-label>
        <div class="text-daradjaAlert" *ngIf="newPasswordIsTheSame()">
          {{ "IS_SAME_PWD" | translate }}
        </div>
      </div>
      <div>
        <label
          for="newPasswordVerify"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW_VERIFY"
          >{{ "CHANGE_PWD_NEW_VERIFY" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newPasswordVerify"
            name="newPasswordVerify"
            [type]="inputType"
            [(ngModel)]="newPasswordVerify"
            formControlName="newPasswordVerify"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <app-error-validation-label
        [control]="getNewPasswordVerifyControl"
      ></app-error-validation-label>
      <div class="text-daradjaAlert" *ngIf="passwordMissmatch()">
        {{ "MISSMATCH_PWD" | translate }}
      </div>
      <div class="mt-2">
        <button
          (click)="toggleShowPassword()"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <svg
            *ngIf="!showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>

          <svg
            *ngIf="showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        </button>
      </div>

      <!-- Début By selecting this, you agree to our privacy policy. -->
      <div class="flex gap-x-4 sm:col-span-6">
        <div class="flex h-6 items-center">
          <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
          <button
            type="button"
            class="bg-gray-200 flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            role="switch"
            [class.bg-daradjaMagenta]="agreeToPolicies"
            [class.bg-gray-200]="!agreeToPolicies"
            aria-checked="false"
            (click)="toggleAgreeToPolicies()"
          >
            <span class="sr-only">Agree to policies</span>
            <!-- Enabled: "translate-x-3.5", Not Enabled: "translate-x-0" -->
            <span
              aria-hidden="true"
              [ngClass]="{
                'translate-x-3.5': agreeToPolicies,
                'translate-x-0': !agreeToPolicies
              }"
              class="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
            ></span>
          </button>
        </div>
        <label
          class="text-sm leading-6 text-gray-600"
          id="switch-1-label"
          i18n="@@FORM_STUDENT_POLICIES"
        >
          {{ "FORM_STUDENT_POLICIES" | translate
          }}<span class="text-daradjaAlert">*</span>
        </label>
      </div>
      <!-- Fin By selecting this, you agree to our privacy policy. -->
      <button
        cdkStepperPrevious
        class="btn text-white bg-daradjaMagenta rounded"
        i18n="@@FORM_PREVIOUS"
      >
        {{ "FORM_PREVIOUS" | translate }}
      </button>
      <button
        type="submit"
        class="btn text-white rounded"
        [ngClass]="{
          'bg-daradjaDisabled': isSubmitDisabled(),
          'bg-daradjaMagenta': !isSubmitDisabled()
        }"
        [disabled]="isSubmitDisabled()"
        i18n="@@FORM_SUBMIT"
        (click)="onSubmit()"
      >
        {{ "FORM_SUBMIT" | translate }}
      </button>
    </div>
  </form>
</div>
