<div *ngIf="control && control.invalid && (control.dirty || control.touched)">
  <div class="text-daradjaAlert" *ngIf="control.errors?.['required']">
    {{ "REQUIRED" | translate }}
  </div>
  <div class="text-daradjaAlert" *ngIf="control.errors?.['minlength']">
    {{
      "MIN_LENGTH_REQUIRED"
        | translate: { minlength: control.errors?.["minlength"].requiredLength }
    }}
  </div>
  <div class="text-daradjaAlert" *ngIf="control.errors?.['maxlength']">
    Maximum length allowed is
    {{ control.errors?.["maxlength"].requiredLength }}.
  </div>
  <div class="text-daradjaAlert" *ngIf="control.errors?.['email']">
    {{ "FORM_STUDENT_EMAIL_WRONG_FORMAT" | translate }}
  </div>
</div>
