<div class="container">
  <h1 class="font-title text-3xl text-daradjaMagenta mb-5" i18n="@@SCHOOLS">
    {{ "SCHOOLS" | translate }}
  </h1>

  <div
    class="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10"
  >
    <img
      *ngFor="let schoolImage of schoolImages"
      src="{{ schoolImage.url }}"
      alt="{{ schoolImage.name }}"
      class="image-item rounded object-contain w-full h-full"
    />
  </div>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@HIGHER_ESTABLISHMENT_JOIN">
        {{ "HIGHER_ESTABLISHMENT_JOIN" | translate }}</span
      >
      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>

  <h1 class="font-title text-3xl text-daradjaMagenta mb-5 mt-10" i18n="@@PRIVATE_PARTNERS">
    {{ "PRIVATE_PARTNERS" | translate }}
  </h1>
  <div
    class="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10"
  >
    <img
      *ngFor="let privatePartnershipImage of privatePartnershipImages"
      src="{{ privatePartnershipImage.url }}"
      alt="{{ privatePartnershipImage.name }}"
      class="image-item rounded object-contain w-full h-full"
    />
  </div>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@COMPANY_JOIN"> {{ "COMPANY_JOIN" | translate }}</span
      ><br />
      <span i18n="@@CONTACT_US_ON"> {{ "CONTACT_US_ON" | translate }}</span>
      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        <span i18n="@@EMAIL_DARADJA"> ecrire&#64;daradja.fr</span>
      </a>
    </p>
  </div>

  <h1 i18n="@@INSTITUTIONAL_PARTNER" class="font-title text-3xl text-daradjaMagenta mb-5 mt-10">
    {{ "INSTITUTIONAL_PARTNER" | translate }}
  </h1>

  <div
    class="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-10"
  >
    <img
      *ngFor="let publicPartnershipImage of publicPartnershipImages"
      src="{{ publicPartnershipImage.url }}"
      alt="{{ publicPartnershipImage.name }}"
      class="image-item rounded object-contain w-full h-full"
    />
  </div>
</div>
