<div class="container">
  <h1 class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta">Promotion F</h1>

  <p class="mb-5">
    <span i18n="@@PROMOTIONF_P1_1">{{ "PROMOTIONF_P1_1" | translate }}</span>

    <span i18n="@@PROMOTIONF_P1_2_BOLD" class="font-semibold">
      {{ "PROMOTIONF_P1_2_BOLD" | translate }}
    </span>
    <span i18n="@@PROMOTIONF_P1_3"> {{ "PROMOTIONF_P1_3" | translate }}</span>
  </p>

  <p class="mb-5" i18n="@@PROMOTIONF_P2">
    {{ "PROMOTIONF_P2" | translate }}
  </p>

  <p class="mb-5">
    <span i18n="@@PROMOTIONF_P3_1">{{ "PROMOTIONF_P3_1" | translate }}</span>
    <span i18n="@@PROMOTIONF_P3_28_BOLD" class="font-semibold">
      {{ "PROMOTIONF_P3_28_BOLD" | translate }}</span
    ><span i18n="@@PROMOTIONF_P3_3">{{ "PROMOTIONF_P3_3" | translate }}</span>
  </p>

  <p class="mb-5">
    <span i18n="@@PROMOTIONF_P4_1">{{ "PROMOTIONF_P4_1" | translate }}</span>
    <span i18n="@@PROMOTIONF_P4_2_BOLD" class="font-semibold">{{
      "PROMOTIONF_P4_2_BOLD" | translate
    }}</span
    ><span i18n="@@PROMOTIONF_P4_3">{{ "PROMOTIONF_P4_3" | translate }}</span>
  </p>

  <h2
    class="mb-5 text-center font-semibold text-3xl text-daradjaMagenta"
    i18n="@@PROMOTIONF_TITLE_VIDEO"
  >
    {{ "PROMOTIONF_TITLE_VIDEO" | translate }}
  </h2>

  <div class="text-center mb-5">
    <iframe
      class="rounded-lg"
      width="100%"
      height="500"
      src="https://www.youtube.com/embed/DNN5FgSXSYI?si=7k-bGrC8PjrDs-eZ"
      title="Promo F by Daradja- Témoigage #1"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <p class="mb-5">
    <span i18n="@@PROMOTIONF_THANKS_1">
      {{ "PROMOTIONF_THANKS_1" | translate }}</span
    >
    <a
      target="_blank"
      href="https://fr.ulule.com/promo-f-by-daradja/"
      class="underline font-bold text-daradjaMain"
      i18n="@@PROMOTIONF_CROWDFUNDING"
    >
      {{ "PROMOTIONF_CROWDFUNDING" | translate }}</a
    ><span i18n="@@PROMOTIONF_THANKS_2">{{
      "PROMOTIONF_THANKS_2" | translate
    }}</span>
    <a
      target="_blank"
      href="https://fr.ulule.com/"
      class="underline font-bold text-daradjaMain"
      i18n="@@PROMOTIONF_ULULE"
    >
      {{ "PROMOTIONF_ULULE" | translate }}</a
    ><span i18n="@@PROMOTIONF_SG"> {{ "PROMOTIONF_SG" | translate }}</span>
  </p>

  <p class="mb-5" i18n="@@PROMOTIONF_CREDITS">
    {{ "PROMOTIONF_CREDITS" | translate }}
  </p>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@PROMOTIONF_MORE_DETAILS">
        {{ "PROMOTIONF_MORE_DETAILS" | translate }}</span
      >

      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>
</div>
