<div class="container">
  <h1 class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@OF_HRS_TITLE">
    {{ "OF_HRS_TITLE" | translate }}
  </h1>

  <p class="mt-5">
    <span i18n="@@OF_HRS_P1_1">
      {{ "OF_HRS_P1_1" | translate }}
    </span>
    <a
      i18n="@@OF_HRS_P1_2_BOLD"
      class="underline font-bold text-daradjaMain"
      href="https://www.facebook.com/MonDaradja/"
      target="_blank"
    >
      {{ "OF_HRS_P1_2_BOLD" | translate }}
    </a>
    <span i18n="@@OF_HRS_P1_3"> {{ "OF_HRS_P1_3" | translate }}</span>

    <a
      i18n="@@OF_HRS_P1_4_BOLD"
      class="underline font-bold text-daradjaMain"
      href="https://www.linkedin.com/company/daradja/"
      target="_blank"
    >
      {{ "OF_HRS_P1_4_BOLD" | translate }}</a
    >.
  </p>

  <p class="mt-5 font-semibold" i18n="@@OF_HRS_P2">
    {{ "OF_HRS_P2" | translate }}
  </p>

  <p class="mt-5" i18n="@@OF_HRS_P3">
    {{ "OF_HRS_P3" | translate }}
  </p>

  <div
    class="mt-5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-10"
  >
    <div
      *ngFor="let w of officeHoursPictures"
      class="relative overflow-hidden aspect-w-1 aspect-h-1"
    >
      <img
        src="{{ w.url }}"
        alt="{{ w.name }}"
        class="rounded object-fill w-full h-full"
      />
    </div>
  </div>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@OF_HRS_MORE"> {{ "OF_HRS_MORE" | translate }}</span>

      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>
</div>
