<section class="bg-white rounded shadow-lg">
	<header class="bg-gray-100 py-2 px-4 flex justify-center rounded-t">
		<div class="flex text-daradjaMagenta">
			<ng-container *ngFor="let step of steps; let i = index; let last = last;">
				<div class="relative" [ngClass]="{'pr-8 sm:pr-20': !last }">
					<div class="absolute inset-0 flex items-center" aria-hidden="true">
						<div class="h-0.5 w-full bg-daradjaMagenta"></div>
					</div>
					<span
					class="group relative w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full "

					[ngClass]="{
					'border-gray-300 hover:border-gray-400': !isValid(1) && selectedIndex !== 1,
					'text-white bg-daradjaMagenta border-daradjaMagenta hover:bg-daradjaMagenta': isValid(i),
					'border-daradjaMagenta hover:bg-daradjaMagenta': selectedIndex === i
					}  
					"
					>
						<ng-container *ngIf="selectedIndex === i">
							<span class="h-2.5 w-2.5 bg-daradjaMagenta rounded-full" aria-hidden="true"></span>
						</ng-container>
						<ng-container *ngIf="selectedIndex !== i">
							<ng-container *ngIf="isValid(i)">
								<svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
								</svg>
							</ng-container>
							<ng-container *ngIf="!isValid(i)">
								{{i + 1}}
							</ng-container>
						</ng-container>
          			</span>
				</div>
			</ng-container>
		</div>
	</header>

	<div class="p-4 ">

		<div [style.display]="selected ? 'block' : 'none'">
			<!-- Content from the CdkStep is projected here -->
			<ng-container [ngTemplateOutlet]="selected!.content">
			</ng-container>
		</div>
	</div>

	<footer class="bg-gray-100 py-2 px-4 rounded-b flex justify-center">
		<h2 i18n="@@FORM_STEP">{{ "FORM_STEP" | translate }} {{selectedIndex + 1}}/{{steps.length}}</h2>
	</footer>


</section>