import { AutoMap } from '@automapper/classes';
import { AppDaradjaObject } from './app-daradja-object.model';
export class AppAddress extends AppDaradjaObject {
  @AutoMap()
  postalAddress?: string;
  @AutoMap()
  additionalAddress?: string;
  @AutoMap()
  city?: string;
  @AutoMap()
  region?: string;
  @AutoMap()
  country?: string;
  @AutoMap()
  zipCode?: string;
}
