import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppVideo } from '../../models/app-video.model';
import { VideoModule } from '../../services/video/video.module';
import { NgFor, NgIf } from '@angular/common';
import { mapper } from '../../services/helpers/mapper';
import { VideoComponent } from '../../components/video/video.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadContentComponent } from '../../components/load-content/load-content.component';
import { MesVideosService } from './mes-videos.service';
import { Video } from 'projects/front-end/src/app/models/DTO/video';
@Component({
  selector: 'app-mes-videos',
  standalone: true,
  imports: [
    TranslateModule,
    VideoModule,
    NgFor,
    NgIf,
    VideoComponent,
    MatProgressSpinnerModule,
    LoadContentComponent,
  ],
  templateUrl: './mes-videos.component.html',
  styleUrl: './mes-videos.component.scss',
})
export class MesVideosComponent {
  public videos: AppVideo[] = [];
  isLoading = true;
  showError = false;

  constructor(private mesVideosService: MesVideosService) {}

  ngOnInit() {
    this.mesVideosService.getCollection().subscribe({
      next: (videos) => {
        this.videos = mapper.mapArray(videos, Video, AppVideo);
        this.isLoading = false;
      },
      error: () => {
        this.showError = true;
        this.isLoading = false;
      },
    });
  }
}
