import { AutoMap } from '@automapper/classes';
import { AppPerson } from './app-person.model';

export class AppTutor extends AppPerson {
  @AutoMap()
  school?: string;
  @AutoMap()
  lastDiploma?: string;
  @AutoMap()
  comment?: string;
  @AutoMap()
  subject?: string;
  @AutoMap()
  alwaysLivedInFrance?: boolean;
  @AutoMap()
  longestCountry?: string;
  @AutoMap()
  helpDomains?: Array<string>;
  @AutoMap()
  emailContact?: boolean;
  @AutoMap()
  telContact?: boolean;
}
