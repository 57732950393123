import { NgModule } from '@angular/core';
import { environment } from 'projects/front-end/src/environments/environment';
import { StaffMockDataService } from './staff-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { StaffService } from '../api/staff.service';

@NgModule({
  providers: [
    {
      provide: StaffService,
      useClass: environment.useMockData ? StaffMockDataService : StaffService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class StaffModule {}
