import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { DonationButtonComponent } from './components/donation/donation-button.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    RouterLink,
    RouterLinkActive,
    FooterComponent,
    TranslateModule,
    FlexLayoutModule,
    LanguageSelectorComponent,
    DonationButtonComponent,
  ],
})
export class AppComponent {
  isHandset: boolean | undefined;
  underMaintenance = environment.isUnderMaintenance;
  header: boolean = true;
  footer: boolean = true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang('fr');
    this.translateService.use('fr');
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data: any) => {
        this.getTitle(data.title);
        this.translateService.onLangChange.subscribe(() => {
          // Réagit au changement de langue
          this.getTitle(data.title);
        });

        this.header = data.header ?? true;
        this.footer = data.footer ?? true;
      });
  }

  getTitle(key: string) {
    this.translateService
      .get(key)
      .subscribe((val) => this.titleService.setTitle(val));
  }
}
