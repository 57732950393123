<div class="container">
  <h1
    class="font-title text-center text-3xl text-daradjaMagenta mb-10"
    i18n="@@TEAM_TITLE"
  >
    {{ "TEAM_TITLE" | translate }}
  </h1>
  <app-load-content
    [isLoading]="isLoading"
    [showError]="showError"
  ></app-load-content>

  <div class="flex flex-wrap">
    <div class="member" *ngFor="let member of team">
      <app-member
        [name]="member.name || ''"
        [role]="member.job || ''"
        [description]="member.description || ''"
        [englishRole]="member.englishJob || ''"
        [englishDescription]="member.englishDescription || ''"
        [imageUrl]="member.url || ''"
      ></app-member>
    </div>
  </div>

  <h1
    class="font-title text-center text-3xl text-daradjaMagenta mb-10"
    i18n="@@TEAM_TITLE_1"
  >
    {{ "TEAM_TITLE_1" | translate }}
  </h1>

  <p class="text-center m-3" i18n="@@TEAM_P1">
    {{ "TEAM_P1" | translate }}
  </p>

  <div class="flex flex-wrap">
    <div class="speaker" *ngFor="let speaker of speakers">
      <app-speaker
        [name]="speaker + '.name'"
        [role]="speaker + '.role'"
        [imageUrl]="speaker + '.imageUrl'"
      ></app-speaker>
    </div>
  </div>
</div>
