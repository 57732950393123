import { Observable } from 'rxjs';

export abstract class INewsService {
  /**
   * Retrieves a News resource.
   * Retrieves a News resource.
   * @param id News identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiNewsIdGet(id: string): Observable<any>;

  public abstract apiNewsGetCollection(page?: number): Observable<any>;
}
