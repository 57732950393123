import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'projects/front-end/src/environments/environment';
import { UserMockDataService } from './user-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { UserCustomService } from './user-custom.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: UserCustomService,
      useClass: environment.useMockData
        ? UserMockDataService
        : UserCustomService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class UserModule {}
