<!-- footer.component.html -->
<hr class="h-px bg-gray-200 border-0 dark:bg-gray-700" />

<footer class="footer bg-white">
  <div class="flex flex-wrap">
    <div class="footer-section">
      <div class="social-links">
        <a
          class="text-sm"
          href="https://www.facebook.com/MonDaradja/"
          target="_blank"
          ><i class="fa fa-facebook text-3xl text-daradjaMain"></i
        ></a>
        <a href="https://twitter.com/Daradja4" target="_blank"
          ><i class="text-daradjaMain text-3xl fa fa-twitter"></i
        ></a>
        <a href="https://fr.linkedin.com/company/daradja" target="_blank"
          ><i class="text-daradjaMain text-3xl fa fa-linkedin"></i
        ></a>
        <a
          href="https://www.instagram.com/association_daradja/?hl=fr"
          target="_blank"
          ><i class="text-daradjaMain text-3xl fa fa-instagram"></i
        ></a>
        <a
          href="https://www.youtube.com/channel/UCJJENKSE_WL6E-g7ZrOtFGA"
          target="_blank"
          ><i class="text-daradjaMain text-3xl fa fa-youtube"></i
        ></a>
      </div>
    </div>

    <div class="m-auto">
      <span class="text-black"> contact :</span>
      <a class="text-black" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr&nbsp;</a
      >
      <span class="text-black"
        >&copy; {{ currentYear }} Daradja {{ version }}</span
      >
    </div>

    <div class="footer-section">
      <div class="page-links">
        <a href="documents" routerLink="documents"
          ><span
            class="text-daradjaMain font-bold text-sm"
            i18n="@@FOOTER_DOCUMENTS"
            >{{ "FOOTER_DOCUMENTS" | translate }}</span
          >
        </a>
        <a routerLink="legal"
          ><span
            class="text-daradjaMain font-bold text-sm"
            i18n="@@FOOTER_POLICIES"
            >{{ "FOOTER_POLICIES" | translate }}</span
          ></a
        >
      </div>
    </div>
  </div>
</footer>
