import { Observable, Subject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';
import { INewsService } from './I-news-item.service';
import { AppNews } from '../../models/app-news.model';

@Injectable({
  providedIn: 'root',
})
export class NewsMockDataService implements INewsService {
  private throwError = false;
  private newss: AppNews[] = [
    {
      id: 0,
      imageUrl: 'assets/images/hafosa.webp',
      title: 'Nouveau site Daradja',
      description:
        'Le site daradja.fr a été mis à jour ! De nouvelles fonctionnalités sont disponibles ! Fait par les collaborateurs de Kolabee.',
      createdAt: new Date('2024-06-25'),
      user: 'Hafosa',
      ressourceLinkUrl: 'https://kolabee.com/',
      ressourceLinkLabel: 'Kolabee - Site officiel',
    },
  ];

  constructor(private mockHelperService: MockHelperService<AppNews[]>) {}
  public apiNewsIdGet(id: string): Observable<any> {
    return this.mockHelperService.simuleAppel(this.throwError, this.newss[0]);
  }

  public apiNewsGetCollection(page?: number): Observable<any> {
    return this.mockHelperService.simuleAppel(this.throwError, this.newss);
  }
}
