<div class="container">
  <app-load-content
    [isLoading]="isLoading"
    [showError]="showError"
  ></app-load-content>
  <div *ngFor="let jobSection of jobSections">
    <app-job-section [jobSection]="jobSection"></app-job-section>
  </div>
</div>

<div class="text-center" i18n="@@HIRING_CONTACT">
  <p class="text-lg">{{ "HIRING_CONTACT1" | translate }}</p>
  <p class="text-xl">
    {{ "HIRING_CONTACT2" | translate }}
    <a
      class="text-daradjaMagenta font-semibold"
      href="mailto: ecrire@daradja.fr"
      >ecrire&#64;daradja.fr</a
    >
  </p>
</div>
<br />
