import { Injectable } from '@angular/core';

@Injectable()
export class UserToken {}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor() {}

  canActivate(userRole: string | null, autorizedRoles: string[]): boolean {
    const role = userRole ?? '';
    if (autorizedRoles.length == 0 && role === '') return true;
    if (autorizedRoles.length > 0 && role === '') return false;
    return autorizedRoles.includes(role);
  }
}
