import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { IJobService } from '../../services/job/i-job.service';
import { AppJobSection } from '../../models/app-job-section.model';
import { NgFor } from '@angular/common';
import { LoadContentComponent } from '../../components/load-content/load-content.component';
import { JobSectionComponent } from '../../components/job-section/job-section.component';
import { JobModule } from '../../services/job/job.module';

@Component({
  selector: 'app-hiring',
  standalone: true,
  imports: [
    NgFor,
    TranslateModule,
    RouterLink,
    LoadContentComponent,
    JobSectionComponent,
    JobModule,
  ],
  templateUrl: './hiring.component.html',
  styleUrl: './hiring.component.scss',
})
export class HiringComponent {
  public jobSections: AppJobSection[] = [];
  isLoading = true;
  showError = false;

  constructor(private mesJobSectionsService: IJobService) {}

  ngOnInit() {
    this.mesJobSectionsService.apiJobSectionGetCollection().subscribe({
      next: (jobSections) => {
        this.jobSections = jobSections;
        this.isLoading = false;
      },
      error: () => {
        this.showError = true;
        this.isLoading = false;
      },
    });
  }
}
