import { AutoMap } from '@automapper/classes';
import { AppDaradjaObject } from './app-daradja-object.model';

export class AppNews extends AppDaradjaObject {
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  title?: string;
  @AutoMap()
  description?: string;
  @AutoMap()
  user?: string;
  @AutoMap()
  imageUrl?: string;
  @AutoMap()
  ressourceLinkLabel?: string;
  @AutoMap()
  ressourceLinkUrl?: string;

  @AutoMap()
  englishTitle?: string;
  @AutoMap()
  englishDescription?: string;
  @AutoMap()
  englishRessourceLinkLabel?: string;
}
