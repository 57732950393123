import { Observable } from 'rxjs';
import { Tutor } from '../../models/DTO/tutor';

export abstract class ITutorService {
  /**
   * Retrieves a Tutor resource.
   * Retrieves a Tutor resource.
   * @param id Tutor identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiTutorsIdGet(id: string): Observable<any>;

  /**
   * Creates a Tutor resource.
   * Creates a Tutor resource.
   * @param tutor The new Tutor resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiTutorsPost(tutor: Tutor): Observable<any>;

  /**
   * Updates a Tutor resource.
   * Updates a Tutor resource.
   * @param tutor The Tutor resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiTutorsIdPut(id: string, tutor: Tutor): Observable<any>;

  /**
   * Deletes a Tutor resource.
   * Deletes a Tutor resource.
   * @param id Tutor identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiTutorsDelete(id: string): Observable<any>;
}
