import {
  ApplicationConfig,
  InjectionToken,
  importProvidersFrom,
} from '@angular/core';
import {
  RouterModule,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
} from '@angular/router';

import { routes } from './app.routes';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { AuthenticationModule } from './services/authentication/authentication.module';

import { NgxTranslateModule } from './translate.module';

import { BASE_PATH } from 'openapi/ouput/front-end';
import { environment } from '../environments/environment';
import { authInterceptor } from './services/interceptor/authentication-interceptor';
import { getNames } from 'country-list';
import { StudentModule } from './services/student/student.module';
import { MentorModule } from './services/mentor/mentor.module';
import { TutorModule } from './services/tutor/tutor.module';
import { NewsModule } from './services/news-item/news-item.module';
import { VideoModule } from './services/video/video.module';
import { StaffModule } from './services/staff/staff.module';

export const COUNTRY_LIST = new InjectionToken<string[]>('countryList');
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes, withDebugTracing(), withComponentInputBinding()),
    provideNoopAnimations(),
    provideAnimationsAsync(),
    importProvidersFrom([
      AuthenticationModule,
      NgxTranslateModule,
      RouterModule.forRoot(routes, { useHash: true }),
      HttpClientModule,
      StudentModule,
      MentorModule,
      TutorModule,
      NewsModule,
      VideoModule,
      StaffModule,
    ]),
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: COUNTRY_LIST, useValue: getNames().sort() },
  ],
};
