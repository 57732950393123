import { Component } from '@angular/core';
import { ContactFormComponent } from '../stepper/form/contact-form/contact-form.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AppStudent } from '../../models/app-student.model';
import { mapper } from '../../services/helpers/mapper';
import { EnvoiModalService } from '../../components/envoi-modal/envoi-modal.service';
import { StorageService } from '../../services/authentication/storage.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserTypeEnum } from '../../constants/user-type.enum';
import { UserTypeHelperService } from '../../services/helpers/user-type-helper.service';
import { MentorProgramEnum } from '../../constants/mentor-program.enum';
import { LoadContentComponent } from '../../components/load-content/load-content.component';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContactFormComponent,
    TranslateModule,
    MatProgressSpinnerModule,
    LoadContentComponent,
  ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
})
export class AccountComponent {
  contactForm: FormGroup;
  mentorProgramForm: FormGroup;
  user: any = {};
  dataModal: any;
  isLoading = true;
  showError = false;
  notAvailableForTechAccount = false;
  userType: UserTypeEnum = UserTypeEnum.ROLE_USER;
  userId: string = '';
  mentorProgram: string = '';

  constructor(
    private fb: FormBuilder,
    private userTypeHelper: UserTypeHelperService,
    private envoiModalService: EnvoiModalService,
    private storageService: StorageService,
  ) {
    this.contactForm = this.fb.group({
      // Fields for Person
      firstName: [''],
      lastName: [''],
      birthAt: [new Date()],
      gender: [''],
      phone: [''],
      arrivedFrenchAt: [new Date()],
      email: ['', [Validators.email]],

      // Fields for Address
      postalAddress: [''],
      additionalAddress: [''],
      city: [''],
      zipCode: [''],
      region: [''],
      country: [''],
    });
    this.mentorProgramForm = this.fb.group({
      // Fields for Person
      mentorProgram: [''],
    });
    this.dataModal = {
      titre: 'MODAL_FORM_UPDATE_TITLE',
      successContent: 'MODAL_FORM_UPDATE_SUCCESS',
      errorContent: 'MODAL_FORM_SUBMIT_ERROR',
    };
  }

  ngOnInit() {
    this.userType = this.storageService.getUserType();
    this.userId = this.storageService.getUserId() || '';

    if (this.userType === UserTypeEnum.ROLE_USER) {
      this.notAvailableForTechAccount = true;
      this.isLoading = false;
    } else if (this.userId) {
      this.userTypeHelper.get(this.userId, this.userType).subscribe({
        next: (user) => {
          this.user = this.userTypeHelper.mapToApplicationModel(
            user,
            this.userType,
          );
          this.isLoading = false;
        },
        error: (error) => {
          this.showError = true;
          this.isLoading = false;
        },
      });
    }
  }

  onSubmit() {
    this.envoiModalService.open(this.dataModal);
    let successHandler = {
      next: () => {
        this.envoiModalService.showSuccess();
      },
      error: () => this.envoiModalService.showError(),
    };
    this.user.id = this.userId;
    this.userTypeHelper
      .put(
        this.userTypeHelper.mapToApiModel(this.user, this.userType),
        this.userType,
      )
      .subscribe(successHandler);
  }

  isSubmitDisabled(): boolean {
    return !(this.contactForm && this.contactForm.valid);
  }
}
