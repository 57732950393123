import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppMentor } from '../../models/app-mentor.model';
import { MockHelperService } from '../helpers/mock-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MentorMockDataService {
  private throwError = false;
  constructor(private mockHelperService: MockHelperService<AppMentor>) {}

  apiMentorsPost(mentor: AppMentor): Observable<AppMentor> {
    console.log(mentor);
    return this.mockHelperService.simuleAppel(this.throwError, mentor);
  }

  apiMentorsIdPut(id: string, mentor: AppMentor): Observable<AppMentor> {
    console.log(mentor);
    return this.mockHelperService.simuleAppel(this.throwError, mentor);
  }
}
