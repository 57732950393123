import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppHelpDomain } from '../../../models/app-help-domain';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

@Component({
  selector: 'app-help-domain-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './help-domain-item.component.html',
  styleUrl: './help-domain-item.component.scss',
})
export class HelpDomainItemComponent {
  @Input() helpDomainItem?: AppHelpDomain;
  @Output() check = new EventEmitter<any>();
  public labelName = '';
  private checked = false;

  constructor(private translateService: TranslateService) {}

  public onCheck() {
    this.checked = !this.checked;
    console.log(this.checked);
    this.check.emit({
      checked: this.checked,
      helpDomainItem: this.helpDomainItem,
    });
  }

  ngOnInit() {
    this.handleLangChange(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.handleLangChange(langChangeEvent.lang);
      },
    );
  }

  handleLangChange(lang: string) {
    if (lang === 'fr') {
      this.labelName = this.helpDomainItem?.name || '';
    }
    if (lang === 'en') {
      this.labelName = this.helpDomainItem?.englishName || '';
    }
  }
}
