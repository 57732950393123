<div class="container">
  <h1 class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@MENTORING_TITLE">
    {{ "MENTORING_TITLE" | translate }}
  </h1>

  <p class="mt-5" i18n="@@MENTORING_P1">
    {{ "MENTORING_P1" | translate }}
  </p>

  <p class="mt-5 font-semibold" i18n="@@MENTORING_P2">
    {{ "MENTORING_P2" | translate }}
  </p>

  <p class="mt-5" i18n="@@MENTORING_P3">
    {{ "MENTORING_P3" | translate }}
  </p>

  <p class="mt-5" i18n="@@MENTORING_P4">
    {{ "MENTORING_P4" | translate }}
  </p>

  <div
    class="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3"
  >
    <div
      *ngFor="let w of mentoringPictures"
      class="relative overflow-hidden aspect-w-1 aspect-h-1"
    >
      <img
        src="{{ w.url }}"
        alt="{{ w.name }}"
        (click)="openCarousel(w.url)"
        class="rounded object-cover w-full h-full"
      />
    </div>
  </div>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@MENTORING_MORE_DETAILS">
        {{ "MENTORING_MORE_DETAILS" | translate }}</span
      >

      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>
</div>
<app-images-carousel [images]="mentoringPictures"></app-images-carousel>
