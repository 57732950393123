import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-witness',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './witness.component.html',
  styleUrl: './witness.component.scss',
})
export class WitnessComponent {
  @Input()
  name!: string;
  @Input()
  role!: string;
  @Input()
  description!: string;
  @Input()
  imageUrl!: string;
}
