import { Injectable } from '@angular/core';
import { UserTypeEnum } from '../../constants/user-type.enum';
import { IStudentService } from '../student/I-student.service';
import { IMentorService } from '../mentor/I-mentor.service';
import { ITutorService } from '../tutor/I-tutor.service';
import { mapper } from './mapper';
import { AppStudent } from '../../models/app-student.model';
import { AppMentor } from '../../models/app-mentor.model';
import { AppTutor } from '../../models/app-tutor.model';
import { Student } from 'projects/front-end/src/app/models/DTO/student';
import { Mentor } from '../../models/DTO/mentor';
import { Tutor } from '../../models/DTO/tutor';

@Injectable({
  providedIn: 'root',
})
export class UserTypeHelperService {
  constructor(
    private studentService: IStudentService,
    private mentorService: IMentorService,
    private tutorService: ITutorService,
  ) {}

  get(id: string, userTypeEnum: UserTypeEnum) {
    switch (userTypeEnum) {
      case UserTypeEnum.ROLE_STUDENT:
        return this.studentService.apiStudentsIdGet(id);
      case UserTypeEnum.ROLE_MENTOR:
        return this.mentorService.apiMentorsIdGet(id);
      case UserTypeEnum.ROLE_TUTOR:
        return this.tutorService.apiTutorsIdGet(id);
    }
    throw Error("Service absent pour ce type d'utilisateur");
  }

  put(user: any, userTypeEnum: UserTypeEnum) {
    switch (userTypeEnum) {
      case UserTypeEnum.ROLE_STUDENT:
        return this.studentService.apiStudentsIdPut(user.id, user);
      case UserTypeEnum.ROLE_MENTOR:
        return this.mentorService.apiMentorsPost(user);
      case UserTypeEnum.ROLE_TUTOR:
        return this.tutorService.apiTutorsPost(user);
    }
    throw Error("Service absent pour ce type d'utilisateur");
  }

  mapToApiModel(user: any, userTypeEnum: UserTypeEnum) {
    switch (userTypeEnum) {
      case UserTypeEnum.ROLE_STUDENT:
        return mapper.map(user, AppStudent, Student);
      case UserTypeEnum.ROLE_MENTOR:
        return mapper.map(user, AppMentor, Mentor);
      case UserTypeEnum.ROLE_TUTOR:
        return mapper.map(user, AppTutor, Tutor);
    }
    throw Error("Service absent pour ce type d'utilisateur");
  }

  mapToApplicationModel(user: any, userTypeEnum: UserTypeEnum) {
    switch (userTypeEnum) {
      case UserTypeEnum.ROLE_STUDENT:
        return mapper.map(user, Student, AppStudent);
      case UserTypeEnum.ROLE_MENTOR:
        return mapper.map(user, Mentor, AppMentor);
      case UserTypeEnum.ROLE_TUTOR:
        return mapper.map(user, Tutor, AppTutor);
    }
    throw Error("Service absent pour ce type d'utilisateur");
  }
}
