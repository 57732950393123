import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommunityActorComponent } from '../../templates/community-actor/community-actor.component';
import { WitnessComponent } from '../../templates/witness/witness.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    CommunityActorComponent,
    WitnessComponent,
    TranslateModule,
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  communityActors: any = [];
  witnesses: any = [];
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.translate.get('communityActors').subscribe((communityActors: any) => {
      this.communityActors = Object.keys(communityActors).map(
        (key) => `communityActors.${key}`,
      );
    });

    this.translate.get('witnesses').subscribe((witnesses: any) => {
      this.witnesses = Object.keys(witnesses).map((key) => `witnesses.${key}`);
    });
  }
}
