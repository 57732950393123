import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-load-content',
  standalone: true,
  imports: [MatProgressSpinnerModule, MatIconModule, TranslateModule, NgIf],
  templateUrl: './load-content.component.html',
  styleUrl: './load-content.component.scss',
})
export class LoadContentComponent {
  @Input() isLoading = true;
  @Input() showError = false;
  @Input() errorContent =
    'Une erreur est survenue dans le chargement des données.';

  @Input() notAvailableForTechAccount = false;

  error(errorContent?: string) {
    this.isLoading = false;
    this.showError = true;
    if (errorContent) this.errorContent = errorContent;
  }
}
