import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnvoiModalComponent } from './envoi-modal.component';
@Injectable({
  providedIn: 'root',
})
export class EnvoiModalService {
  private envoiModalRef?: MatDialogRef<EnvoiModalComponent>;

  constructor(private dialog: MatDialog) {}

  open(inputData: any) {
    this.envoiModalRef = this.dialog.open(EnvoiModalComponent, {
      disableClose: true,
    });

    this.envoiModalRef.componentInstance.titre = inputData.titre;
    this.envoiModalRef.componentInstance.successContent =
      inputData.successContent;
    this.envoiModalRef.componentInstance.info = inputData.info;
    this.envoiModalRef.componentInstance.errorContent = inputData.errorContent;
    if (inputData.redirectAfterCloseUrl) {
      this.envoiModalRef.componentInstance.redirectAfterCloseUrl =
        inputData.redirectAfterCloseUrl;
    }
  }

  showSuccess(redirectUrlOnModalClose?: string) {
    this.envoiModalRef?.componentInstance.showSuccess(redirectUrlOnModalClose);
  }

  showError(redirectUrlOnModalClose?: string) {
    this.envoiModalRef?.componentInstance.showError(redirectUrlOnModalClose);
  }

  showCustomError(errorMessage: string, redirectUrlOnModalClose?: string) {
    if (this.envoiModalRef) {
      this.envoiModalRef.componentInstance.errorContent = errorMessage;
      this.envoiModalRef?.componentInstance.showError(redirectUrlOnModalClose);
    }
  }

  close() {
    this.dialog.closeAll();
  }
}
