import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { COUNTRY_LIST } from 'projects/front-end/src/app/app.config';
import { DatePickerComponent } from 'projects/front-end/src/app/components/date-picker/date-picker.component';
import { AppPerson } from 'projects/front-end/src/app/models/app-person.model';
@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    DatePickerComponent,
    CdkStepperModule,
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent {
  @Input() contactForm!: FormGroup;
  @Input() createMode = true;
  @Input() person: AppPerson = {
    firstname: '',
    lastname: '',
    birthAt: new Date(),
    gender: '',
    phone: '',
    arrivedFrenchAt: new Date(),
    email: '',
    address: {
      postalAddress: '',
      additionalAddress: '',
      city: '',
      region: '',
      country: '',
      zipCode: '',
    },
  };

  countries: string[] = [];

  constructor(@Inject(COUNTRY_LIST) countries: string[]) {
    this.countries = countries;
  }

  getDateOfBirth(): Date {
    return this.person.birthAt || new Date();
  }

  updateDateOfBirth(date: Date) {
    this.person.birthAt = date;
  }

  getDateArrival(): Date {
    return this.person.arrivedFrenchAt || new Date();
  }

  updateDateArrival(date: Date) {
    this.person.arrivedFrenchAt = date;
  }

  startYearBirth(): number {
    return new Date().getFullYear() - 60;
  }

  endYearBirth(): number {
    return new Date().getFullYear();
  }

  startYearArrival(): number {
    return new Date().getFullYear() - 50;
  }

  endYearArrival(): number {
    return new Date().getFullYear() + 10;
  }

  get firstName() {
    return this.contactForm.get('firstName');
  }

  get lastName() {
    return this.contactForm.get('lastName');
  }

  get postalAddress() {
    return this.contactForm.get('postalAddress');
  }

  get gender() {
    return this.contactForm.get('gender');
  }

  get email() {
    return this.contactForm.get('email');
  }
}
