<li class="flex justify-between gap-x-6 py-5">
  <div class="flex min-w-0 gap-x-4">
    <img
      *ngIf="news.imageUrl"
      class="h-12 w-12 flex-none rounded-full bg-gray-50"
      [src]="news.imageUrl"
      alt=""
    />
    <div class="min-w-0 flex-auto">
      <p class="text-sm font-semibold leading-6 text-gray-900">
        {{ translatedTitle }}
      </p>
      <p class="mt-1 text-xs leading-5 text-gray-500">
        {{ translatedDescription }}
      </p>
      <a
        *ngIf="news.ressourceLinkLabel"
        class="bg-daradjaMagenta text-white text-center px-4 py-2 rounded-md inline-block hover:bg-daradjaMagenta"
        [href]="news.ressourceLinkUrl"
      >
        {{ translatedRessourceLinkLabel }}
      </a>
    </div>
  </div>
  <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
    <p class="text-sm leading-6 text-gray-900">{{ news.user }}</p>
    <p class="mt-1 text-xs leading-5 text-gray-500">
      {{ news.createdAt | date: "d/MM/yyyy, à HH:mm" }}
    </p>
  </div>
</li>
