import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { Video } from 'projects/front-end/src/app/models/DTO/video';
import { VideoService } from '../../services/api/video.service';

@Injectable({
  providedIn: 'root',
})
export class MesVideosService {
  private data: Video[] | null = null;

  constructor(private videoService: VideoService) {}

  getCollection(): Observable<Video[]> {
    const subject = new Subject<Video[]>();

    if (this.data) {
      return of(this.data);
    } else {
      this.videoService.apiVideosGetCollection().subscribe({
        next: (video) => {
          this.data = video;
          subject.next(video);
        },
        error: (error) => {
          subject.error(error);
        },
      });
    }
    return subject.asObservable();
  }
}
