<div class="witness-container">
  <div class="witness-details">
    <img
      [src]="imageUrl | translate"
      alt="{{ name | translate }} Image"
      class="witness-image"
    />
    <h2 class="witness-name text-xl font-bold m-3">{{ name | translate }}</h2>
    <p class="witness-role m-3 italic text-daradjaMagenta">{{ role | translate }}</p>
    <p class="witness-description">{{ description | translate }}</p>
  </div>
</div>
