import { Observable, Subject, of } from 'rxjs';
import { AppVideo } from '../../models/app-video.model';
import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';

@Injectable({
  providedIn: 'root',
})
export class VideoMockDataService {
  private throwError = false;
  private videos: AppVideo[] = [
    {
      id: 0,
      url: 'https://www.youtube.com/embed/BTl64cbxO4c?si=SvJX4k22mF1IKDFm',
      fileType: 'video/mp4',
    },
    {
      id: 1,
      url: 'https://www.youtube.com/embed/u-JUSe2RY6Y?si=NkeQtKwzs89hJkVH',
      fileType: 'video/mp4',
    },
  ];

  constructor(private mockHelperService: MockHelperService<AppVideo[]>) {}

  public apiVideosGetCollection(page?: number): Observable<any> {
    return this.mockHelperService.simuleAppel(this.throwError, this.videos);
  }
}
