<div class="container">
  <h1
    class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta"
    i18n="NEWS"
  >
    {{ "NEWS" | translate }}
  </h1>
  <p>
    {{ "NEWS_DESCRIPTION" | translate }}
  </p>
  <app-load-content
    [isLoading]="isLoading"
    [showError]="showError"
  ></app-load-content>
  <ul
    role="list"
    class="divide-y divide-gray-100"
    *ngFor="let news of newsList"
  >
    <app-news-item [news]="news"></app-news-item>
  </ul>
</div>
