<div class="container">
  <h1
    class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta"
    i18n="@@MY_ACCOUNT"
  >
    {{ "MY_ACCOUNT" | translate }}
  </h1>
  <div *ngIf="!isLoading && !showError && !notAvailableForTechAccount">
    <app-contact-form
      [createMode]="false"
      [contactForm]="contactForm"
      [person]="user"
    ></app-contact-form>
    <!-- Début Programme mentorat -->
    <form [formGroup]="mentorProgramForm">
      <div *ngIf="user.mentorProgram" class="mt-6 col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_STUDENT_JOIN_MENTORING"
          >
            {{ "FORM_STUDENT_JOIN_MENTORING" | translate }}
          </legend>

          <div class="mt-6 space-y-6">
            <div class="flex items-center gap-x-3">
              <input
                formControlName="mentorProgram"
                id="yes-mentoring"
                type="radio"
                value="YES"
                [(ngModel)]="user.mentorProgram"
                class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              />
              <label
                for="yes-mentoring"
                i18n="@@FORM_STUDENT_YES_MENTORING"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {{ "FORM_STUDENT_YES_MENTORING" | translate }}</label
              >
            </div>
            <div class="flex items-center gap-x-3">
              <input
                formControlName="mentorProgram"
                id="no-mentoring"
                type="radio"
                value="NO"
                [(ngModel)]="user.mentorProgram"
                class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              />
              <label
                i18n="@@FORM_STUDENT_NO_MENTORING"
                for="no-mentoring"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {{ "FORM_STUDENT_NO_MENTORING" | translate }}</label
              >
            </div>
            <div class="flex items-center gap-x-3">
              <input
                formControlName="mentorProgram"
                id="may-mentoring"
                type="radio"
                value="MAY"
                [(ngModel)]="user.mentorProgram"
                class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              />
              <label
                i18n="@@FORM_STUDENT_MAY_MENTORING"
                for="may-mentoring"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {{ "FORM_STUDENT_MAY_MENTORING" | translate }}</label
              >
            </div>
          </div>
        </fieldset>
      </div>
    </form>
    <p class="mt-10 text-center text-sm text-gray-500">
      <!-- Fin Programme mentorat -->
      <button
        type="submit"
        class="bg-daradjaMagenta text-white text-center px-4 py-2 rounded-md inline-block hover:bg-daradjaMagenta"
        [ngClass]="{
          'bg-daradjaDisabled': isSubmitDisabled(),
          'bg-daradjaMagenta': !isSubmitDisabled()
        }"
        [disabled]="isSubmitDisabled()"
        i18n="@@FORM_SUBMIT"
        (click)="onSubmit()"
      >
        {{ "FORM_SUBMIT" | translate }}
      </button>
    </p>
  </div>
  <app-load-content
    [isLoading]="isLoading"
    [showError]="showError"
    [notAvailableForTechAccount]="notAvailableForTechAccount"
  ></app-load-content>
</div>
