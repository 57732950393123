<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    <a href="#">
      <img
        class="mx-auto h-30 w-auto"
        src="assets/images/Logo-Daradja-Moyen.png"
        alt="Your Company"
    /></a>
    <h2
      class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      i18n="@@LOG_LOG_ACCOUNT"
    >
      {{ "LOG_LOG_ACCOUNT" | translate }}
    </h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form [formGroup]="loginForm" class="space-y-6">
      <div>
        <label
          for="email"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@LOG_MAIL_LABEL"
          >{{ "LOG_MAIL_LABEL" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            formControlName="username"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label
            for="password"
            class="block text-sm font-medium leading-6 text-gray-900"
            i18n="@@LOG_PASSWORD_LABEL"
            >{{ "LOG_PASSWORD_LABEL" | translate }}</label
          >
          <div class="text-sm">
            <a
              href="#/forgot-password"
              class="font-semibold text-daradjaMain hover:text-daradjaMain"
              i18n="@@LOG_FORGOT_PWD"
              >{{ "LOG_FORGOT_PWD" | translate }}</a
            >
          </div>
        </div>
        <div class="mt-2">
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            formControlName="password"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div>
        <button
          i18n="@@LOG_LOGIN_BTN"
          type="submit"
          (click)="login()"
          class="flex w-full justify-center rounded-md bg-daradjaMain px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-daradjaMain focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-daradjaMain"
        >
          {{ "LOG_LOGIN_BTN" | translate }}
        </button>
      </div>
    </form>

    <p class="mt-10 text-center text-sm text-gray-500">
      <span i18n="@@LOG_NOT_MEMBER">{{ "LOG_NOT_MEMBER" | translate }}</span>
      <a
        i18n="@@LOG_JOIN"
        routerLink="/form"
        href="form"
        class="font-semibold leading-6 text-daradjaMain hover:text-daradjaMain"
      >
        {{ "LOG_JOIN" | translate }}</a
      >
    </p>
  </div>
</div>
