import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { INewsService } from '../../services/news-item/I-news-item.service';
import { AppNews } from '../../models/app-news.model';

@Injectable({
  providedIn: 'root',
})
export class ActualitesService {
  private data: AppNews[] | null = null;

  constructor(private newsService: INewsService) {}

  getCollection(): Observable<AppNews[]> {
    const subject = new Subject<AppNews[]>();

    if (this.data) {
      return of(this.data);
    } else {
      this.newsService.apiNewsGetCollection().subscribe({
        next: (news) => {
          this.data = news;
          subject.next(news);
        },
        error: (error) => {
          subject.error(error);
        },
      });
    }
    return subject.asObservable();
  }
}
