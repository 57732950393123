import { Observable, Subject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';
import { Student } from 'projects/front-end/src/app/models/DTO/student';

@Injectable({
  providedIn: 'root',
})
export class StudentMockDataService {
  private throwError = false;

  students = [
    {
      id: '1',
      email: 'superadmin@example.com',
      password: 'Secret1234',
      roles: ['ROLE_STUDENT'],
      gender: 'MALE',
      phone: '+33167133254',
      arrivedFrenchAt: '2009-03-03T00:00:00+00:00',
      firstname: 'Agnès',
      lastname: 'Parent',
      birthAt: '2006-04-09T00:00:00+00:00',
      activated: true,
      address: {
        id: 1,
        postalAddress: 'postalAddress',
        additionalAddress: 'additionalAddress',
        city: 'city',
        zipCode: 'zipCode',
        region: 'region',
        country: 'Albania',
      },
      mentorProgram: undefined,
    },
  ];

  constructor(private mockHelperService: MockHelperService<Student>) {}

  apiStudentsPost(student: Student): Observable<Student> {
    console.log(student);
    return this.mockHelperService.simuleAppel(this.throwError, student);
  }

  apiStudentsIdGet(id: string) {
    return this.mockHelperService.simuleAppel(
      this.throwError,
      this.students[0],
    );
  }

  apiStudentsIdPut(student: Student): Observable<any> {
    console.log(student);
    return this.mockHelperService.simuleAppel(this.throwError, student);
  }

  apiStudentsDelete(id: string) {
    console.log(id);
    return this.mockHelperService.simuleAppel(this.throwError, id);
  }
}
