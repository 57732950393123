import { AutoMap } from '@automapper/classes';
import { AppUser } from './app-user.model';
export class AppPerson extends AppUser {
  @AutoMap()
  firstname?: string;
  @AutoMap()
  lastname?: string;
  @AutoMap()
  birthAt?: Date;
  @AutoMap()
  gender?: string;
  @AutoMap()
  phone?: string;
  @AutoMap()
  arrivedFrenchAt?: Date;
  @AutoMap()
  email?: string;
}
