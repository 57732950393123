import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberComponent } from '../../templates/member/member.component';
import { SpeakerComponent } from '../../templates/speaker/speaker.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StaffModule } from '../../services/staff/staff.module';
import { LoadContentComponent } from '../../components/load-content/load-content.component';
import { TeamService } from './team.service';
import { Staff } from 'projects/front-end/src/app/models/DTO/staff';

@Component({
  selector: 'app-team',
  standalone: true,
  imports: [
    CommonModule,
    MemberComponent,
    SpeakerComponent,
    TranslateModule,
    StaffModule,
    LoadContentComponent,
  ],
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent {
  members: any = [];
  speakers: any = [];
  team: Staff[] = [];
  isLoading = true;
  showError = false;
  constructor(
    private translate: TranslateService,
    private teamDataService: TeamService,
  ) {}

  ngOnInit() {
    this.teamDataService.getCollection().subscribe({
      next: (team) => {
        this.team = team;
        this.isLoading = false;
      },
      error: (error) => {
        this.showError = true;
        this.isLoading = false;
      },
    });

    this.getSpeakers();
  }

  getSpeakers() {
    this.translate.get('speakers').subscribe((translatedSpeakers: any) => {
      this.speakers = Object.keys(translatedSpeakers).map(
        (key) => `speakers.${key}`,
      );
    });
  }
}
