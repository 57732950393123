import { Injectable } from '@angular/core';
import { HttpAuthenticationService } from './http-authentication.service';
import { Router } from '@angular/router';
import { EnvoiModalService } from '../../components/envoi-modal/envoi-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import {
  DaradjaPayload,
  JwtHelperService,
} from '../helpers/jwt-helper.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  expLogginDate: number = 0;

  constructor(
    private router: Router,
    private httpAuthenticationService: HttpAuthenticationService,
    private envoiModalService: EnvoiModalService,
    private translateService: TranslateService,
    private storageService: StorageService,
    private jwtHelperService: JwtHelperService,
  ) {}

  public login(username: string, password: string): void {
    this.envoiModalService.open({
      titre: this.translateService.instant('MODAL_LOGIN_TITLE'),
      errorContent: this.translateService.instant('MODAL_LOGIN_ERROR'),
    });

    this.httpAuthenticationService.login(username, password).subscribe({
      next: (reponse) => {
        this.envoiModalService.close();
        this.saveTokenPayload(reponse.token || '');
        this.router.navigate(['/mes-videos']);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status == 401)
          this.envoiModalService.showCustomError(
            this.translateService.instant('BAD_CREDENTIALS'),
            '/connexion',
          );
        if (error.status >= 500 && error.status < 600)
          this.envoiModalService.showCustomError(
            this.translateService.instant('SERVER_ERROR'),
            '/connexion',
          );
      },
    });
  }

  public logout() {
    this.storageService.removeToken();
    this.expLogginDate = 0;
    this.router.navigate(['/connexion']);
  }

  public isLoggedIn(): boolean {
    let token = this.storageService.getToken();
    this.resetExpirationLoginDateFromToken(token);
    const currentTimestampInMilliseconds = Date.now();
    const currentTimestampInSeconds = Math.floor(
      currentTimestampInMilliseconds / 1000,
    );
    return this.expLogginDate > currentTimestampInSeconds;
  }

  resetExpirationLoginDateFromToken(token: string | null) {
    if (this.expLogginDate === 0 && token) {
      let payload: DaradjaPayload | null =
        this.jwtHelperService.decodeToken(token);
      if (payload && payload.exp) {
        this.expLogginDate = payload.exp;
      }
    }
  }

  private saveTokenPayload(token: string) {
    this.storageService.setToken(token);

    let payload: DaradjaPayload | null =
      this.jwtHelperService.decodeToken(token);

    if (payload && payload.roles && payload.roles.length > 0 && payload.exp) {
      this.expLogginDate = payload.exp;
      this.storageService.setUserId(payload.id.toString());
      this.storageService.setUserType(payload.roles);
    } else throw Error('Informations manquantes dans le token');
  }
}
