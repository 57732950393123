import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-date-picker',
  standalone: true,
  providers: [DatePipe],
  imports: [FormsModule, CommonModule, TranslateModule],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
})
export class DatePickerComponent implements OnInit {
  @Input() startYear: number = new Date().getFullYear() - 120;
  @Input() endYear: number = new Date().getFullYear() + 120;
  @Input() selectedDate: Date = new Date();
  @Output() selectedDateChange = new EventEmitter<Date>();

  MONTH_NAMES = [
    'months.january',
    'months.february',
    'months.march',
    'months.april',
    'months.may',
    'months.june',
    'months.july',
    'months.august',
    'months.september',
    'months.october',
    'months.november',
    'months.december',
  ];
  DAYS = [
    'days.sunday',
    'days.monday',
    'days.tuesday',
    'days.wednesday',
    'days.thursday',
    'days.friday',
    'days.saturday',
  ];

  showDatepicker = false;
  datepickerValue!: string;
  showYearPicker: boolean = false;
  month!: number; // !: mean promis it will not be null, and it will definitely be assigned
  year!: number;
  no_of_days = [] as number[];
  blankdays = [] as number[];
  datePipe: DatePipe;

  constructor(
    private elementRef: ElementRef,
    private translateService: TranslateService,
  ) {
    this.datePipe = new DatePipe(translateService.currentLang);
    translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.datepickerValue = this.formatDate(this.selectedDate, params.lang);
    });
  }

  ngOnInit(): void {
    this.initDate();
    this.getNoOfDays();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    // Vérifier si l'élément cliqué est en dehors du composant
    const clickedInside = this.elementRef.nativeElement.contains(event.target);

    // Si le clic est en dehors du composant, masquer le datepicker
    if (!clickedInside) {
      this.showDatepicker = false;
    }
  }

  initDate() {
    this.datepickerValue = this.formatDate(
      this.selectedDate,
      this.translateService.currentLang,
    );
  }

  formatDate(date: Date, currentLang: string): string {
    this.month = date.getMonth();
    this.year = date.getFullYear();
    this.datePipe = new DatePipe(currentLang);
    const formattedDate = this.datePipe.transform(
      new Date(this.year, this.month, date.getDate()),
      'fullDate',
    );
    return formattedDate ?? '';
  }

  getYears(): number[] {
    const years: number[] = [];

    for (let year = this.startYear; year <= this.endYear; year++) {
      years.push(year);
    }

    return years;
  }

  isToday(date: any) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return (
      today.getFullYear() === d.getFullYear() &&
      today.getMonth() === d.getMonth() &&
      today.getDate() === d.getDate()
    );
  }

  getDateValue(selectedDay: any) {
    const date = new Date(this.year, this.month, selectedDay);
    this.selectedDateChange.emit(date);
    this.datepickerValue = this.formatDate(
      date,
      this.translateService.currentLang,
    );
    this.showDatepicker = false;
  }

  getNoOfDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay();
    let blankdaysArray = [];
    for (var i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  trackByIdentity = (index: number, item: any) => item;

  onYearChange() {
    this.getNoOfDays();
  }
}
