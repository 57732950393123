import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  footerExpanded = false;
  currentYear: number | undefined;
  version = '1.0.7';

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

  toggleFooter() {
    this.footerExpanded = !this.footerExpanded;
    const footer = document.querySelector('.mat-toolbar') as HTMLElement;
    if (footer) {
      footer.style.height = this.footerExpanded ? 'auto' : '50px';
    }
  }
}
