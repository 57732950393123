<div class="w-full bg-white rounded-md overflow-hidden md:shadow-md">
  <img
    [src]="imageUrl | translate"
    alt="{{ name | translate }} Image"
    class="w-full max-md:mx-auto max-md:rounded-full max-md:w-auto"
  />

  <!-- Contenu texte -->
  <div class="flex flex-col justify-between p-4 h-full">
    <!-- Nom -->
    <h2 class="text-xl font-semibold mb-2 align-top">{{ name | translate }}</h2>

    <!-- Description -->
    <p class="text-gray-700 justify-center md:h-28 mb-4 align-top">
      {{ description | translate }}
    </p>

    <!-- Bouton de redirection -->
    <a
      routerLink="{{ siteUrl | translate }}"
      href="{{ siteUrl | translate }}"
      class="bg-daradjaMagenta text-white text-center px-4 py-2 rounded-md inline-block hover:bg-daradjaMain"
      >{{ textButton | translate }}</a
    >
  </div>
</div>
