/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
import { AutoMap } from '@automapper/classes';
import { AppAddress } from './app-address.model';
import { AppDaradjaObject } from './app-daradja-object.model';

export class AppUser extends AppDaradjaObject {
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  password?: string;
  @AutoMap()
  plainPassword?: string;
  @AutoMap()
  roles?: Array<string>;
  @AutoMap()
  resetToken?: string;

  address?: AppAddress;
  @AutoMap()
  videos?: Array<string>;
  @AutoMap()
  isActivated?: boolean;
  @AutoMap()
  readonly salt?: string;
  @AutoMap()
  readonly username?: string;
  @AutoMap()
  /**
   * The public representation of the user (e.g. a username, an email address, etc.)
   */
  readonly userIdentifier?: string;
  @AutoMap()
  video?: Array<string>;
  @AutoMap()
  readonly activated?: boolean;
}
