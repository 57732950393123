import { Component, Inject, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AppStudent } from 'projects/front-end/src/app/models/app-student.model';
import { StudentModule } from 'projects/front-end/src/app/services/student/student.module';
import { mapper } from 'projects/front-end/src/app/services/helpers/mapper';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from 'projects/front-end/src/app/components/date-picker/date-picker.component';
import { FormHelperService } from 'projects/front-end/src/app/services/helpers/form-helper.service';
import { EnvoiModalService } from 'projects/front-end/src/app/components/envoi-modal/envoi-modal.service';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MentorProgramEnum } from 'projects/front-end/src/app/constants/mentor-program.enum';
import { COUNTRY_LIST } from 'projects/front-end/src/app/app.config';
import { IStudentService } from 'projects/front-end/src/app/services/student/I-student.service';
import { Student } from 'projects/front-end/src/app/models/DTO/student';
import { PasswordStrengthEnum } from 'projects/front-end/src/app/constants/password-strengh.enum';
import { UserCustomService } from 'projects/front-end/src/app/services/user/user-custom.service';
import { AuthenticationService } from 'projects/front-end/src/app/services/authentication/authentication.service';
import { StorageService } from 'projects/front-end/src/app/services/authentication/storage.service';
import { JwtHelperService } from 'projects/front-end/src/app/services/helpers/jwt-helper.service';
import { Router } from '@angular/router';
import { ErrorValidationLabelComponent } from 'projects/front-end/src/app/components/error-validation-label/error-validation-label.component';

@Component({
  selector: 'app-student-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    DatePickerComponent,
    StudentModule,
    TranslateModule,
    CdkStepperModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ErrorValidationLabelComponent,
  ],
  templateUrl: './student-form.component.html',
  styleUrl: './student-form.component.scss',
})
export class StudentFormComponent {
  studentForm: FormGroup;
  @Input() roleSelectionForm!: FormGroup;
  @Input() qualifications: string[] = [];
  @Input() contactForm!: FormGroup;
  countries: string[] = [];

  public agreeToPolicies: boolean = false;
  public emailContact: boolean = false;
  public telContact: boolean = false;

  constructor(
    private fb: FormBuilder,
    private studentService: IStudentService,
    private formHelper: FormHelperService,
    private envoiModalService: EnvoiModalService,
    @Inject(COUNTRY_LIST) countries: string[],
  ) {
    this.countries = countries;

    this.studentForm = this.fb.group({
      bacCountry: [],
      lastDiploma: [],
      titleDiploma: [],
      preparedDiploma: [],
      futureWork: [],
      school: [],
      comment: [''],
      mentorProgram: [],
      emailContact: [],
      telContact: [],
      newPassword: [
        this.newPassword,
        [Validators.required, Validators.minLength(12)],
      ],
      newPasswordVerify: [
        this.newPasswordVerify,
        [Validators.required, Validators.minLength(12)],
      ],
    });

    this.strengthEnum = PasswordStrengthEnum.WEAK;
  }

  onSubmit() {
    if (this.studentForm.valid) {
      let person = this.formHelper.createData(
        this.contactForm,
        this.roleSelectionForm,
      );
      let combinedData: AppStudent = {
        ...person,
        ...this.studentForm.value,
        telContact: this.telContact,
        emailContact: this.emailContact,
        plainPassword: this.newPassword,
      };
      let mappedData: Student = {
        ...mapper.map(combinedData, AppStudent, Student),
      };

      this.envoiModalService.open(this.formHelper.dataModal);
      let successHandler = {
        next: () => {
          this.envoiModalService.showSuccess();
        },
        error: () => this.envoiModalService.showError(),
      };
      console.log(combinedData);
      console.log(mappedData);
      this.studentService.apiStudentsPost(mappedData).subscribe(successHandler);
    }
  }
  toggleAgreeToPolicies() {
    this.agreeToPolicies = !this.agreeToPolicies;
  }

  isSubmitDisabled(): boolean {
    return !(
      this.studentForm &&
      this.studentForm.valid &&
      this.agreeToPolicies
    );
  }

  get MentorProgramEnum() {
    return MentorProgramEnum;
  }

  toggleEmailContact() {
    this.emailContact = !this.emailContact;
    console.log(this.emailContact);
  }

  toggleTelContact() {
    this.telContact = !this.telContact;
    console.log(this.telContact);
  }
  oldPassword: string = '';
  newPassword: string = '';
  newPasswordVerify: string = '';
  dataModal: any;
  strengthEnum: PasswordStrengthEnum;
  strengthLabel = '';
  showPassword = false;
  inputType = 'password';
  isLoggedIn = false;

  passwordMissmatch(): boolean {
    return (
      this.newPasswordVerify !== '' &&
      this.newPassword !== this.newPasswordVerify
    );
  }

  newPasswordIsTheSame(): boolean {
    return this.newPassword !== '' && this.newPassword === this.oldPassword;
  }

  get getOldPasswordControl() {
    return this.studentForm.get('oldPassword');
  }

  get getNewPasswordControl() {
    return this.studentForm.get('newPassword');
  }

  get getNewPasswordVerifyControl() {
    return this.studentForm.get('newPasswordVerify');
  }
  onPasswordInput(event: any) {
    const password = event.target.value;
    this.evaluatePasswordStrength(password);
  }

  evaluatePasswordStrength(password: string) {
    let strength = 0;
    if (password.length >= 8) {
      strength += 1;
    }
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }
    if (/[a-z]/.test(password)) {
      strength += 1;
    }
    if (/[0-9]/.test(password)) {
      strength += 1;
    }
    if (/[\W]/.test(password)) {
      strength += 1;
    }

    switch (strength) {
      case 3:
        this.strengthEnum = PasswordStrengthEnum.MODERATE;
        this.strengthLabel = 'PWD_MODERATE';
        break;
      case 4:
      case 5:
        this.strengthEnum = PasswordStrengthEnum.STRONG;
        this.strengthLabel = 'PWD_STRONG';
        break;
      default:
        this.strengthEnum = PasswordStrengthEnum.WEAK;
        this.strengthLabel = 'PWD_WEAK';
    }
  }

  PasswordStrengthEnum() {
    return PasswordStrengthEnum;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }
}
