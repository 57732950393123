import { Injectable } from '@angular/core';
import { JwtPayload, jwtDecode } from 'jwt-decode';

export interface DaradjaPayload extends JwtPayload {
  roles: string[];
  username: string;
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {
  constructor() {}

  decodeToken(token: string): DaradjaPayload | null {
    try {
      return jwtDecode(token);
    } catch (Error) {
      console.error('Erreur de décodage du token', Error);
      return null;
    }
  }
}
