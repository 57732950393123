import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MentorMockDataService } from './mentor-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { IMentorService } from './I-mentor.service';
import { MentorService } from '../api/mentor.service';

@NgModule({
  providers: [
    {
      provide: IMentorService,
      useClass: environment.useMockData ? MentorMockDataService : MentorService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class MentorModule {}
