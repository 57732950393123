<h2
  class="font-semibold text-3xl my-5 text-daradjaMagenta"
  i18n="@@HIRING_SUBTITLE_3"
>
  {{ label }}
</h2>

<div *ngFor="let job of jobSection?.jobs">
  <app-job [job]="job"></app-job>
</div>
