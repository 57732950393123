import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationMockDataService {
  public login(username: string, password: string): Observable<string> {
    let throwError = false;

    if (username !== 'daradjatest' || password !== 'daradjatest')
      throwError = true;

    const jetonSubject = new Subject<any>();
    setTimeout(() => {
      if (throwError) jetonSubject.error('Error');
      else jetonSubject.next({ token: 'jeton' });
    }, 1000);
    return jetonSubject.asObservable();
  }
}
