import { Injectable } from '@angular/core';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { DaradjaPayload } from './jwt-helper.service';

@Injectable({
  providedIn: 'root',
})
export class JwtHelperMockDataService {
  daradjaPayload: DaradjaPayload = {
    roles: ['ROLE_STUDENT'],
    username: 'student',
    id: 1,
    exp: 0,
  };
  constructor() {}

  decodeToken(token: string): DaradjaPayload | null {
    this.daradjaPayload.exp = Date.now().valueOf() + 1000 * 60 * 5;
    return this.daradjaPayload;
  }
}
