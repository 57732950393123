<div class="fixed top-0 right-0 p-4 flex items-center space-x-2">
  <div>
    <button
      id="dropdownDividerButton"
      data-dropdown-toggle="dropdownDivider"
      class="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-centerbg-white bg-daradjaMain"
      type="button"
      routerLink="don"
    >
      {{ "DONATION" | translate }}
    </button>

    <!-- Dropdown menu -->
  </div>
</div>
