import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ImageObject } from '../../utils/imageobject.interface';

@Component({
  selector: 'app-images-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './images-carousel.component.html',
  styleUrl: './images-carousel.component.scss',
})
export class ImagesCarouselComponent {
  @Input() images: ImageObject[] = [];
  currentImageIndex = 0;
  isVisible = false; // Flag to track visibility

  showPrev() {
    this.currentImageIndex =
      (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }

  showNext() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }

  closeCarousel() {
    this.isVisible = false;
  }

  openCarousel(url: string = '') {
    const index = this.getIndexByUrl(url);
    if (index !== -1) {
      this.currentImageIndex = index;
    }
    this.isVisible = true;
  }

  private getIndexByUrl(url: string): number {
    return this.images.findIndex((image) => image.url === url);
  }
}
