<div class="container">
  <h1
    class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta"
    i18n="@@MY_VIDEOS"
  >
    {{ "MY_VIDEOS" | translate }}
  </h1>
  <p>
    {{ "MY_VIDEOS_DESCRIPTION" | translate }}
  </p>
  <app-load-content
    [isLoading]="isLoading"
    [showError]="showError"
  ></app-load-content>
  <div *ngFor="let video of videos">
    <app-video [video]="video"></app-video>
  </div>
</div>
