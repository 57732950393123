import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../authentication/storage.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authToken = inject(StorageService).getToken();
  if (authToken != null) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + authToken);
    //Ajout du token à la  requête.
    const authReq = req.clone({ headers: headers });

    return next(authReq);
  }
  return next(req);
}
