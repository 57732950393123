<div class="container">
  <h1 class="font-title text-center p-2 text-3xl text-white bg-daradjaMagenta">{{ "JOIN_TITLE" | translate }}</h1>
  <p class="italic">{{ "JOIN_SUBTITLE" | translate }}</p>
  <br/>
  <p><span class="text-daradjaMagenta font-semibold">{{ "JOIN_P1_1_BOLD" | translate }} </span>{{ "JOIN_P1_1" | translate }}</p>
  <br/>
  <p>{{ "JOIN_P1_2" | translate }}</p>
  <br/>
  <p class="font-semibold">{{ "DONATION_TAX_CREDIT" | translate }}</p>
  <br/>
  <!-- IFRAME -->
  <div class="">
    <iframe id="haWidget" allowtransparency="true" scrolling="auto" src="https://www.helloasso.com/associations/daradja/adhesions/formulaire-adhesions-daradja-1/widget" style="width: 100%; height: 750px; border: none;"></iframe>
  </div>
  <!-- FIN IFRAME -->
  <h2 class="text-3xl text-daradjaMagenta font-semibold mb-5">{{ "JOIN_P2_TITLE" | translate }}</h2>
  <p>{{ "JOIN_P2_1" | translate }} <span class="underline">{{ "JOIN_P2_2" | translate }}</span></p>
  <br/>
  <p><span class="font-semibold">{{ "JOIN_P2_1_BOLD" | translate }} </span>{{ "JOIN_P2_3" | translate }}</p>
  <br/>
  <p><span class="font-semibold">{{ "JOIN_P2_2_BOLD" | translate }} </span>{{ "JOIN_P2_4" | translate }}</p>
  <br/>
  <div class="text-center">
    <hr class="h-px my-8 bg-black border-0" />
    <p>{{ "DONATION_QUESTION" | translate }} <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">ecrire&#64;daradja.fr</a></p>
  </div>
</div>
