<!-- image-carousel.component.html -->

<div
  id="gallery"
  class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex justify-center items-center z-50"
  *ngIf="isVisible"
>
  <!-- Close button in the top-right corner -->

  <!-- Carousel wrapper -->

  <!-- Dynamic images based on input array -->

  <div
    *ngFor="let image of images; let i = index"
    [class.hidden]="i !== currentImageIndex"
    data-carousel-item
    class="flex flex-col md:flex-row rounded p-5 max-w-90 w-full max-h-screen bg-white"
  >
    <div class="flex-1 md:flex-[2]">
      <img [src]="image.url" class="rounded object-contain max-w-full h-auto" alt="{{image.name}}" />
    </div>
    <div class="flex-1 p-5 image-description">
      <p class="text-2xl md:text-4xl text-daradjaMain">{{image.title}}</p>
      <br/>
      <p class="text-xl md:text-2xl italic">{{image.name}}</p>
    </div>
  </div>

  <!-- Slider controls -->

  <!-- Original Buttons for Web Version -->
  <button
    type="button"
    class="hidden md:flex absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
    data-carousel-next
    (click)="showNext()"
  >
    <span
      class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
    >
      <svg
        class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 9 4-4-4-4"
        />
      </svg>
      <span class="sr-only">Next</span>
    </span>
  </button>

  <button
    type="button"
    class="hidden md:flex absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
    data-carousel-prev
    (click)="showPrev()"
  >
    <span
      class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
    >
      <svg
        class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 1 1 5l4 4"
        />
      </svg>
      <span class="sr-only">Previous</span>
    </span>
  </button>

  <!-- Additional Buttons for Mobile Version -->

  <div class="md:hidden absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-between w-full px-4">
    <button
      type="button"
      class="flex items-center justify-center h-10 w-10 rounded-full shadow-md cursor-pointer focus:outline-none"
      data-carousel-prev
      (click)="showPrev()"
    >
      <span
        class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
      >
        <svg
          class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 1 1 5l4 4"
          />
        </svg>
        <span class="sr-only">Previous</span>
      </span>
    </button>
  
    <button
      type="button"
      class="flex items-center justify-center h-10 w-10 rounded-full shadow-md cursor-pointer focus:outline-none"
      data-carousel-next
      (click)="showNext()"
    >
      <span
        class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
      >
        <svg
          class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 9 4-4-4-4"
          />
        </svg>
        <span class="sr-only">Next</span>
      </span>
    </button>
  </div>

  <button
    type="button"
    class="absolute top-2 end-2 text-white cursor-pointer z-50"
    (click)="closeCarousel()"
  >
    <svg
      class="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
</div>
