<div class="container">
  <h1 class="font-title text-center p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@PARTNERS_TITLE">
    {{ "PARTNERS_TITLE" | translate }}
  </h1>

  <!-- Première section -->
  <div class="flex flex-wrap">
    <div class="text w-1/2 p-4">
        <h2 class="text-lg font-bold mt-5 mb-5 text-daradjaMagenta" i18n="@@PARTNERS_TITLE_1">
          {{ "PARTNERS_TITLE_1" | translate }}
        </h2>
          <span class="my-break" i18n="@@PARTNERS_P1_1">
            {{ "PARTNERS_P1_1" | translate}}
          </span>
          <span class="font-semibold" i18n="@@PARTNERS_P1_2_BOLD">
            {{ "PARTNERS_P1_2_BOLD" | translate}}
          </span>
          <span class="my-break" i18n="@@PARTNERS_P1_2">
            {{ "PARTNERS_P1_2" | translate}}
          </span>
          <span class="font-semibold" i18n="@@PARTNERS_P1_3_Bold">
            {{ "PARTNERS_P1_3_Bold" | translate}}
        </span>
        <span class="my-break" i18n="@@PARTNERS_P1_3">
          {{ "PARTNERS_P1_3" | translate}}
        </span>
        <span class="my-break"  i18n="@@PARTNERS_P1_4">
          {{ "PARTNERS_P1_4" | translate}}
        </span> 
        <span class="font-semibold text-daradjaMagenta" i18n="@@PARTNERS_P1_5">
          {{ "PARTNERS_P1_5" | translate}}
        </span>
        <span class="font-semibold text-daradjaMagenta" i18n="@@PARTNERS_P1_LINK_CONTACT">
          <a class="underline" href="mailto:ecrire@daradja.fr">{{ "PARTNERS_P1_LINK_CONTACT" | translate}}</a>
        </span>
      </div>

      <!-- Deuxième section -->
      <div class="text flex w-1/2">
        <div class="text p-4">
          <h2 class="text-lg font-bold mt-5 mb-5 text-daradjaMagenta" i18n="@@PARTNERS_P2">
            {{ "PARTNERS_P2" | translate}}
          </h2>
          <span class="my-break" i18n="@@PARTNERS_P2_1">
            {{ "PARTNERS_P2_1" | translate}}
          </span>
          <span class="my-break" i18n="@@PARTNERS_P2_2">
            {{ "PARTNERS_P2_2" | translate}}
          </span>
          <span class="font-semibold text-daradjaMagenta" i18n="@@PARTNERS_P2_3">
            {{ "PARTNERS_P2_3" | translate}}
          </span>
          <span class="font-semibold text-daradjaMagenta" i18n="@@PARTNERS_P1_LINK_CONTACT">
            <a class="underline" href="mailto:ecrire@daradja.fr">{{ "PARTNERS_P1_LINK_CONTACT" | translate}}</a>
          </span>
        </div>
    </div>
  </div>
</div>
