<div class="text-center mt-6" *ngIf="video">
  <iframe
    *ngIf="trustedUrl"
    class="rounded-lg"
    width="100%"
    height="500"
    [src]="trustedUrl"
    [title]="video.title"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
</div>
