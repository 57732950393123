import { AutoMap } from '@automapper/classes';

export class AppHelpDomain {
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  name?: string;
  @AutoMap()
  englishName?: string;
}
