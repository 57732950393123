import { AutoMap } from '@automapper/classes';
import { AppPerson } from './app-person.model';

export class AppMentor extends AppPerson {
  @AutoMap()
  workCity?: string;
  @AutoMap()
  occupation?: string;
  @AutoMap()
  otherExperience?: string;
  @AutoMap()
  comment?: string;
  @AutoMap()
  yearExperience?: string;
  @AutoMap()
  hobbies?: string;
  @AutoMap()
  otherTypeSupport?: string;
  @AutoMap()
  workHours?: string;
  @AutoMap()
  emailContact?: boolean;
  @AutoMap()
  telContact?: boolean;
  canHelpStudentBy?: Array<any>;
}
