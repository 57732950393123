<div class="container">
  <h1
    class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta"
    i18n="@@MEMBERS_TITLE"
  >
    {{ "MEMBERS_TITLE" | translate }}
  </h1>

  <ol class="list-decimal list-inside">
    <!-- STUDENTS -->
    <li class="font-semibold text-2xl text-daradjaMagenta">
      {{ "MEMBERS_STUD_TITLE" | translate }}
    </li>
    <p>{{ "MEMBERS_STUD_P1" | translate }}</p>
    <p>{{ "MEMBERS_STUD_P2" | translate }}</p>
    <br />
    <p class="font-semibold">{{ "MEMBERS_STUD_P1_BOLD" | translate }}</p>
    <br />
    <p>{{ "MEMBERS_STUD_P3" | translate }}</p>
    <br />

    <!-- MENTORS -->
    <li class="font-semibold text-2xl text-daradjaMagenta">
      {{ "VOLUNTEER_MENT_TITLE" | translate }}
    </li>
    <p>{{ "VOLUNTEER_MENT_P1" | translate }}</p>
    <br />
    <p class="font-semibold">{{ "VOLUNTEER_MENT_P2" | translate }}</p>
    <br />
    <p>{{ "VOLUNTEER_MENT_P3" | translate }}</p>
    <br />

    <!-- TUTORS -->
    <li class="font-semibold text-2xl text-daradjaMagenta">
      {{ "VOLUNTEER_TUTO_TITLE" | translate }}
    </li>
    <p>{{ "VOLUNTEER_TUTO_P1" | translate }}</p>
    <p class="font-semibold">{{ "VOLUNTEER_TUTO_P2" | translate }}</p>
    <br />
  </ol>
  <p class="mt-10 text-center text-sm text-gray-500">
    <span i18n="@@LOG_NOT_MEMBER">{{ "LOG_NOT_MEMBER" | translate }}</span>
  </p>
  <p class="mt-10 text-center text-sm text-gray-500">
    <a
      routerLink="/form"
      href="form"
      class="bg-daradjaMagenta text-white text-center px-4 py-2 rounded-md inline-block hover:bg-daradjaMagenta"
      >{{ "LOG_JOIN" | translate }}</a
    >
  </p>
</div>
