import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { StoryComponent } from './pages/story/story.component';
import { TeamComponent } from './pages/team/team.component';
import { EcosystemComponent } from './pages/ecosystem/ecosystem.component';
import { WorkshopsComponent } from './pages/workshops/workshops.component';
import { MentoringComponent } from './pages/mentoring/mentoring.component';
import { FpromotionComponent } from './pages/fpromotion/fpromotion.component';
import { OfficeHoursComponent } from './pages/office-hours/office-hours.component';
import { StudentGuideComponent } from './pages/student-guide/student-guide.component';
import { VolunteersComponent } from './pages/volunteers/volunteers.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { JoinComponent } from './pages/join/join.component';
import { HiringComponent } from './pages/hiring/hiring.component';
import { LoginComponent } from './pages/login/login.component';
import { LegalComponent } from './pages/legal/legal.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DonationComponent } from './pages/donation/donation.component';
import { MembersComponent } from './pages/members/members.component';
import { FormComponent } from './pages/stepper/form/form.component';
import { MesVideosComponent } from './pages/mes-videos/mes-videos.component';
import { authenticationGuard } from './services/authentication/authentication.guard';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ActualitesComponent } from './pages/actualites/actualites.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangementMdpComponent } from './pages/changement-mdp/changement-mdp.component';
import { AccountComponent } from './pages/account/account.component';
import { maintenanceGuard } from './services/maintenance/maintenance.guard';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';

export const routes: Routes = [
  { path: 'maintenance', component: MaintenanceComponent },
  {
    path: '',
    canMatch: [maintenanceGuard],
    children: [
      {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full',
      },
      {
        path: 'accueil',
        component: HomePageComponent,
        data: { title: 'HEADER_MENU_HOME' },
      },
      {
        path: 'histoire',
        component: StoryComponent,
        data: { title: 'HEADER_MENU_STORY' },
      },
      {
        path: 'equipe',
        component: TeamComponent,
        data: { title: 'HEADER_MENU_TEAM' },
      },
      {
        path: 'ecosysteme',
        component: EcosystemComponent,
        data: { title: 'HEADER_MENU_ECOSYSTEM' },
      },
      {
        path: 'ateliers',
        component: WorkshopsComponent,
        data: { title: 'HEADER_MENU_WORKSHOPS' },
      },
      {
        path: 'mentorat',
        component: MentoringComponent,
        data: { title: 'HEADER_MENU_MENTORING' },
      },
      {
        path: 'promof',
        component: FpromotionComponent,
        data: { title: 'HEADER_MENU_PROMOF' },
      },
      {
        path: 'permanences',
        component: OfficeHoursComponent,
        data: { title: 'HEADER_MENU_OFFICE_HOURS' },
      },
      {
        path: 'guide',
        component: StudentGuideComponent,
        data: { title: 'HEADER_MENU_STUDENT_GUIDE' },
      },
      {
        path: 'benevoles',
        component: VolunteersComponent,
        data: { title: 'HEADER_MENU_JOIN_AS_VOLUNTEER' },
      },
      {
        path: 'partenaires',
        component: PartnersComponent,
        data: { title: 'HEADER_MENU_JOIN_AS_PARTNER' },
      },
      {
        path: 'adherer',
        component: JoinComponent,
        data: { title: 'HEADER_MENU_JOIN_AS_MONEY' },
      },
      {
        path: 'recrutement',
        component: HiringComponent,
        data: { title: 'HEADER_MENU_HIRING' },
      },
      {
        path: 'connexion',
        component: LoginComponent,
        data: { title: 'HEADER_MENU_LOGIN', header: false, footer: false },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'HEADER_MENU_LOGIN', header: false, footer: false },
      },
      {
        path: 'change-password',
        component: ChangementMdpComponent,
        data: { title: 'HEADER_MENU_LOGIN', header: false, footer: false },
      },
      {
        path: 'legal',
        component: LegalComponent,
        data: { title: 'FOOTER_POLICIES' },
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        data: { title: 'FOOTER_DOCUMENTS' },
      },
      {
        path: 'don',
        component: DonationComponent,
        data: { title: 'DONATION' },
      },
      {
        path: 'members',
        component: MembersComponent,
        data: { title: 'HEADER_MENU_JOIN_AS_MEMBER' },
      },
      {
        path: 'form',
        component: FormComponent,
        data: { title: 'LOG_JOIN' },
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
        data: { title: 'FORBIDDEN' },
      },
      {
        path: 'mes-videos',
        component: MesVideosComponent,
        data: { title: 'MY_VIDEOS', roles: ['ROLE_USER'] },
        canActivate: [authenticationGuard],
      },
      {
        path: 'actualites',
        component: ActualitesComponent,
        data: { title: 'NEWS', roles: ['ROLE_USER'] },
        canActivate: [authenticationGuard],
      },
      {
        path: 'mon-compte',
        component: AccountComponent,
        data: { title: 'MY_ACCOUNT', roles: ['ROLE_USER'] },
        canActivate: [authenticationGuard],
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'PAGE_NOT_FOUND' },
      },
    ],
  },
];
