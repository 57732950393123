/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.7
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
import { AutoMap } from '@automapper/classes';

export class Mentor {
  @AutoMap()
  workCity?: string;
  @AutoMap()
  occupation?: string;
  @AutoMap()
  otherExperience?: string;
  @AutoMap()
  comment?: string;
  @AutoMap()
  yearExperience?: string;
  @AutoMap()
  hobbies?: string;
  @AutoMap()
  otherTypeSupport?: string;
  @AutoMap()
  workHours?: string;
  canHelpStudentBy?: Array<any>;
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  updatedAt?: string;
  @AutoMap()
  createdAt?: string;
  @AutoMap()
  email?: string;
  @AutoMap()
  password?: string;
  @AutoMap()
  plainPassword?: string;
  @AutoMap()
  roles?: Array<string>;
  @AutoMap()
  gender?: string;
  @AutoMap()
  phone?: string;
  @AutoMap()
  address?: string;
  @AutoMap()
  videos?: Array<string>;
  @AutoMap()
  isActivated?: boolean;
  @AutoMap()
  arrivedFrenchAt?: string;
  @AutoMap()
  firstname?: string;
  @AutoMap()
  lastname?: string;
  @AutoMap()
  birthAt?: string;
  @AutoMap()
  emailContact?: boolean;
  @AutoMap()
  telContact?: boolean;
  @AutoMap()
  news?: Array<string>;
  @AutoMap()
  readonly username?: string;
  @AutoMap()
  /**
   * The public representation of the user (e.g. a username, an email address, etc.)
   */
  readonly userIdentifier?: string;
  @AutoMap()
  video?: Array<string>;
  @AutoMap()
  readonly activated?: boolean;
}
