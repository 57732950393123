import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IJobService } from './i-job.service';
import { JobMockDataService } from './job-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';

@NgModule({
  providers: [
    {
      provide: IJobService,
      useClass: JobMockDataService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class JobModule {}
