import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-community-actor',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './community-actor.component.html',
  styleUrl: './community-actor.component.scss',
})
export class CommunityActorComponent {
  @Input()
  name!: string;
  @Input()
  description!: string;
  @Input()
  textButton!: string;
  @Input()
  imageUrl!: string;
  @Input()
  siteUrl!: string;
}
