<header class="bg-white">
  <nav
    class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 pl-0"
    aria-label="Global"
  >
    <div class="flex lg:flex-1 mr-10 max-lg:m-auto">
      <a href="#">
        <img
          class="h-35 mb-3.5"
          src="/assets/images/Logo-Daradja-Moyen.png"
          alt=""
        />
        <div class="w-full h-auto ml-5 -mt-5">
          <span
            class="block max-lg:text-center text-xs font-bold text-daradjaYellow"
            i18n="@@HEADER_SLOGAN_1"
            >{{ "HEADER_SLOGAN_1" | translate }}
          </span>
          <span
            class="block max-lg:text-center text-xs font-bold text-daradjaYellow"
            i18n="@@HEADER_SLOGAN_2"
            >{{ "HEADER_SLOGAN_2" | translate }}</span
          >
        </div>
      </a>
    </div>

    <div class="hidden lg:flex lg:gap-x-12">
      <!--Accueil -->
      <a
        routerLink="accueil"
        class="text-lg font-semibold leading-6 text-daradjaMain"
        i18n="@@HEADER_MENU_HOME"
        >{{ "HEADER_MENU_HOME" | translate }}</a
      >

      <!--Fin Accueil -->
      <!-- Mon Contenu -->
      <div *ngIf="isLoggedIn" class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-lg font-semibold leading-6 text-daradjaMain"
          aria-expanded="false"
          (mouseenter)="enterMenuMonContenu()"
          (mouseleave)="leaveMenuMonContenu()"
        >
          <span i18n="@@MY_CONTENT">{{ "MY_CONTENT" | translate }}</span>
          <svg
            class="h-5 w-5 flex-none text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          *ngIf="subMenuMonContenu || menuMonContenu"
          (mouseenter)="enterSubMenuMonContenu()"
          (mouseleave)="leaveSubMenuMonContenu()"
          class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
        >
          <div class="p-4">
            <!-- Qui sommes nous > Notre hisoire  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 group-hover:text-daradjaMain"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </div>
              <!-- Mon contenu > Mes vidéos  -->
              <div class="flex-auto">
                <a
                  routerLink="mes-videos"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@MY_VIDEOS">{{ "MY_VIDEOS" | translate }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Mon contenu > Mes vidéos  -->

            <!-- Mon contenu > Actualites  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 group-hover:text-daradjaMain"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
                  />
                </svg>
              </div>
              <!-- Mon contenu > Actualites  -->
              <div class="flex-auto">
                <a
                  routerLink="actualites"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@NEWS">{{ "NEWS" | translate }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Mon contenu > Actualites  -->
          </div>
        </div>
      </div>

      <!--Fin Accueil -->
      <!-- Qui sommes nous -->
      <div class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-lg font-semibold leading-6 text-daradjaMain"
          aria-expanded="false"
          (mouseenter)="enterMenuQui()"
          (mouseleave)="leaveMenuQui()"
        >
          <span i18n="@@HEADER_MENU_WHO">{{
            "HEADER_MENU_WHO" | translate
          }}</span>
          <svg
            class="h-5 w-5 flex-none text-gray-400 group-hover:text-daradjaMain"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <!--
          'Product' flyout menu, show/hide based on flyout menu state.

          Entering: "transition ease-out duration-200"
            From: "opacity-0 translate-y-1"
            To: "opacity-100 translate-y-0"
          Leaving: "transition ease-in duration-150"
            From: "opacity-100 translate-y-0"
            To: "opacity-0 translate-y-1"
        -->
        <div
          *ngIf="subMenuQui || menuQui"
          (mouseenter)="enterSubMenuQui()"
          (mouseleave)="leaveSubMenuQui()"
          class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
        >
          <div class="p-4">
            <!-- Qui sommes nous > Notre hisoire  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </div>
              <!-- Qui sommes nous > Notre hisoire  -->
              <div class="flex-auto">
                <a
                  routerLink="histoire"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_STORY">{{
                    "HEADER_MENU_STORY" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_STORY_DETAILS"
                >
                  {{ "HEADER_MENU_STORY_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!-- Fin Qui sommes nous > Notre hisoire  -->
            <!-- Qui sommes nous > Equipe -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="equipe"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_TEAM">{{
                    "HEADER_MENU_TEAM" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p class="mt-1 text-gray-600" i18n="@@HEADER_MENU_TEAM_DETAILS">
                  {{ "HEADER_MENU_TEAM_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!-- Fin Qui sommes nous > Equipe -->
            <!-- Qui sommes nous > Ecosystème -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="ecosysteme"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_ECOSYSTEM">
                    {{ "HEADER_MENU_ECOSYSTEM" | translate }}</span
                  >
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_ECOSYSTEM_DETAILS"
                >
                  {{ "HEADER_MENU_ECOSYSTEM_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!--Fin Qui sommes nous > Ecosystème -->
          </div>
        </div>
      </div>
      <!-- Fin Qui sommes nous -->
      <!-- Actions -->
      <div class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-lg font-semibold leading-6 text-daradjaMain"
          aria-expanded="false"
          (mouseenter)="enterMenuActions()"
          (mouseleave)="leaveMenuActions()"
        >
          <span i18n="@@HEADER_MENU_ACTIONS">{{
            "HEADER_MENU_ACTIONS" | translate
          }}</span>
          <svg
            class="h-5 w-5 flex-none text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          *ngIf="subMenuActions || menuActions"
          (mouseenter)="enterSubMenuActions()"
          (mouseleave)="leaveSubMenuActions()"
          class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
        >
          <div class="p-4">
            <!-- Qui sommes nous > Notre hisoire  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                  />
                </svg>
              </div>
              <!-- Actions > Ateliers  -->
              <div class="flex-auto">
                <a
                  routerLink="ateliers"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_WORKSHOPS">{{
                    "HEADER_MENU_WORKSHOPS" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_WORKSHOPS_DETAILS"
                >
                  {{ "HEADER_MENU_WORKSHOPS_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!-- Fin Actions > Ateliers  -->
            <!-- Actions > Programme mentorat -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="mentorat"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_MENTORING"></span>
                  {{ "HEADER_MENU_MENTORING" | translate }}
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_MENTORING_DETAILS"
                >
                  {{ "HEADER_MENU_MENTORING_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!-- Fin  Actions > Programme mentorat -->
            <!--  Actions > PromoF -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16V22"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15 19H9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="promof"
                  href="#"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_PROMOF">{{
                    "HEADER_MENU_PROMOF" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_PROMOF_DETAILS"
                >
                  {{ "HEADER_MENU_PROMOF_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!--Fin Actions > PromoF -->
            <!--  Actions > Permanences -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="permanences"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_OFFICE_HOURS">{{
                    "HEADER_MENU_OFFICE_HOURS" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_OFFICE_HOURS_DETAILS"
                >
                  {{ "HEADER_MENU_OFFICE_HOURS_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!--Fin Actions > Permanences -->
            <!--  Actions > Guide le l'étudiant -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="guide"
                  href="guide"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_STUDENT_GUIDE">{{
                    "HEADER_MENU_STUDENT_GUIDE" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
                <p
                  class="mt-1 text-gray-600"
                  i18n="@@HEADER_MENU_STUDENT_GUIDE_DETAILS"
                >
                  {{ "HEADER_MENU_STUDENT_GUIDE_DETAILS" | translate }}
                </p>
              </div>
            </div>
            <!--Fin Actions > Guide le l'étudiant  -->
          </div>
        </div>
      </div>
      <!-- Fin Actions -->
      <!-- Rejoindre communauté -->
      <div class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-lg font-semibold leading-6 text-daradjaMain"
          aria-expanded="false"
          (mouseenter)="enterMenuJoin()"
          (mouseleave)="leaveMenuJoin()"
        >
          <span i18n="@@HEADER_MENU_JOIN">{{
            "HEADER_MENU_JOIN" | translate
          }}</span>
          <svg
            class="h-5 w-5 flex-none text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div
          class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
          *ngIf="subMenuJoin || menuJoin"
          (mouseenter)="enterSubMenuJoin()"
          (mouseleave)="leaveSubMenuJoin()"
        >
          <div class="p-4">
            <!-- Rejoindre communauté  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="members"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_JOIN_AS_MEMBER">{{
                    "HEADER_MENU_JOIN_AS_MEMBER" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Rejoindre communauté  -->
            <!-- Rejoindre communauté > Bénévoles  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
                  />
                </svg>
              </div>

              <div class="flex-auto">
                <a
                  routerLink="benevoles"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_JOIN_AS_VOLUNTEER">{{
                    "HEADER_MENU_JOIN_AS_VOLUNTEER" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Rejoindre communauté > Bénévoles  -->
            <!--  Rejoindre communauté > Partenaires   -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="partenaires"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_JOIN_AS_PARTNER">{{
                    "HEADER_MENU_JOIN_AS_PARTNER" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Rejoindre communauté > Partenaires -->
            <!-- Rejoindre communauté > Adhérer  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  class="h-6 w-6 text-gray-600 group-hover:text-daradjaMain"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="adherer"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@HEADER_MENU_JOIN_AS_MONEY">{{
                    "HEADER_MENU_JOIN_AS_MONEY" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!--Fin Rejoindre communauté > Adhérer  -->
          </div>
        </div>
      </div>
      <!-- Fin Rejoindre communauté -->
      <!--Recrutement -->
      <a
        routerLink="recrutement"
        class="text-lg font-semibold leading-6 text-daradjaMain"
        i18n="@@HEADER_MENU_HIRING"
        >{{ "HEADER_MENU_HIRING" | translate }}</a
      >
      <!--Fin Recrutement -->
      <button
        *ngIf="!isLoggedIn"
        routerLink="connexion"
        class="text-sm font-semibold leading-6 text-gray-900"
        i18n="@@HEADER_MENU_LOGIN"
      >
        {{ "HEADER_MENU_LOGIN" | translate }} <span aria-hidden="true"></span>
      </button>

      <!--Fin Accueil -->
      <!-- Mon Contenu -->
      <div *ngIf="isLoggedIn" class="relative">
        <button
          type="button"
          class="flex items-center gap-x-1 text-lg font-semibold leading-6 text-daradjaMain"
          aria-expanded="false"
          (mouseenter)="enterMenuParametre()"
          (mouseleave)="leaveMenuParametre()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 group-hover:text-daradjaMain"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>

        <div
          *ngIf="subMenuParametre || menuParametre"
          (mouseenter)="enterSubMenuParametre()"
          (mouseleave)="leaveSubMenuParametre()"
          class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
        >
          <div class="p-4">
            <!-- Parametre > Deconnexion -->

            <!-- Parametre > Mes informations  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 group-hover:text-daradjaMain"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <button
                  *ngIf="isLoggedIn"
                  (click)="logout()"
                  class="text-sm font-semibold leading-6 text-gray-900"
                  i18n="@@HEADER_MENU_LOGIN"
                >
                  {{ "HEADER_MENU_LOGOUT" | translate }}
                  <span aria-hidden="true"></span>
                </button>
              </div>
            </div>
            <!-- Fin Parametre > Mes informations  -->

            <!-- Fin  Parametre > Deconnexion  -->

            <!-- Parametre > Mes informations  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 group-hover:text-daradjaMain"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="mon-compte"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@MY_ACCOUNT">{{
                    "MY_ACCOUNT" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Parametre > Mes informations  -->

            <!-- Parametre > Changer de mot de passe  -->
            <div
              class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div
                class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6 group-hover:text-daradjaMain"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </div>
              <div class="flex-auto">
                <a
                  routerLink="change-password"
                  class="block font-semibold text-gray-900"
                >
                  <span i18n="@@MY_CONTENT">{{
                    "CHANGE_PWD_TITLE" | translate
                  }}</span>
                  <span class="absolute inset-0"></span>
                </a>
              </div>
            </div>
            <!-- Fin Parametre > Changer de mot de passe  -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex lg:flex-1 lg:mr-40 max-lg:m-auto"></div>
    <div class="flex lg:hidden">
      <button
        type="button"
        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
        (click)="mobileMenuOpen = true"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </div>
  </nav>

  <!-- MOBILE -->
  <!-- Mobile menu, show/hide based on menu open state. -->
  <div *ngIf="mobileMenuOpen" class="lg:hidden" role="dialog" aria-modal="true">
    <!-- Background backdrop, show/hide based on slide-over state. -->
    <div class="fixed inset-0 z-10"></div>
    <div
      class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
    >
      <div class="flex items-center justify-between">
        <a href="#" class="-m-1.5 p-1.5">
          <img
            class="h-12 w-auto"
            src="/assets/images/Logo-Daradja-Moyen.png"
            alt=""
          />
        </a>
        <button
          (click)="mobileMenuOpen = false"
          type="button"
          class="-m-2.5 rounded-md p-2.5 text-gray-700"
        >
          <span class="sr-only">Close menu</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            <a
              (click)="mobileMenuOpen = false"
              routerLink="accueil"
              href="accueil"
              class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
              >{{ "HEADER_MENU_HOME" | translate }}</a
            >
            <div *ngIf="isLoggedIn" class="-mx-3">
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
                aria-controls="disclosure-1"
                aria-expanded="false"
              >
                {{ "MY_CONTENT" | translate }}
              </button>
              <!-- 'Product' sub-menu, show/hide based on menu state. -->
              <div class="mt-2 space-y-2" id="disclosure-1">
                <a
                  routerLink="mes-videos"
                  (click)="mobileMenuOpen = false"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "MY_VIDEOS" | translate }}</a
                >
                <a
                  routerLink="actualites"
                  (click)="mobileMenuOpen = false"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "NEWS" | translate }}</a
                >
              </div>
            </div>
            <div class="-mx-3">
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
                aria-controls="disclosure-1"
                aria-expanded="false"
              >
                {{ "HEADER_MENU_WHO" | translate }}

                <svg
                  class="h-5 w-5 flex-none"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <!-- 'Product' sub-menu, show/hide based on menu state. -->
              <div class="mt-2 space-y-2" id="disclosure-1">
                <a
                  routerLink="histoire"
                  (click)="mobileMenuOpen = false"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_STORY" | translate }}</a
                >
                <a
                  routerLink="equipe"
                  (click)="mobileMenuOpen = false"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_TEAM" | translate }}</a
                >
                <a
                  routerLink="ecosysteme"
                  (click)="mobileMenuOpen = false"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_ECOSYSTEM" | translate }}</a
                >
              </div>
            </div>

            <div class="-mx-3">
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
                aria-controls="disclosure-1"
                aria-expanded="false"
              >
                {{ "HEADER_MENU_ACTIONS" | translate }}
                <svg
                  class="h-5 w-5 flex-none"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <!-- 'Nos actions' sub-menu, show/hide based on menu state. -->
              <div class="mt-2 space-y-2" id="disclosure-1">
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="ateliers"
                  href="ateliers"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_WORKSHOPS" | translate }}
                </a>
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="mentorat"
                  href="mentorat"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_MENTORING" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="promof"
                  href="promof"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_PROMOF" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="permanences"
                  href="permanences"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_OFFICE_HOURS" | translate }}
                </a>
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="guide"
                  href="guide"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >{{ "HEADER_MENU_STUDENT_GUIDE" | translate }}</a
                >
              </div>
            </div>

            <div class="-mx-3">
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
                aria-controls="disclosure-1"
                aria-expanded="false"
              >
                {{ "HEADER_MENU_JOIN" | translate }}

                <!--
                  Expand/collapse icon, toggle classes based on menu open state.

                  Open: "rotate-180", Closed: ""
                -->
                <svg
                  class="h-5 w-5 flex-none"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <!-- 'Product' sub-menu, show/hide based on menu state. -->
              <div class="mt-2 space-y-2" id="disclosure-1">
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="members"
                  href="members"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "HEADER_MENU_JOIN_AS_MEMBER" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="benevoles"
                  href="benevoles"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "HEADER_MENU_JOIN_AS_VOLUNTEER" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="partenaires"
                  href="partenaires"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "HEADER_MENU_JOIN_AS_PARTNER" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="adherer"
                  href="adherer"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "HEADER_MENU_JOIN_AS_MONEY" | translate }}</a
                >
              </div>
            </div>

            <a
              href="recrutement"
              class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            >
              {{ "HEADER_MENU_HIRING" | translate }}</a
            >
          </div>
          <div class="py-6">
            <button
              *ngIf="!isLoggedIn"
              routerLink="connexion"
              class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            >
              {{ "HEADER_MENU_LOGIN" | translate }}
            </button>
            <div class="-mx-3" *ngIf="isLoggedIn">
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-daradjaMain hover:bg-gray-50"
                aria-controls="disclosure-1"
                aria-expanded="false"
              >
                {{ "PARAMETERS" | translate }}

                <!--
                  Expand/collapse icon, toggle classes based on menu open state.

                  Open: "rotate-180", Closed: ""
                -->
                <svg
                  class="h-5 w-5 flex-none"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <!-- 'Product' sub-menu, show/hide based on menu state. -->
              <div class="mt-2 space-y-2" id="disclosure-1">
                <button
                  (click)="logout()"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "HEADER_MENU_LOGOUT" | translate }}
                </button>
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="mon-compte"
                  href="mon-compte"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "MY_ACCOUNT" | translate }}</a
                >
                <a
                  (click)="mobileMenuOpen = false"
                  routerLink="change-password"
                  href="change-password"
                  class="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {{ "CHANGE_PWD_TITLE" | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
