import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpAuthenticationService } from './http-authentication.service';
import { AuthenticationMockDataService } from './authentication-mock-data.service';
import { JwtHelperService } from '../helpers/jwt-helper.service';
import { JwtHelperMockDataService } from '../helpers/jwt-helper-mock-data.service';

@NgModule({
  providers: [
    {
      provide: HttpAuthenticationService,
      useClass: environment.useMockData
        ? AuthenticationMockDataService
        : HttpAuthenticationService,
    },
    {
      provide: JwtHelperService,
      useClass: environment.useMockData
        ? JwtHelperMockDataService
        : JwtHelperService,
    },
  ],
})
export class AuthenticationModule {}
