import { Component, Input } from '@angular/core';
import { AppVideo } from '../../models/app-video.model';
import { NgIf } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  standalone: true,
  imports: [NgIf],
  templateUrl: './video.component.html',
  styleUrl: './video.component.scss',
})
export class VideoComponent {
  @Input() video?: AppVideo;
  trustedUrl: SafeUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.video && this.video.url)
      this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.video.url,
      );
  }
}
