<div class="fixed top-0 left-0 p-4 flex items-center space-x-2">
  <div>
    <button
      id="dropdownDividerButton"
      data-dropdown-toggle="dropdownDivider"
      class="text-daradjaMain font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-centerbg-white bg-white"
      type="button"
      (click)="openDropdown()"
    >
      {{ lang.toUpperCase() }}
      <svg
        class="w-2.5 h-2.5 ms-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </button>

    <!-- Dropdown menu -->
    <div
      id="dropdownDivider"
      [hidden]="!dropDownOpen"
      class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
    >
      <ul
        class="py-2 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownDividerButton"
      >
        <li>
          <button
            class="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            (click)="switchLanguage('fr')"
          >
            <span class="w-6 fi fi-fr mr-2"></span
            ><span> {{ "FRENCH" | translate }}</span>
          </button>
        </li>
        <li>
          <button
            (click)="switchLanguage('en')"
            class="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <span class="w-6 fi fi-gb mr-2"></span
            ><span> {{ "ENGLISH" | translate }} </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
