import { Injectable } from '@angular/core';
import { UserTypeEnum } from '../../constants/user-type.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private tokenKey = 'USER_TOKEN';
  private roleKey = 'USER_ROLE';
  private typeKey = 'USER_TYPE';
  private idKey = 'USER_ID';

  constructor() {}

  public getToken(): string | null {
    return sessionStorage.getItem(this.tokenKey);
  }
  public getRole(): string | null {
    if (sessionStorage.getItem(this.tokenKey)) return 'ROLE_USER';
    else return null;
  }

  public setToken(token: string) {
    sessionStorage.setItem(this.tokenKey, token);
  }

  /**
   * Attention, cette fonction ne fait rien en attendant qu'un bandeau de cookie soit présent
   */
  public setRole(role: string) {
    //Utiliser des cookies pour stocker le role, le local storage n'est pas suffisament sécurisé pour ça.
  }

  public removeToken() {
    sessionStorage.removeItem(this.tokenKey);
  }

  public removeRole() {
    sessionStorage.removeItem(this.tokenKey);
  }

  public getUserType(): UserTypeEnum {
    let userTypeString = sessionStorage.getItem(this.typeKey);
    switch (userTypeString) {
      case UserTypeEnum.ROLE_STUDENT:
        return UserTypeEnum.ROLE_STUDENT;
      case UserTypeEnum.ROLE_MENTOR:
        return UserTypeEnum.ROLE_MENTOR;
      case UserTypeEnum.ROLE_TUTOR:
        return UserTypeEnum.ROLE_TUTOR;
    }
    return UserTypeEnum.ROLE_USER;
  }

  public setUserType(roles: string[]) {
    if (roles.find((role) => role === UserTypeEnum.ROLE_STUDENT))
      sessionStorage.setItem(this.typeKey, UserTypeEnum.ROLE_STUDENT);
    if (roles.find((role) => role === UserTypeEnum.ROLE_MENTOR))
      sessionStorage.setItem(this.typeKey, UserTypeEnum.ROLE_MENTOR);
    if (roles.find((role) => role === UserTypeEnum.ROLE_TUTOR))
      sessionStorage.setItem(this.typeKey, UserTypeEnum.ROLE_TUTOR);
  }

  public removeUserType() {
    sessionStorage.removeItem(this.typeKey);
  }

  public getUserId() {
    return sessionStorage.getItem(this.idKey);
  }

  public setUserId(userId: string) {
    sessionStorage.setItem(this.idKey, userId);
  }

  public removeUserId() {
    sessionStorage.removeItem(this.idKey);
  }
}
