/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressUserWrite } from './addressUserWrite';


/**
 * 
 */
import { AutoMap } from "@automapper/classes";

export class StudentUserWrite { 
    @AutoMap()
    bacCountry?: string;
    @AutoMap()
    lastDiploma?: string;
    @AutoMap()
    titleDiploma?: string;
    @AutoMap()
    school?: string;
    @AutoMap()
    preparedDiploma?: string;
    @AutoMap()
    futureWork?: string;
    @AutoMap()
    comment?: string;
    @AutoMap()
    mentorProgram?: boolean;
    @AutoMap()
    updatedAt?: string;
    @AutoMap()
    createdAt?: string;
    @AutoMap()
    email?: string;
    @AutoMap()
    plainPassword?: string;
    @AutoMap()
    gender?: string;
    @AutoMap()
    phone?: string;
    @AutoMap()
    address?: AddressUserWrite;
    @AutoMap()
    arrivedFrenchAt?: string;
    @AutoMap()
    firstname?: string;
    @AutoMap()
    lastname?: string;
    @AutoMap()
    birthAt?: string;
    @AutoMap()
    emailContact?: boolean;
    @AutoMap()
    telContact?: boolean;
}

