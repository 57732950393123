import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RandomStringService {
  generateRandomString(length: number): string {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) =>
      ('0' + (byte & 0xff).toString(16)).slice(-2),
    ).join('');
  }

  generateRandomStrongPassword(): string {
    return (
      this.generateRandomString(2) +
      '#' +
      this.generateRandomString(1) +
      '!' +
      this.generateRandomString(2) +
      'à'
    );
  }
}
