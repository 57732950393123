import {
  createMapper,
  MappingProfile,
  forMember,
  mapFrom,
  createMap,
  namingConventions,
  CamelCaseNamingConvention,
  addProfile,
  typeConverter,
} from '@automapper/core';
import { classes } from '@automapper/classes';
import { AppAddress } from '../../models/app-address.model';
import { AppMentor } from '../../models/app-mentor.model';
import { AppStudent } from '../../models/app-student.model';
import { AppTutor } from '../../models/app-tutor.model';
import { AppUser } from '../../models/app-user.model';
import { MentorProgramEnum } from '../../constants/mentor-program.enum';
import { AppVideo } from '../../models/app-video.model';
import { Address } from 'projects/front-end/src/app/models/DTO/address';
import { Student } from 'projects/front-end/src/app/models/DTO/student';
import { User } from 'projects/front-end/src/app/models/DTO/user';
import { Video } from 'projects/front-end/src/app/models/DTO/video';
import { HelpDomain } from 'projects/front-end/src/app/models/DTO/helpDomain';
import { AppHelpDomain } from '../../models/app-help-domain';
import { Mentor } from '../../models/DTO/mentor';
import { Tutor } from '../../models/DTO/tutor';

function convertStringToDate(string: string): Date {
  if (!Number.isNaN(Date.parse(string))) return new Date(string);
  else return new Date();
}
const mapper = createMapper({
  strategyInitializer: classes(),
});

const daradjaProfile: MappingProfile = (mapper) => {
  createMap(mapper, AppAddress, Address);
  createMap(mapper, Address, AppAddress);

  createMap(mapper, HelpDomain, AppHelpDomain);
  createMap(mapper, AppHelpDomain, HelpDomain);

  createMap(
    mapper,
    AppStudent,
    Student,
    typeConverter(Date, String, (date) => date?.toDateString()),
    forMember(
      (d) => d.mentorProgram,
      mapFrom((value) => {
        switch (value.mentorProgram) {
          case MentorProgramEnum.YES:
            return true;
          case MentorProgramEnum.NO:
            return false;
          default:
            return null;
        }
      }),
    ),
    forMember(
      (d) => d.emailContact,
      mapFrom((value) => value.emailContact),
    ),
    forMember(
      (d) => d.address,
      mapFrom((value) => value.address),
    ),
  );
  createMap(
    mapper,
    Student,
    AppStudent,
    typeConverter(String, Date, (dateString) =>
      convertStringToDate(dateString),
    ),
    forMember(
      (d) => d.mentorProgram,
      mapFrom((value) => {
        switch (value.mentorProgram) {
          case true:
            return MentorProgramEnum.YES;
          case false:
            return MentorProgramEnum.NO;
          default:
            return MentorProgramEnum.MAY;
        }
      }),
    ),
    forMember(
      (d) => d.address,
      mapFrom((value) => value.address),
    ),
  );

  createMap(
    mapper,
    AppMentor,
    Mentor,
    typeConverter(Date, String, (date) => date?.toDateString()),
    forMember(
      (d) => d.yearExperience,
      mapFrom((value) => {
        return value.yearExperience?.toString();
      }),
    ),
    forMember(
      (d) => d.workHours,
      mapFrom((value) => {
        return value.workHours?.toString();
      }),
    ),
    forMember(
      (d) => d.canHelpStudentBy,
      mapFrom((value) => {
        return value.canHelpStudentBy;
      }),
    ),
  );

  createMap(
    mapper,
    Mentor,
    AppMentor,
    typeConverter(String, Date, (dateString) =>
      convertStringToDate(dateString),
    ),
    forMember(
      (d) => d.yearExperience,
      mapFrom((value) => {
        return value.yearExperience?.toString();
      }),
    ),
    forMember(
      (d) => d.workHours,
      mapFrom((value) => {
        return value.workHours?.toString();
      }),
    ),
  );
  createMap(
    mapper,
    Tutor,
    AppTutor,
    typeConverter(String, Date, (dateString) =>
      convertStringToDate(dateString),
    ),
  );
  createMap(
    mapper,
    AppTutor,
    Tutor,
    typeConverter(Date, String, (date) => date?.toDateString()),
  );

  createMap(
    mapper,
    AppUser,
    User,
    typeConverter(Date, String, (date) => date?.toDateString()),
    forMember(
      (d) => d.address,
      mapFrom((value) => {
        return value.address?.toString();
      }),
    ),
  );
  createMap(
    mapper,
    User,
    AppUser,
    typeConverter(String, Date, (dateString) =>
      convertStringToDate(dateString),
    ),
    forMember(
      (d) => d.address,
      mapFrom((value) => {
        return new AppAddress();
      }),
    ),
  );

  createMap(mapper, AppVideo, Video);
  createMap(mapper, Video, AppVideo);
};

addProfile(
  mapper,
  daradjaProfile,
  namingConventions(new CamelCaseNamingConvention()),
);

export { mapper };
