import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class IJobService {
  constructor() {}

  /**
   * Retrieves the collection of JobSection resources.
   * Retrieves the collection of JobSection resources.
   * @param page The collection page number
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiJobSectionGetCollection(): Observable<any>;

  /**
   * Retrieves a JobSection resource.
   * Retrieves a JobSection resource.
   * @param id JobSection identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiJobSectionIdGet(id: string): Observable<any>;
}
