import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';

@Component({
  selector: 'app-student-guide',
  standalone: true,
  imports: [PdfViewerModule, FormsModule, TranslateModule],
  templateUrl: './student-guide.component.html',
  styleUrl: './student-guide.component.scss',
})
export class StudentGuideComponent {
  urlPdf = '/assets/pdf/student_guide.pdf';
  pageNumber: number = 1;
  totalPages: number = 1;

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
  }

  afterPageChange(event: any) {
    // Update the input field when the page changes
    this.pageNumber = event;
  }

  ngOnInit() {}
}
