import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterOutlet, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatTabsModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    RouterOutlet,
    TranslateModule,
    RouterLink,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private authenticationService: AuthenticationService) {}

  public logout() {
    this.authenticationService.logout();
  }

  enterSubMenuMonContenu() {
    this.subMenuMonContenu = true;
  }
  async leaveSubMenuMonContenu() {
    this.subMenuMonContenu = false;
  }
  async enterMenuMonContenu() {
    this.menuMonContenu = true;
  }
  async leaveMenuMonContenu() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuMonContenu = false;
  }

  enterSubMenuQui() {
    this.subMenuQui = true;
  }
  async leaveSubMenuQui() {
    this.subMenuQui = false;
  }
  async enterMenuQui() {
    this.menuQui = true;
  }
  async leaveMenuQui() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuQui = false;
  }

  enterSubMenuActions() {
    this.subMenuActions = true;
  }
  async leaveSubMenuActions() {
    this.subMenuActions = false;
  }
  async enterMenuActions() {
    this.menuActions = true;
  }
  async leaveMenuActions() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuActions = false;
  }

  enterSubMenuJoin() {
    this.subMenuJoin = true;
  }
  async leaveSubMenuJoin() {
    this.subMenuJoin = false;
  }
  async enterMenuJoin() {
    this.menuJoin = true;
  }
  async leaveMenuJoin() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuJoin = false;
  }

  menuMonContenu: boolean = false;
  subMenuMonContenu: boolean = false;

  menuQui: boolean = false;
  subMenuQui: boolean = false;

  menuActions: boolean = false;
  subMenuActions: boolean = false;

  menuJoin: boolean = false;
  subMenuJoin: boolean = false;

  mobileMenuOpen: boolean = false;

  subMenuParametre: boolean = false;
  menuParametre: boolean = false;

  get isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  enterSubMenuParametre() {
    this.subMenuParametre = true;
  }
  async leaveSubMenuParametre() {
    this.subMenuParametre = false;
  }

  async enterMenuParametre() {
    this.menuParametre = true;
  }
  async leaveMenuParametre() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.menuParametre = false;
  }
}
