import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-error-validation-label',
  standalone: true,
  imports: [NgIf, TranslateModule],
  templateUrl: './error-validation-label.component.html',
  styleUrl: './error-validation-label.component.scss',
})
export class ErrorValidationLabelComponent {
  @Input()
  control!: AbstractControl<any, any> | null;

  constructor() {}
}
