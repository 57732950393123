<div class="container">
  <div class="bg-white">
    <div class="mx-auto text-center mb-5">
      <h1 class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@VOLUNTEER_TITLE">
        {{ "VOLUNTEER_TITLE" | translate }}
      </h1>

      <h2 class="font-semibold text-3xl text-daradjaMagenta">{{ "VOLUNTEER_SUBTITLE" | translate }}</h2>
    </div>
    <p>{{ "VOLUNTEER_P1" | translate }}</p>
    <p>{{ "VOLUNTEER_P2" | translate }}</p>
    <p>{{ "VOLUNTEER_P3" | translate }}</p>
    <p>{{ "VOLUNTEER_P4" | translate }}</p>
    <br/>

    <ol class="list-decimal list-inside">
      <li class="font-semibold text-2xl text-daradjaMagenta">{{ "VOLUNTEER_AMBA_TITLE" | translate }}</li>
      <p>{{ "VOLUNTEER_AMBA_P1" | translate }}</p>
      <br/>
      <p class="font-semibold">{{ "VOLUNTEER_AMBA_P2" | translate }}</p>

      <!-- IMPLEMENTER SVG ICI -->
      <div class="flex justify-evenly">
        <a href="/assets/pdf/charte_ambassadeurs.pdf" target="_blank">
          <img [src]="'VOLUNTEER_SVG1' | translate" alt="Description" />
        </a>
        <a href="/assets/pdf/charte_ambassadeurs.pdf" target="_blank">
          <img [src]="'VOLUNTEER_SVG2' | translate" alt="Description" /> 
        </a>
        <a href="/assets/pdf/charte_ambassadeurs.pdf" target="_blank">
          <img [src]="'VOLUNTEER_SVG3' | translate" alt="Description" />
        </a>
      </div>
      <!-- FIN SVG -->

      <div class="text-center">
        <p>{{ "VOLUNTEER_INSC" | translate }}<a class="text-daradjaMain underline font-bold" target="_blank" href="/assets/pdf/charte_ambassadeurs.pdf">{{ "VOLUNTEER_HERE" | translate }}</a>.</p>
        <p class="text-daradjaMain">-</p>
        <p>{{ "VOLUNTEER_AMBA_P3" | translate }}</p>
        <p class="text-daradjaMagenta">{{ "VOLUNTEER_AMBA_P4" | translate }} <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">ecrire&#64;daradja.fr</a></p>
      </div>
      <br/>

      <li class="font-semibold text-2xl text-daradjaMagenta">{{ "VOLUNTEER_MENT_TITLE" | translate }}</li>
      <p>{{ "VOLUNTEER_MENT_P1" | translate }}</p>
      <br/>
      <p class="font-semibold">{{ "VOLUNTEER_MENT_P2" | translate }}</p>
      <br/>
      <p>{{ "VOLUNTEER_MENT_P3" | translate }}</p>
      <br/>
      <div class="text-center">
        <p>{{ "VOLUNTEER_MENT_P4" | translate }}</p>
        <p>{{ "VOLUNTEER_INSC2" | translate }}<a class=" text-daradjaMainunderline font-bold" routerLink="/members">{{ "VOLUNTEER_HERE2" | translate }}</a>.</p>
      </div>
      <br/>

      <li class="font-semibold text-2xl text-daradjaMagenta">{{ "VOLUNTEER_TUTO_TITLE" | translate }}</li>
      <p>{{ "VOLUNTEER_TUTO_P1" | translate }}</p>
      <p class="font-semibold">{{ "VOLUNTEER_TUTO_P2" | translate }}</p>
      <br/>
      <div class="text-center">
        <p>{{ "VOLUNTEER_TUTO_P3" | translate }}</p>
        <p>{{ "VOLUNTEER_INSC3" | translate }}<a class="text-daradjaMain underline font-bold" routerLink="/members">{{ "VOLUNTEER_HERE" | translate }}</a>.</p>
      </div>
    </ol>
    <div class="mt-5 mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-3">
      <div *ngFor="let v of volunteers" class="relative overflow-hidden aspect-w-1 aspect-h-1">
        <img src="{{ v.url }}" alt="{{ v.name }}" class="rounded object-cover w-full h-full" (click)="openCarousel(v.url)" />
      </div>
    </div>
    <app-images-carousel [images]="volunteers"></app-images-carousel>
  </div>
</div>
