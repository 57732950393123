import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeJobEnum } from '../../constants/type-job.enum';
import { MockHelperService } from '../helpers/mock-helper.service';
import { AppJobSection } from '../../models/app-job-section.model';

@Injectable({
  providedIn: 'root',
})
export class JobMockDataService {
  throwError = false;
  constructor(private mockHelperService: MockHelperService<AppJobSection>) {}

  jobsSections = [
    {
      label: 'Missions en bénévolat',
      englishLabel: 'Volunteer Missions',
      type: TypeJobEnum.BENEVOLAT,
      jobs: [
        {
          label: 'Developeur',
          englishLabel: 'Developer',
        },
      ],
    },
  ];

  /**
   * Retrieves the collection of JobSection resources.
   * Retrieves the collection of JobSection resources.
   * @param page The collection page number
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobSectionGetCollection(): Observable<any> {
    return this.mockHelperService.simuleAppel(
      this.throwError,
      this.jobsSections,
    );
  }

  /**
   * Retrieves a JobSection resource.
   * Retrieves a JobSection resource.
   * @param id JobSection identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiJobSectionIdGet(id: string): Observable<any> {
    return this.mockHelperService.simuleAppel(
      this.throwError,
      this.jobsSections[0],
    );
  }
}
