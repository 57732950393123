import { Component, Input } from '@angular/core';
import { AppHelpDomain } from '../../models/app-help-domain';
import { NgFor } from '@angular/common';
import { HelpDomainItemComponent } from './help-domain-item/help-domain-item.component';

@Component({
  selector: 'app-help-domain-list',
  standalone: true,
  imports: [NgFor, HelpDomainItemComponent],
  templateUrl: './help-domain-list.component.html',
  styleUrl: './help-domain-list.component.scss',
})
export class HelpDomainListComponent {
  @Input() canHelpStudentBy?: Array<AppHelpDomain>;
  public selected = new Set<AppHelpDomain>();

  ngOnInit() {}

  onItemCheck(event: any) {
    console.log(event);
    if (event.checked) this.selected.add(event.helpDomainItem);
    else this.selected.delete(event.helpDomainItem);
  }
}
