import { Component, Input } from '@angular/core';
import { DatePipe, NgIf } from '@angular/common';
import { AppNews } from '../../../models/app-news.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news-item',
  standalone: true,
  imports: [DatePipe, NgIf],
  templateUrl: './news-item.component.html',
  styleUrl: './news-item.component.scss',
})
export class NewsComponent {
  @Input() news: AppNews = new AppNews();
  translatedTitle: string | undefined;
  translatedDescription: string | undefined;
  translatedRessourceLinkLabel: string | undefined;

  constructor(private translateService: TranslateService) {}
  ngOnInit() {
    this.handleLangChange(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.handleLangChange(langChangeEvent.lang);
      },
    );
  }

  handleLangChange(lang: string) {
    if (lang === 'fr') {
      this.translatedTitle = this.news.title;
      this.translatedDescription = this.news.description;
      this.translatedRessourceLinkLabel = this.news.ressourceLinkLabel;
    }
    if (lang === 'en') {
      this.translatedTitle = this.news.englishTitle;
      this.translatedDescription = this.news.englishDescription;
      this.translatedRessourceLinkLabel = this.news.englishRessourceLinkLabel;
    }
  }
}
