import { Component, ElementRef, HostListener } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent {
  lang: string = '';
  constructor(
    private translateService: TranslateService,
    private elementRef: ElementRef,
  ) {
    this.lang = this.translateService.currentLang;
  }
  switchLanguage(arg0: string) {
    this.translateService.use(arg0);
    this.lang = arg0;
    this.dropDownOpen = false;
  }
  dropDownOpen: boolean = false;

  openDropdown() {
    this.dropDownOpen = true;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const target = event.target as HTMLElement;

    if (
      !this.elementRef.nativeElement.contains(target) &&
      !this.elementRef.nativeElement
        .querySelector('#dropdownDivider')
        ?.contains(target)
    ) {
      this.dropDownOpen = false;
    }
  }
}
