<div class="container">
  <h1 class="font-title text-center p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@LEGAL_TITLE">
    {{ "LEGAL_TITLE" | translate }}
  </h1>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_1"> 
    {{"LEGAL_SUBTITLE_1" | translate }}
  </h2>

  <p class="my-break" i18n="@@LEGAL_P1">
    {{"LEGAL_P1" | translate }}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_2">
    {{"LEGAL_SUBTITLE_2" | translate }}
  </h2>

  <p class="my-break" i18n="@@LEGAL_P2">
   {{"LEGAL_P2"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_3">
    {{"LEGAL_SUBTITLE_3" | translate }}
  </h2>

  <p class="my-break" i18n="@@LEGAL_P3">
    {{"LEGAL_P3"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_4">
    {{"LEGAL_SUBTITLE_4" | translate }}
  </h2>

  <p class="my-break" i18n="@@LEGAL_P4">
    {{"LEGAL_P4"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_5">
    {{"LEGAL_SUBTITLE_5" | translate }}
  </h2>

  <p class="my-break" i18n="@@LEGAL_P5">
    {{"LEGAL_P5"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_6">
    {{"LEGAL_SUBTITLE_6" | translate }}
  </h2>

  <p i18n="@@LEGAL_P6">
    {{"LEGAL_P6"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_7">
    {{"LEGAL_SUBTITLE_7" | translate }}
  </h2>

  <p i18n="@@LEGAL_P7">
    {{"LEGAL_P7"| translate}}
  </p>

  <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@LEGAL_SUBTITLE_8">
    {{"LEGAL_SUBTITLE_8" | translate }}
  </h2>

  <p i18n="@@LEGAL_P8">
    {{"LEGAL_P8"| translate}}
  </p>
</div>
