import { Component, Input } from '@angular/core';
import { AppJob } from '../../../models/app-job.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-job',
  standalone: true,
  imports: [],
  templateUrl: './job.component.html',
  styleUrl: './job.component.scss',
})
export class JobComponent {
  @Input() job?: AppJob;
  label = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.handleLangChange(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.handleLangChange(langChangeEvent.lang);
      },
    );
  }

  handleLangChange(lang: string) {
    if (lang === 'fr') {
      this.label = this.job?.label || '';
    }
    if (lang === 'en') {
      this.label = this.job?.englishLabel || '';
    }
  }
}
