import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePickerComponent } from '../../components/date-picker/date-picker.component';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { getNames } from 'country-list';
import { RouterLink } from '@angular/router';
import { ImagesCarouselComponent } from '../../components/images-carousel/images-carousel.component';

@Component({
  selector: 'app-volunteers',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerComponent,
    TranslateModule,
    RouterLink,
    ImagesCarouselComponent,
  ],
  templateUrl: './volunteers.component.html',
  styleUrl: './volunteers.component.scss',
})
export class VolunteersComponent {
  @ViewChild(ImagesCarouselComponent)
  imagesCarouselComponent: ImagesCarouselComponent | undefined;
  volunteers: any[] = [];

  openCarousel(url: string = '') {
    this.imagesCarouselComponent?.openCarousel(url);
  }

  contactForm: FormGroup;
  countries: string[];
  qualifications: string[] = [
    'Bac ou équivalent',
    'DUT',
    'BTS',
    'Licence',
    'Master',
    'Doctorat',
    'Autre',
    'Sans diplôme',
  ];

  ngOnInit() {
    this.updateVolunteer();
    this.translateService.onLangChange.subscribe(() => {
      this.updateVolunteer();
    });
  }

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
  ) {
    this.countries = getNames().sort();
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      streetAddress: ['', Validators.required],
      complementAddress: [''],
      dateOfBirth: [
        new Date((new Date().getFullYear() + this.startYearBirth()) / 2, 0, 1),
      ],
      dateArrival: [new Date(new Date().getFullYear(), 0, 1)],
      city: [''],
      region: [''],
      postalCode: [''],
      email: ['', [Validators.required, Validators.email]],
      country: [],
      countryBac: [],
      lastQualification: [],
      qualificationTitle: [],
      preparedQualification: [],
      desiredJob: [],
      university: [],
      phoneNumber: [''],
      message: [''],
      agreeToPolicies: [false, Validators.requiredTrue],
    });
  }

  updateVolunteer() {
    const translationsKeys = [
      'VOLUNTEER_IMG1_NAME',
      'VOLUNTEER_IMG2_NAME',
      'VOLUNTEER_IMG3_NAME',
      'VOLUNTEER_IMG4_NAME',
      'VOLUNTEER_IMG5_NAME',
      'VOLUNTEER_IMG6_NAME',
      'VOLUNTEER_IMG1_TITLE',
      'VOLUNTEER_IMG2_TITLE',
      'VOLUNTEER_IMG3_TITLE',
      'VOLUNTEER_IMG4_TITLE',
      'VOLUNTEER_IMG5_TITLE',
      'VOLUNTEER_IMG6_TITLE',
    ];
    this.translateService.get(translationsKeys).subscribe((translations) => {
      this.volunteers = [
        {
          name: translations['VOLUNTEER_IMG1_NAME'],
          title: translations['VOLUNTEER_IMG1_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-1.webp',
          width: '1',
        },
        {
          name: translations['VOLUNTEER_IMG2_NAME'],
          title: translations['VOLUNTEER_IMG2_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-2.webp',
          width: '1',
        },
        {
          name: translations['VOLUNTEER_IMG3_NAME'],
          title: translations['VOLUNTEER_IMG3_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-3.webp',
          width: '1',
        },
        {
          name: translations['VOLUNTEER_IMG4_NAME'],
          title: translations['VOLUNTEER_IMG4_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-4.webp',
          width: '1',
        },
        {
          name: translations['VOLUNTEER_IMG5_NAME'],
          title: translations['VOLUNTEER_IMG5_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-5.webp',
          width: '1',
        },
        {
          name: translations['VOLUNTEER_IMG6_NAME'],
          title: translations['VOLUNTEER_IMG6_TITLE'],
          url: 'assets/images/volunteers/volunteers-carousel-6.webp',
          width: '1',
        },
      ];
    });
  }

  getDateOfBirth(): Date {
    return this.contactForm.get('dateOfBirth')?.value;
  }

  updateDateOfBirth(date: Date) {
    this.contactForm.get('dateOfBirth')?.setValue(date);
  }

  getDateArrival(): Date {
    return this.contactForm.get('dateArrival')?.value;
  }

  updateDateArrival(date: Date) {
    this.contactForm.get('dateArrival')?.setValue(date);
  }

  startYearBirth(): number {
    return new Date().getFullYear() - 60;
  }

  endYearBirth(): number {
    return new Date().getFullYear();
  }

  startYearArrival(): number {
    return new Date().getFullYear() - 50;
  }

  endYearArrival(): number {
    return new Date().getFullYear() + 10;
  }

  onSubmit() {
    // Handle form submission here
  }

  toggleAgreeToPolicies() {
    const currentValue = this.contactForm?.get('agreeToPolicies')?.value;
    this.contactForm?.get('agreeToPolicies')?.setValue(!currentValue);
  }
}
