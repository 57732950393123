<div class="container">
  <!-- Première section -->
  <div class="flex flex-wrap">
    <div class="text">
      <h1 class="font-title text-3xl text-daradjaMagenta mt-5 mb-5" i18n="@@STORY_TITLE_1">
        {{ "STORY_TITLE_1" | translate }}
      </h1>
      <p>
        <span i18n="@@STORY_P1_1">
          {{ "STORY_P1_1" | translate }}
        </span>
        <span class="font-semibold" i18n="@@STORY_P1_2_BOLD">
          {{ "STORY_P1_2_BOLD" | translate }}
        </span>
        <span i18n="@@STORY_P1_3"> {{ "STORY_P1_3" | translate }}</span>
        <span class="font-semibold" i18n="@@STORY_P1_4_BOLD">
          {{ "STORY_P1_4_BOLD" | translate }}
        </span>
      </p>
      <p>
        <span i18n="@@STORY_P2_1"> {{ "STORY_P2_1" | translate }}</span>
        <span class="font-semibold" i18n="@@STORY_P2_2_BOLD">
          {{ "STORY_P2_2_BOLD" | translate }}</span
        >
        <span i18n="@@STORY_P2_3"> {{ "STORY_P2_3" | translate }}</span>
      </p>
    </div>
    <div class="max-lg:w-8/12 m-auto max-md:w-full">
      <img
        class="lg:ml-10 rounded-lg max-lg:w-full"
        src="assets/images/Etudiants Daradja.webp"
        alt="Votre Image"
      />
    </div>
  </div>
  <!-- Deuxième section -->
  <div class="flex flex-wrap">
    <div class="max-lg:w-8/12 m-auto max-md:w-full">
      <img
        class="rounded-lg max-lg:w-full"
        src="assets/images/Sage_Daradja_9.webp"
        alt="Votre Image"
      />
    </div>
    <div class="text lg:ml-10">
      <h1 class="font-title text-3xl text-daradjaMagenta mt-5 mb-5" i18n="@@STORY_TITLE_2">
        {{ "STORY_TITLE_2" | translate }}
      </h1>
      <p i18n="@@STORY_P4">
        {{ "STORY_P4" | translate }}
      </p>
      <p class="font-semibold" i18n="@@STORY_P5_BOLD">
        {{ "STORY_P5_BOLD" | translate }}
      </p>
      <p i18n="@@STORY_P6_BOLD">
        {{ "STORY_P6_BOLD" | translate }}
      </p>
    </div>
  </div>
  <!-- Troisième section-->
  <div class="flex flex-wrap">
    <div class="text">
      <h1 class="font-title text-3xl text-daradjaMagenta mt-5 mb-5" i18n="@@STORY_TITLE_3">
        {{ "STORY_TITLE_3" | translate }}
      </h1>
      <p>
        <span i18n="@@STORY_P7_1"> {{ "STORY_P7_1" | translate }}</span>
        <span class="font-semibold" i18n="@@STORY_P7_2_BOLD">
          {{ "STORY_P7_2_BOLD" | translate }}</span
        ><span i18n="@@STORY_P7_3"> {{ "STORY_P7_3" | translate }}</span>
      </p>
      <p>
        <span class="font-semibold" i18n="@@STORY_8_1">
          {{ "STORY_8_1" | translate }}
        </span>
        <span i18n="@@STORY_8_2"> {{ "STORY_8_2" | translate }}</span>
      </p>
    </div>
    <div class="max-lg:w-8/12 m-auto max-md:w-full">
      <img
        class="lg:ml-10 rounded-lg max-lg:w-full"
        src="assets/images/Etudiants Conseil Daradja.webp"
        alt="Votre Image"
      />
    </div>
  </div>
  <!-- Quatrième section  -->
  <div class="flex flex-wrap">
    <div class="max-lg:w-8/12 m-auto max-md:w-full">
      <img
        class="rounded-lg max-lg:w-full"
        src="assets/images/Daradja_Montpellier_JPG.webp"
        alt="Votre Image"
      />
    </div>
    <div class="text lg:ml-10">
      <h1 class="font-title text-3xl text-daradjaMagenta mt-5 mb-5" i18n="@@STORY_TITLE_4">
        {{ "STORY_TITLE_4" | translate }}
      </h1>
      <p>
        <span i18n="@@STORY_9_1"> {{ "STORY_9_1" | translate }}</span>
        <span class="font-semibold" i18n="@@STORY_9_2_BOLD">
          {{ "STORY_9_2_BOLD" | translate }}</span
        >
        <span i18n="@@STORY_9_3"> {{ "STORY_9_3" | translate }}</span>
        <span class="font-semibold" i18n="@@STORY_9_4_BOLD">
          {{ "STORY_9_4_BOLD" | translate }}</span
        ><span i18n="@@STORY_9_5"> {{ "STORY_9_5" | translate }} </span>
        <span i18n="@@STORY_9_6_BOLD" class="font-semibold">
          {{ "STORY_9_6_BOLD" | translate }}</span
        ><span i18n="@@STORY_9_7"> {{ "STORY_9_7" | translate }}</span>
      </p>
    </div>
  </div>
</div>
