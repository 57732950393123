<div class="member-container">
  <div class="member-details">
    <img
      [src]="imageUrl | translate"
      alt="{{ name | translate }} Image"
      class="member-image m-5"
    />
    <h2 class="member-name font-bold text-xl text-black">
      {{ name | translate }}
    </h2>
    <p class="member-role text-base m-5 italic text-daradjaMagenta">
      {{ labelRole }}
    </p>
    <p class="member-description">{{ labelDescription }}</p>
  </div>
</div>
