export * from './address.service';
import { AddressService } from './address.service';
export * from './helpDomain.service';
import { HelpDomainService } from './helpDomain.service';
export * from './loginCheck.service';
import { LoginCheckService } from './loginCheck.service';
export * from './mentor.service';
import { MentorService } from './mentor.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './staff.service';
import { StaffService } from './staff.service';
export * from './student.service';
import { StudentService } from './student.service';
export * from './tutor.service';
import { TutorService } from './tutor.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './video.service';
import { VideoService } from './video.service';
export const APIS = [AddressService, HelpDomainService, LoginCheckService, MentorService, NewsService, StaffService, StudentService, TutorService, UserService, VideoService];
