import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { VideoMockDataService } from './video-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { VideoService } from '../api/video.service';

@NgModule({
  providers: [
    {
      provide: VideoService,
      useClass: environment.useMockData ? VideoMockDataService : VideoService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class VideoModule {}
