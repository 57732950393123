import { Observable } from 'rxjs';
import { Mentor } from '../../models/DTO/mentor';

export abstract class IMentorService {
  /**
   * Retrieves a Mentor resource.
   * Retrieves a Mentor resource.
   * @param id Mentor identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiMentorsIdGet(id: string): Observable<any>;

  /**
   * Creates a Mentor resource.
   * Creates a Mentor resource.
   * @param mentor The new Mentor resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiMentorsPost(mentor: Mentor): Observable<any>;

  /**
   * Updates a Mentor resource.
   * Updates a Mentor resource.
   * @param mentor The Mentor resource
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiMentorsIdPut(id: string, mentor: Mentor): Observable<any>;

  /**
   * Deletes a Mentor resource.
   * Deletes a Mentor resource.
   * @param id Mentor identifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiMentorsDelete(id: string): Observable<any>;
}
