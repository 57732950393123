<mat-dialog-content>
  <h2>{{ titre | translate }}</h2>
  <mat-spinner *ngIf="showSpinner" class="custom-spinner"></mat-spinner>
  <div
    class="envoi-modal-content text-daradjaSuccess"
    *ngIf="!showSpinner && success"
  >
    <mat-icon class="modal-icon" fontIcon="done"></mat-icon>
    <h3>
      {{ successContent | translate }}
    </h3>
    <h4 *ngIf="info && info !== ''">
      <mat-icon class="modal-icon" fontIcon="info"></mat-icon>
      {{ info | translate }}
    </h4>
  </div>
  <div
    class="envoi-modal-content text-daradjaAlert"
    *ngIf="!showSpinner && !success"
  >
    <mat-icon class="modal-icon" fontIcon="cancel"> </mat-icon>
    <h3>
      {{ errorContent | translate }}
    </h3>
  </div>
  <button mat-dialog-close (click)="close()" *ngIf="!showSpinner">
    {{ "CLOSE" | translate }}
  </button>
</mat-dialog-content>
