/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.6
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
import { AutoMap } from '@automapper/classes';

export class Student {
  @AutoMap()
  bacCountry?: string;
  @AutoMap()
  lastDiploma?: string;
  @AutoMap()
  titleDiploma?: string;
  @AutoMap()
  school?: string;
  @AutoMap()
  preparedDiploma?: string;
  @AutoMap()
  futureWork?: string;
  @AutoMap()
  comment?: string;
  @AutoMap()
  mentorProgram?: boolean;
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  updatedAt?: string;
  @AutoMap()
  createdAt?: string;
  @AutoMap()
  email?: string;
  @AutoMap()
  password?: string;
  @AutoMap()
  plainPassword?: string;
  @AutoMap()
  roles?: Array<string>;
  @AutoMap()
  gender?: string;
  @AutoMap()
  phone?: string;
  address?: any;
  @AutoMap()
  videos?: Array<string>;
  @AutoMap()
  isActivated?: boolean;
  @AutoMap()
  arrivedFrenchAt?: string;
  @AutoMap()
  firstname?: string;
  @AutoMap()
  lastname?: string;
  @AutoMap()
  birthAt?: string;
  @AutoMap()
  telContact?: boolean;

  emailContact: boolean = false;
  @AutoMap()
  readonly username?: string;
  @AutoMap()
  /**
   * The public representation of the user (e.g. a username, an email address, etc.)
   */
  readonly userIdentifier?: string;
  @AutoMap()
  video?: Array<string>;
  @AutoMap()
  readonly activated?: boolean;
}
