import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IHelpDomainService } from './IHelp-domain.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { HelpDomainService } from '../api/helpDomain.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: IHelpDomainService,
      useClass: HelpDomainService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class HelpDomainModule {}
