import { Observable } from 'rxjs';

export abstract class IHelpDomainService {
  /**
   * Retrieves the collection of HelpDomain resources.
   * Retrieves the collection of HelpDomain resources.
   * @param page The collection page number
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public abstract apiHelpDomainsGetCollection(): Observable<any>;
}
