/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.6
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
import { AutoMap } from "@automapper/classes";

export class Address { 
    @AutoMap()
    readonly id?: number;
    @AutoMap()
    postalAddress?: string;
    @AutoMap()
    additionalAddress?: string;
    @AutoMap()
    city?: string;
    @AutoMap()
    zipCode?: string;
    @AutoMap()
    region?: string;
    @AutoMap()
    country?: string;
    @AutoMap()
    updatedAt?: string;
    @AutoMap()
    createdAt?: string;
}

