<div class="relative">
  <input type="hidden" name="date" x-ref="date" />
  <input
    type="text"
    readonly
    [(ngModel)]="datepickerValue"
    (click)="showDatepicker = !showDatepicker"
    (keydown.escape)="showDatepicker = false"
    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
    placeholder="Select date"
  />

  <div class="absolute top-0 right-0 px-3 py-2">
    <svg
      (click)="showDatepicker = !showDatepicker"
      class="h-6 w-6 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </div>

  <div
    class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-10"
    style="width: 17rem"
    [hidden]="!showDatepicker"
    (keydown.away)="showDatepicker = false; showYearPicker = false"
  >
    <div class="my-3">
      <label class="block text-sm font-medium text-gray-700">Année</label>
      <select
        [(ngModel)]="year"
        (ngModelChange)="onYearChange()"
        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-daradjaMain focus:border-daradjaMain sm:text-sm"
      >
        <option *ngFor="let y of getYears()" [value]="y">{{ y }}</option>
      </select>
    </div>
    <div class="flex justify-between items-center mb-2">
      <div>
        <span class="text-lg font-bold text-gray-800">{{
          MONTH_NAMES[month] + ".full" | translate
        }}</span>
        <span class="ml-1 text-lg text-gray-600 font-normal">{{ year }}</span>
      </div>
      <!-- boutons suivant et précédent -->
      <div>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
          [ngClass]="{ 'cursor-not-allowed opacity-25': month === 0 }"
          [disabled]="month === 0 ? true : false"
          (click)="month = month - 1; getNoOfDays()"
        >
          <svg
            class="h-6 w-6 text-gray-500 inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
          [ngClass]="{ 'cursor-not-allowed opacity-25': month === 11 }"
          [disabled]="month === 11 ? true : false"
          (click)="month = month + 1; getNoOfDays()"
        >
          <svg
            class="h-6 w-6 text-gray-500 inline-flex"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
      <!-- fin boutons suivant et précédent -->
    </div>

    <div class="flex flex-wrap mb-3 -mx-1">
      <ng-container *ngFor="let day of DAYS; let index = index">
        <div style="width: 14.26%" class="px-1">
          <div class="text-gray-800 font-medium text-center text-xs">
            {{ day + ".short" | translate }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-wrap -mx-1">
      <ng-container *ngFor="let blankday of blankdays">
        <div
          style="width: 14.28%"
          class="text-center border-none p-1 border-transparent text-sm"
        ></div>
      </ng-container>
      <ng-container
        *ngFor="
          let date of no_of_days;
          let dateIndex = index;
          trackBy: trackByIdentity
        "
      >
        <div style="width: 14.28%" class="px-1 mb-1">
          <div
            (click)="getDateValue(date)"
            class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
            [ngClass]="{
              'bg-daradjaMain text-white': isToday(date),
              'text-gray-700 hover:bg-daradjaMain': !isToday(date)
            }"
          >
            {{ date }}
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Year selection -->
  </div>
</div>
