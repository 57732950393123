<div class="container">
  <h1 class="font-title text-center p-2 text-3xl text-white bg-daradjaMagenta">
    {{ "DONATION_P1_TITLE" | translate }}
  </h1>
  <br />
  <p>
    {{ "DONATION_P1_1" | translate }}
    <span class="font-semibold">{{ "DONATION_P1_1_BOLD" | translate }} </span>
    {{ "DONATION_P1_2" | translate }}
    <span class="font-semibold">{{ "DONATION_P1_2_BOLD" | translate }} </span>
    {{ "DONATION_P1_3" | translate }}
    <span class="font-semibold">{{ "DONATION_P1_3_BOLD" | translate }} </span
    >{{ "DONATION_P1_4" | translate }}
  </p>
  <br />
  <p>{{ "DONATION_P1_5" | translate }}</p>
  <br />
  <p class="font-semibold">{{ "DONATION_TAX_CREDIT" | translate }}</p>
  <br />
  <!-- IFRAME -->
  <div class="text-center">
    <a
      class="mx-auto bg-daradjaMagenta text-white text-center px-4 py-2 rounded-md inline-block hover:bg-daradjaMagenta"
      href="https://www.helloasso.com/associations/daradja/formulaires/1/"
    >
      Faites un don à Daradja
    </a>
  </div>
  <!-- FIN IFRAME -->
  <h2 class="font-semibold text-3xl text-daradjaMagenta mt-5">
    {{ "DONATION_P2_TITLE" | translate }}
  </h2>
  <br />
  <img src="assets/images/soLeader.webp" alt="Logo So Leader" />
  <br />
  <ul class="list-inside list-disc">
    <li>
      <span class="font-semibold">{{ "DONATION_P2_1_BOLD" | translate }} </span
      >{{ "DONATION_P2_1" | translate }}
    </li>
    <li>
      <span class="font-semibold">{{ "DONATION_P2_2_BOLD" | translate }} </span
      >{{ "DONATION_P2_2" | translate }}
    </li>
    <li>
      <span class="font-semibold">{{ "DONATION_P2_3_BOLD" | translate }} </span
      >{{ "DONATION_P2_3" | translate }}
    </li>
  </ul>
  <br />
  <h2 class="font-semibold text-3xl text-daradjaMagenta">
    {{ "DONATION_P3_TITLE" | translate }}
  </h2>
  <br />
  <p>
    {{ "DONATION_P3_1" | translate }}
    <a
      class="underline font-bold text-daradjaMain"
      target="_blank"
      href="https://so-leader.com"
      >So-leader</a
    >, <span class="font-semibold">{{ "DONATION_P3_1_BOLD" | translate }}</span>
  </p>
  <p>{{ "DONATION_P3_2" | translate }}</p>
  <br />
  <p>
    {{ "DONATION_P3_3" | translate }}
    <a
      class="underline font-bold text-daradjaMain"
      target="_blank"
      href="https://so-leader.com/nos-associations-partenaires/education-enfance/daradja/103"
      >{{ "DONATION_LINK" | translate }}</a
    >, <span class="font-semibold">{{ "DONATION_P3_2_BOLD" | translate }}</span>
  </p>
  <div class="text-center">
    <hr class="h-px my-8 bg-black border-0" />
    <p>
      {{ "DONATION_QUESTION" | translate }}
      <a
        class="text-daradjaMagenta font-semibold"
        href="mailto: ecrire@daradja.fr"
        >ecrire&#64;daradja.fr</a
      >
    </p>
  </div>
</div>
