import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

@Component({
  selector: 'app-member',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
})
export class MemberComponent {
  @Input()
  name!: string;
  @Input()
  role!: string;
  @Input()
  englishRole!: string;
  @Input()
  description!: string;
  @Input()
  imageUrl!: string;
  @Input()
  englishDescription!: string;

  labelDescription: string = '';
  labelRole: string = '';

  constructor(private translateService: TranslateService) {}
  ngOnInit() {
    this.handleLangChange(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.handleLangChange(langChangeEvent.lang);
      },
    );
  }

  handleLangChange(lang: string) {
    if (lang === 'fr') {
      this.labelDescription = this.description;
      this.labelRole = this.role;
    }
    if (lang === 'en') {
      this.labelDescription = this.englishDescription;
      this.labelRole = this.englishRole;
    }
  }
}
