import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-story',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
})
export class StoryComponent {
  items = [
    {
      image: '../../../assets/images/Chamsia-étudiante-Daradja.webp',
      titre: 'Chamsia, étudiante',
      text: '" Le mentorat m\'a aidée à mieux connaître le marché du travail en France. Merci à Daradja et à ma mentor ! "',
    },
    {
      image: '../../../assets/images/Nathalie-mentor-Daradja.webp',
      titre: 'Nathalie, mentore',
      text:
        "\" J'ai eu la chance d'avoir un mentor qui m'a aidé à débuter ma vie professionnelle." +
        "Aujourd'hui, c'est avec plaisir que je transmets mon expérience à une étudiante, en espérant lui apporter autant que j'ai reçu ! \"",
    },
    {
      image: '../../../assets/images/Nadir-étudiant-Daradja.webp',
      titre: 'Nadir, étudiant',
      text: "\" C'est une chance de bénéficier de l'expertise d'un professionnel comme mon mentor. Merci à Daradja pour cette aventure ! \"",
    },
  ];
}
