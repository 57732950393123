import { Component, Input } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppJobSection } from '../../models/app-job-section.model';
import { JobComponent } from './job/job.component';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-job-section',
  standalone: true,
  imports: [JobComponent, NgFor],
  templateUrl: './job-section.component.html',
  styleUrl: './job-section.component.scss',
})
export class JobSectionComponent {
  @Input() jobSection?: AppJobSection;
  label = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.handleLangChange(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        this.handleLangChange(langChangeEvent.lang);
      },
    );
  }

  handleLangChange(lang: string) {
    if (lang === 'fr') {
      this.label = this.jobSection?.label || '';
    }
    if (lang === 'en') {
      this.label = this.jobSection?.englishLabel || '';
    }
  }
}
