import { Observable, Subject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';
import { Staff } from 'projects/front-end/src/app/models/DTO/staff';

@Injectable({
  providedIn: 'root',
})
export class StaffMockDataService {
  private throwError = false;

  staves = [
    {
      name: 'Hafosa Ali',
      job: 'Fondatrice et spécialiste de la gestion de projet',
      description:
        'Pendant 20 ans, Hafosa a piloté des projets organisationnels pour des associations, des multinationales, des médias, ou des collectivités locales. Sa position d’interface entre différents métiers ou cultures lui a permis de développer une expertise dans l’animation d’équipes multiculturelles.',
      picture: 'assets/images/hafosa.webp',
    },
    {
      name: 'Mallory Eugène',
      job: 'Chargée de Projet Inclusion',
      description:
        'Diplômée d’un master en communication et sociologie, Mallory a d’abord travaillé dans le domaine culturel. Elle a été par la suite chargée de projet dans l’associatif, dans le digital et à monté son entreprise. Aujourd’hui, c’est avec plaisir qu’elle met ces expériences au service de la mission de Daradja, qui lui parle tout particulièrement. Son objectif est de permettre à tou.te.s les étudiant.e.s, peu importe leur genre, leur milieu social et leur culture, d’avoir la carrière qu’ils et elles méritent.',
      picture: 'assets/images/mallory.webp',
    },
    {
      name: 'Remicard Sereme',
      job: 'Cheffe de projet Mécénat et Partenariats',
      description:
        'Diplômée d’un master en droit des affaires et gouvernance mondiale de Sciences Po Paris et de la Columbia Law School à New York, Remicard est passionnée par les questions d’inégalités sociales. Elle a travaillé dans des ONG, Cabinets d’Avocats, Entreprises, Universités et au sein de l’Assemblée Nationale sur les sujets de discriminations et d’égalité. Elle s’investit depuis de nombreuses années dans la lutte pour l’égalité des chances en France et se bat pour que tous les jeunes, quel que soit leur genre, leur origines géographiques et/ou culturelles, ou leur milieu social, puissent avoir accès aux opportunités adéquates pour pouvoir réaliser leur plein potentiel.',
      picture: 'assets/images/remicard.webp',
    },
    {
      name: 'Carla Gauthier',
      job: 'Chargée de développement',
      description:
        "Diplômée d’un master en Anthropologie du développement durable et actuellement en Master 2 en Management du développement durable, Carla souhaite développer ses compétences auprès d’un organisme engagé dans le monde de demain. Faire partie d’un projet qui lui tient à cœur lui est essentiel. Elle a d'ailleurs déjà plusieurs expériences dans le domaine associatif. C’est pour cette raison qu’elle a choisi de rejoindre l’équipe de Daradja pour son alternance, car elle était convaincue de la pertinence des projets qui y sont portés.",
      picture: 'assets/images/carla.webp',
    },
    {
      name: 'Robin Paillard',
      job: 'Chargé de développement',
      description:
        'Diplômé d’un DUT techniques de commercialisation, Robin a décidé de se spécialiser dans l’économie sociale et solidaire. Son service civique l’a conforté dans ce choix : faire un métier qui a du sens. Être en alternance en tant que chargé de développement est pour lui une réelle opportunité de mettre en pratique les savoirs théoriques de sa formation. Travailler chez Daradja est également une manière pour lui de poursuivre son engagement dans la lutte contre les différentes discriminations.',
      picture: 'assets/images/robin.webp',
    },
  ];

  constructor(private mockHelperService: MockHelperService<Staff>) {}

  apiStaffsIdGet(id: string) {
    return this.mockHelperService.simuleAppel(this.throwError, this.staves[0]);
  }

  public apiStavesGetCollection(page?: number): Observable<any> {
    return this.mockHelperService.simuleAppel(this.throwError, this.staves);
  }
}
