import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ImagesCarouselComponent } from '../../components/images-carousel/images-carousel.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workshops',
  standalone: true,
  templateUrl: './workshops.component.html',
  styleUrl: './workshops.component.scss',
  imports: [CommonModule, ImagesCarouselComponent, TranslateModule],
})
export class WorkshopsComponent {
  @ViewChild(ImagesCarouselComponent)
  imagesCarouselComponent: ImagesCarouselComponent | undefined;
  workshops: any[] = [];

  constructor(private translateService: TranslateService) {

  }

  ngOnInit() {
    this.updateWorkshops();
    this.translateService.onLangChange.subscribe(() => {
      this.updateWorkshops();
    })
  }

  updateWorkshops() {
    const translationsKeys = [
      "WSHOP_IMG1_NAME","WSHOP_IMG2_NAME","WSHOP_IMG3_NAME","WSHOP_IMG4_NAME","WSHOP_IMG5_NAME","WSHOP_IMG6_NAME","WSHOP_IMG7_NAME","WSHOP_IMG8_NAME",
      "WSHOP_IMG1_TITLE","WSHOP_IMG2_TITLE","WSHOP_IMG3_TITLE","WSHOP_IMG4_TITLE","WSHOP_IMG5_TITLE","WSHOP_IMG6_TITLE","WSHOP_IMG7_TITLE","WSHOP_IMG8_TITLE",
    ];
    this.translateService.get(translationsKeys).subscribe(translations => {
      this.workshops = [
        {
          name: translations['WSHOP_IMG1_NAME'],
          title: translations['WSHOP_IMG1_TITLE'],
          url: 'assets/images/workshops/atelier-1.webp',
          width: '4/12',
        },
        {
          name: translations['WSHOP_IMG2_NAME'],
          title: translations['WSHOP_IMG2_TITLE'],
          url: 'assets/images/workshops/atelier-2.webp',
          width: '4/12',
        },
        {
          name: translations['WSHOP_IMG3_NAME'],
          title: translations['WSHOP_IMG3_TITLE'],
          url: 'assets/images/workshops/atelier-3.webp',
          width: '4/12',
        },
        {
          name: translations['WSHOP_IMG4_NAME'],
          title: translations['WSHOP_IMG4_TITLE'],
          url: 'assets/images/workshops/atelier-4.webp',
          width: '4/12',
        },
        {
          name: translations['WSHOP_IMG5_NAME'],
          title: translations['WSHOP_IMG5_TITLE'],
          url: 'assets/images/workshops/atelier-5.webp',
         width: '4/12',
        },
        {
          name: translations['WSHOP_IMG6_NAME'],
          title: translations['WSHOP_IMG6_TITLE'],
          url: 'assets/images/workshops/atelier-6.webp',
        width: '4/12',
        },
        {
          name: translations['WSHOP_IMG7_NAME'],
          title: translations['WSHOP_IMG7_TITLE'],
          url: 'assets/images/workshops/atelier-7.webp',
        width: '4/12',
        },
        {
          name: translations['WSHOP_IMG8_NAME'],
          title: translations['WSHOP_IMG8_TITLE'],
          url: 'assets/images/workshops/atelier-8.webp',
        width: '4/12',
        },
      ]
    });
  }

  openCarousel(url: string = '') {
    this.imagesCarouselComponent?.openCarousel(url);
  }

  chunkArray(array: any[], size: number): any[][] {
    const chunkedArr = [];
    let index = 0;
    while (index < array.length) {
      chunkedArr.push(array.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }
}
