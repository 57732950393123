import { CdkStepper } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  providers: [{ provide: CdkStepper, useExisting: StepperComponent}]
})
export class StepperComponent extends CdkStepper implements OnInit {
  validSteps = new Map<number, boolean>();
  
  ngOnInit(): void {
      this.selectedIndex = 0;
  }

  onClick(index: number): void {
    this.selectedIndex = index;
    this.validSteps.set(index, true);
  }

  isValid(index: number): boolean {
    return this.validSteps.has(index) ? this.validSteps.get(index)! : false;
  }
}
