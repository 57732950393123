import { AutoMap } from '@automapper/classes';
import { AppDaradjaObject } from './app-daradja-object.model';
export class AppVideo extends AppDaradjaObject {
  @AutoMap()
  readonly id?: number;
  @AutoMap()
  fileType?: string;
  @AutoMap()
  fileName?: string;
  @AutoMap()
  title?: string;
  @AutoMap()
  user?: string;
  @AutoMap()
  url?: string;
}
