<div class="text-center">
  <mat-spinner *ngIf="isLoading" class="custom-spinner m-auto"></mat-spinner>
  <div class="text-daradjaAlert m-auto" *ngIf="!isLoading && showError">
    <mat-icon fontIcon="cancel"> </mat-icon>
    <h3>
      {{ errorContent | translate }}
    </h3>
  </div>
  <div
    class="text-daradjaInfo m-auto"
    *ngIf="!isLoading && notAvailableForTechAccount"
  >
    <mat-icon fontIcon="info"> </mat-icon>
    <h3>
      {{ "TECHNICAL_ACCOUNT_INFO_TITLE" | translate }}
    </h3>
    <p>
      {{ "TECHNICAL_ACCOUNT_INFO_CONTENT" | translate }}
    </p>
  </div>
</div>
