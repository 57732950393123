<div class="speaker-container">
  <div class="speaker-details">
    <img
      [src]="imageUrl | translate"
      alt="{{ name | translate }} Image"
      class="speaker-image"
    />
    <h2 class="speaker-name font-bold text-lg m-2 text-black">
      {{ name | translate }}
    </h2>
    <p class="speaker-role italic text-daradjaMagenta">{{ role | translate }}</p>
  </div>
</div>
