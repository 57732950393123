<div class="container">
  <h1 class="font-title text-center mb-5 p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@STUDENT_GUIDE_TITLE">
    {{ "STUDENT_GUIDE_TITLE" | translate }}
  </h1>

  <p class="mb-5" i18n="@@STUDENT_P1">
    {{ "STUDENT_P1" | translate }}
  </p>

  <p class="mb-5">
    <span i18n="@@STUDENT_P2_1"> {{ "STUDENT_P2_1" | translate }}</span>
    <a
      href="https://lesaf.org/"
      target="_blank"
      class="underline text-daradjaMain font-bold"
      i18n="@@STUDENT_P2_2_BOLD"
    >
      {{ "STUDENT_P2_2_BOLD" | translate }}</a
    >
    <span i18n="@@STUDENT_P2_3_AND"> {{ "STUDENT_P2_3_AND" | translate }}</span>
    <a
      href="https://unef.fr/"
      target="_blank"
      class="underline text-daradjaMain font-bold"
      i18n="@@STUDENT_P2_4_UNEF"
    >
      {{ "STORY_TITLE_4" | translate }}</a
    ><span i18n="@@STUDENT_P2_5"> {{ "STUDENT_P2_5" | translate }}</span>
  </p>

  <div class="mt-5 mx-auto max-w-2xl lg:max-w-4xl mb-5">
    <p
      class="text-center text-xl font-normal leading-8 text-gray-900 sm:leading-9"
    >
      <span i18n="@@STUDENT_MORE">
        {{ "STUDENT_MORE" | translate }}
      </span>

      <a class="text-daradjaMagenta font-semibold" href="mailto: ecrire@daradja.fr">
        ecrire&#64;daradja.fr</a
      >
    </p>
  </div>

  <div class="flex items-center justify-center">
    <h2 class="font-semibold text-3xl text-daradjaMagenta" i18n="@@STUDENT_TITLE_PDF">
      {{ "STUDENT_TITLE_PDF" | translate }}
    </h2>
    <a
      href="/assets/pdf/student_guide.pdf"
      download="Guide_2022_2023.pdf"
      i18n="@@STUDENT_DOWNLOAD"
      class="bg-daradjaMagenta text-white text-center ml-5 px-4 py-2 rounded-md inline-block hover:bg-daradjaMain"
    >
      {{ "STUDENT_DOWNLOAD" | translate }}</a
    >
  </div>

  <pdf-viewer
    class="mx-auto my-5 w-full md:w-7/12"
    [src]="urlPdf"
    [rotation]="0"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="false"
    [page]="pageNumber"
    [zoom]="1"
    [zoom-scale]="'page-width'"
    [stick-to-page]="false"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="true"
    [show-borders]="false"
    style="height: 800px"
    (after-load-complete)="afterLoadComplete($event)"
    (pageChange)="afterPageChange($event)"
  ></pdf-viewer>

  <div class="text-center mt-3">
    <label for="pageNumber">{{ "STUDENT_GUIDE_PAGE" | translate }} </label>
    <input
      min="1"
      max="{{ totalPages }}"
      type="number"
      id="pageNumber"
      class="rounded"
      [(ngModel)]="pageNumber"
      step="1"
      pattern="[0-9]+"
      oninput="this.value = this.value.replace(/[^0-9]/g, '');"
    />
    <label>
      {{ "STUDENT_GUIDE_ON" | translate }}<span></span> {{ totalPages }}
    </label>
  </div>
</div>
