import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';
import { Observable } from 'rxjs';
import { User } from 'projects/front-end/src/app/models/DTO/user';

@Injectable({
  providedIn: 'root',
})
export class UserMockDataService {
  private throwError = false;

  constructor(private mockHelperService: MockHelperService<User>) {}

  public askForResetToken(user: User): Observable<any> {
    console.log(user);
    return this.mockHelperService.simuleAppel(this.throwError, user);
  }

  public checkToken(): Observable<any> {
    {
      return this.mockHelperService.simuleAppel(this.throwError, {});
    }
  }

  public resetPassword(user: any): Observable<any> {
    console.log(user);
    return this.mockHelperService.simuleAppel(this.throwError, user);
  }
}
