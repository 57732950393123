import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginCheckService } from '../api/loginCheck.service';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthenticationService {
  constructor(
    private loginCheckService: LoginCheckService,
    private userService: UserService,
  ) {}

  public login(username: string, password: string): Observable<any> {
    return this.loginCheckService.loginCheckPost({
      username: username,
      password: password,
    });
  }

  public isLoggedIn(): Observable<boolean> {
    let subjectIsLogged: Subject<boolean> = new Subject<boolean>();
    this.userService.checkToken().subscribe({
      next: () => {
        subjectIsLogged.next(true);
      },
      error: () => {
        subjectIsLogged.next(false);
      },
    });
    return subjectIsLogged.asObservable();
  }
}
