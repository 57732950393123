/**
 * API Daradja
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
import { AutoMap } from "@automapper/classes";

export class VideoUserRead { 
    @AutoMap()
    updatedAt?: string;
    @AutoMap()
    createdAt?: string;
}

