import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockHelperService<T> {
  constructor() {}

  public simuleAppel(throwError: boolean, retour: any): Observable<T> {
    const subject = new Subject<T>();

    setTimeout(() => {
      if (throwError) subject.error('Error');
      else subject.next(retour);
    }, 1000);

    return subject.asObservable();
  }
}
