import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ImagesCarouselComponent } from '../../components/images-carousel/images-carousel.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mentoring',
  standalone: true,
  templateUrl: './mentoring.component.html',
  styleUrl: './mentoring.component.scss',
  imports: [CommonModule, ImagesCarouselComponent, TranslateModule],
})
export class MentoringComponent {
  @ViewChild(ImagesCarouselComponent)
  imagesCarouselComponent: ImagesCarouselComponent | undefined;
  mentoringPictures: any[] = [];
  
  constructor(private translateService: TranslateService) {
    
  }

  ngOnInit() {
    this.updateMentoring();
    this.translateService.onLangChange.subscribe(() => {
      this.updateMentoring();
    })
  }

  updateMentoring() {
    const translationsKeys = [
      'MENTORING_IMG1_NAME', 'MENTORING_IMG2_NAME', 'MENTORING_IMG3_NAME', 'MENTORING_IMG4_NAME', 'MENTORING_IMG5_NAME', 'MENTORING_IMG6_NAME',
      "MENTORING_IMG1_TITLE","MENTORING_IMG2_TITLE","MENTORING_IMG3_TITLE","MENTORING_IMG4_TITLE","MENTORING_IMG5_TITLE","MENTORING_IMG6_TITLE"
    ];
    this.translateService.get(translationsKeys).subscribe(translations => {
      this.mentoringPictures = [
        {
          name: translations['MENTORING_IMG1_NAME'],
          title: translations['MENTORING_IMG1_TITLE'],
          url: 'assets/images/mentoring/mentorat-1.webp',
          width: '1',
        },
        {
          name: translations['MENTORING_IMG2_NAME'],
          title: translations['MENTORING_IMG2_TITLE'],
          url: 'assets/images/mentoring/mentorat-2.webp',
          width: '1',
        },
        {
          name: translations['MENTORING_IMG3_NAME'],
          title: translations['MENTORING_IMG3_TITLE'],
          url: 'assets/images/mentoring/mentorat-3.webp',
          width: '1',
        },
        {
          name: translations['MENTORING_IMG4_NAME'],
          title: translations['MENTORING_IMG4_TITLE'],
          url: 'assets/images/mentoring/mentorat-4.webp',
          width: '1',
        },
        {
          name: translations['MENTORING_IMG5_NAME'],
          title: translations['MENTORING_IMG5_TITLE'],
          url: 'assets/images/mentoring/mentorat-5.webp',
          width: '1',
        },
        {
          name: translations['MENTORING_IMG6_NAME'],
          title: translations['MENTORING_IMG6_TITLE'],
          url: 'assets/images/mentoring/mentorat-6.webp',
          width: '1',
        },
      ]
    });
  }

  openCarousel(url: string = '') {
    this.imagesCarouselComponent?.openCarousel(url);
  }
}
