import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { INewsService } from './I-news-item.service';
import { NewsMockDataService } from './news-item-mock-data.service';
import { NewsService } from '../api/news.service';

@NgModule({
  providers: [
    {
      provide: INewsService,
      useClass: environment.useMockData ? NewsMockDataService : NewsService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class NewsModule {}
