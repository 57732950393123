import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

interface ImageObject {
  url: string;
  name: string;
}
@Component({
  selector: 'app-ecosystem',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './ecosystem.component.html',
  styleUrls: ['./ecosystem.component.scss'],
})
export class EcosystemComponent {
  schoolImages: ImageObject[] = [
    { url: 'assets/images/schools/industreet.jpg', name: 'Industreet' },
    { url: 'assets/images/schools/mbs.jpg', name: 'MBS' },
    { url: 'assets/images/schools/paris8.png', name: 'Paris 8' },
    { url: 'assets/images/schools/sonate.png', name: 'Sonate' },
    { url: 'assets/images/schools/sorbonne.png', name: 'Sorbonne' },
  ];

  privatePartnershipImages: ImageObject[] = [
    {
      url: 'assets/images/privatePartnerships/bnpparibas.png',
      name: 'BNP Paribas',
    },
    { url: 'assets/images/privatePartnerships/deloitte.png', name: 'Deloitte' },
    { url: 'assets/images/privatePartnerships/e221.png', name: 'E221' },
    { url: 'assets/images/privatePartnerships/epiceas.png', name: 'Epiceas' },
    { url: 'assets/images/privatePartnerships/incub.png', name: 'Incub' },
    {
      url: 'assets/images/privatePartnerships/probonolab.png',
      name: 'Pro Bono Lab',
    },
    { url: 'assets/images/privatePartnerships/rec.png', name: 'REC' },
    { url: 'assets/images/privatePartnerships/saf.jpg', name: 'SAF' },
    { url: 'assets/images/privatePartnerships/sage.png', name: 'SAGE' },
    {
      url: 'assets/images/privatePartnerships/societegenerale.png',
      name: 'Société Générale',
    },
    { url: 'assets/images/privatePartnerships/soleader.png', name: 'Soleader' },
  ];

  publicPartnershipImages: ImageObject[] = [
    { url: 'assets/images/publicPartnerships/cget.png', name: 'CGET' },
    {
      url: 'assets/images/publicPartnerships/communevilletaneuse.png',
      name: 'Commune Ville Taneuse',
    },
    { url: 'assets/images/publicPartnerships/drjscs.png', name: 'DRJSCS' },
    { url: 'assets/images/publicPartnerships/in.png', name: 'IN' },
    {
      url: 'assets/images/publicPartnerships/maison18e.png',
      name: 'Maison 18e',
    },
    {
      url: 'assets/images/publicPartnerships/plainecommune.jpg',
      name: 'Plaine Commune',
    },
    {
      url: 'assets/images/publicPartnerships/prefetidf.png',
      name: "Préfecture d'Île-de-France",
    },
    {
      url: 'assets/images/publicPartnerships/seinesaintdenis.png',
      name: 'Seine-Saint-Denis',
    },
    {
      url: 'assets/images/publicPartnerships/servicecivique.png',
      name: 'Service Civique',
    },
    {
      url: 'assets/images/publicPartnerships/villedeparis.png',
      name: 'Ville de Paris',
    },
  ];
}
