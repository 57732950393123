import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-donation-button',
  standalone: true,
  imports: [TranslateModule, RouterLink],
  templateUrl: './donation-button.component.html',
  styleUrl: './donation-button.component.scss',
})
export class DonationButtonComponent {}
