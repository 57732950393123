import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EnvoiModalService } from '../../components/envoi-modal/envoi-modal.service';
import { ErrorValidationLabelComponent } from '../../components/error-validation-label/error-validation-label.component';
import { CommonModule } from '@angular/common';
import { UserModule } from '../../services/user/user.module';
import { UserCustomService } from '../../services/user/user-custom.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    TranslateModule,
    ErrorValidationLabelComponent,
    CommonModule,
    ReactiveFormsModule,
    UserModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPwdForm: FormGroup;
  dataModal: any;
  email: string = '';

  constructor(
    private fb: FormBuilder,
    private modal: EnvoiModalService,
    private userService: UserCustomService,
  ) {
    this.forgotPwdForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.dataModal = {
      titre: 'MODAL_FORM_SUBMIT_TITLE',
      successContent: 'MODAL_PWD_FORGOT_SUCCESS',
      errorContent: 'MODAL_FORM_SUBMIT_ERROR',
    };
  }

  submit() {
    this.modal.open(this.dataModal);
    this.userService.askForResetToken(this.forgotPwdForm.value).subscribe({
      next: () => {
        this.modal.showSuccess();
      },
      error: () => {
        this.modal.showError();
      },
    });
  }

  get getEmailControl() {
    return this.forgotPwdForm.get('email');
  }

  isSubmitDisabled(): boolean {
    return !this.forgotPwdForm.valid;
  }
}
