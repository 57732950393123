import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';

export const authenticationGuard: CanActivateFn = (route, state) => {
  //recupération du role
  const role = inject(StorageService).getRole();
  //on verifie que l'utilisateur est autorisé à consulter la ressource
  const authorized: boolean = inject(PermissionsService).canActivate(
    role,
    route.data['roles'],
  );
  const router = inject(Router);
  const allOk =
    inject(AuthenticationService)
      //on verifie que l'utilisateur est connectée
      .isLoggedIn() && authorized;

  if (!allOk) {
    //redirection vers la page acces interdit
    router.navigate(['/forbidden']);
  }

  return allOk;
};
