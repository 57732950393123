<div class="relative flex gap-x-3">
  <div class="flex h-6 items-center">
    <input
      id="helpDomainItem.id"
      name="helpDomainItem.name"
      type="checkbox"
      class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
      (change)="onCheck()"
    />
  </div>
  <div class="text-sm leading-6">
    <label class="font-medium text-gray-900">
      {{ labelName }}
    </label>
  </div>
</div>
