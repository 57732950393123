<div class="container">
  <div class="bg-white">
    <div class="mx-auto text-center mb-5">
      <h2
        class="text-center p-2 font-bold text-3xl text-white bg-daradjaMagenta mb-5"
        i18n="@@FORM_TITLE"
      >
        {{ "FORM_TITLE" | translate }}
      </h2>
    </div>
    <!-- DEBUT FORMULAIRE STEPPER-->
    <app-stepper>
      <!-- DEBUT FIRST STEP -->
      <cdk-step>
        <app-contact-form [contactForm]="contactForm"></app-contact-form>
      </cdk-step>
      <!-- FIN FIRST STEP -->

      <!-- DEBUT SECOND STEP -->
      <cdk-step>
        <form [formGroup]="roleSelectionForm">
          <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">
            <div class="col-span-full">
              <fieldset>
                <legend
                  class="text-sm font-semibold leading-6 text-gray-900"
                  i18n="@@FORM_ROLE"
                >
                  {{ "FORM_ROLE" | translate }}
                </legend>

                <div class="mt-6 space-y-6">
                  <div class="flex items-center gap-x-3">
                    <input
                      id="studentRole"
                      name="selectedRole"
                      type="radio"
                      class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                      value="ROLE_STUDENT"
                      formControlName="selectedRole"
                    />
                    <label
                      for="studentRole"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      i18n="@@FORM_STUDENT"
                    >
                      {{ "FORM_STUDENT" | translate }}</label
                    >
                  </div>
                  <div class="flex items-center gap-x-3">
                    <input
                      id="mentorRole"
                      name="selectedRole"
                      type="radio"
                      class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                      value="ROLE_MENTOR"
                      formControlName="selectedRole"
                    />
                    <label
                      for="mentorRole"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      i18n="@@FORM_MENTOR"
                    >
                      {{ "FORM_MENTOR" | translate }}</label
                    >
                  </div>
                  <div class="flex items-center gap-x-3">
                    <input
                      id="tutorRole"
                      name="selectedRole"
                      type="radio"
                      class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                      value="ROLE_TUTOR"
                      formControlName="selectedRole"
                    />
                    <label
                      for="tutorRole"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      i18n="@@FORM_TUTOR"
                    >
                      {{ "FORM_TUTOR" | translate }}</label
                    >
                  </div>
                </div>
              </fieldset>
            </div>
            <button
              cdkStepperPrevious
              class="btn text-white bg-daradjaMagenta rounded"
              i18n="@@FORM_PREVIOUS"
            >
              {{ "FORM_PREVIOUS" | translate }}
            </button>
            <button
              cdkStepperNext
              class="btn text-white bg-daradjaMagenta rounded"
              [ngClass]="{
                'bg-daradjaDisabled': !roleSelectionForm.valid,
                'bg-daradjaMagenta': roleSelectionForm.valid
              }"
              [disabled]="!roleSelectionForm.valid"
              i18n="@@FORM_NEXT"
            >
              {{ "FORM_NEXT" | translate }}
            </button>
          </div>
        </form>
      </cdk-step>
      <!-- FIN SECOND STEP -->

      <!-- DEBUT THIRD STEP -->
      <cdk-step>
        <app-student-form
          [roleSelectionForm]="roleSelectionForm"
          [qualifications]="qualifications"
          [contactForm]="contactForm"
        ></app-student-form>
        <app-mentor-form
          [roleSelectionForm]="roleSelectionForm"
          [qualifications]="qualifications"
          [contactForm]="contactForm"
        ></app-mentor-form>
        <app-tutor-form
          [roleSelectionForm]="roleSelectionForm"
          [qualifications]="qualifications"
          [contactForm]="contactForm"
        ></app-tutor-form>
      </cdk-step>
      <!-- FIN THIRD STEP -->
    </app-stepper>
  </div>
</div>
