<!-- Début Formulaire -->
<form [formGroup]="contactForm" class="mx-auto">
  <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">
    <!-- Début Prénom -->
    <div class="sm:col-span-3">
      <label
        for="first-name"
        i18n="@@FORM_STUDENT_FIRST_NAME"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_FIRST_NAME" | translate
        }}<span class="text-daradjaAlert">*</span></label
      >
      <div class="mt-2.5">
        <input
          type="text"
          name="firstName"
          id="firstName"
          placeholder="Jean"
          formControlName="firstName"
          autocomplete="given-name"
          [(ngModel)]="person.firstname"
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
        <div
          *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)"
          class="text-daradjaAlert"
        >
          <div
            i18n="@@FORM_STUDENT_FIRST_NAME_REQUIRED"
            *ngIf="firstName?.errors?.['required']"
          >
            {{ "FORM_STUDENT_FIRST_NAME_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Prénom -->
    <!-- Début Nom -->
    <div class="sm:col-span-3">
      <label
        for="last-name"
        i18n="@@FORM_STUDENT_LAST_NAME"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_LAST_NAME" | translate
        }}<span class="text-daradjaAlert">*</span></label
      >
      <div class="mt-2.5">
        <input
          type="text"
          name="lastName"
          id="lastName"
          placeholder="Jean"
          formControlName="lastName"
          [(ngModel)]="person.lastname"
          autocomplete="family-name"
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
        <div
          *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)"
          class="text-daradjaAlert"
        >
          <div
            i18n="@@FORM_STUDENT_LAST_NAME_REQUIRED"
            *ngIf="lastName?.errors?.['required']"
          >
            {{ "FORM_STUDENT_LAST_NAME_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Nom -->
    <!-- Début Email -->
    <div class="sm:col-span-6">
      <label
        for="email"
        i18n="@@FORM_STUDENT_EMAIL"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_EMAIL" | translate
        }}<span class="text-daradjaAlert">*</span></label
      >
      <div class="mt-2.5">
        <input
          type="email"
          name="email"
          placeholder="ecrire@daradja.fr"
          id="email"
          formControlName="email"
          [(ngModel)]="person.email"
          autocomplete="email"
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
        <div
          *ngIf="email?.invalid && (email?.dirty || email?.touched)"
          class="text-daradjaAlert"
        >
          <div
            i18n="@@FORM_STUDENT_EMAIL_REQUIRED"
            *ngIf="email?.errors?.['required']"
          >
            {{ "FORM_STUDENT_EMAIL_REQUIRED" | translate }}
          </div>

          <div
            i18n="@@FORM_STUDENT_EMAIL_WRONG_FORMAT"
            *ngIf="email?.errors?.['email']"
          >
            {{ "FORM_STUDENT_EMAIL_WRONG_FORMAT" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Email -->
    <!-- Début Numéro de téléphone -->
    <div class="sm:col-span-6">
      <label
        for="phone"
        i18n="@@FORM_STUDENT_PHONE_NUMBER"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_PHONE_NUMBER" | translate }}</label
      >
      <div class="mt-2.5">
        <input
          type="tel"
          name="phone"
          placeholder="0607080910"
          id="phone"
          formControlName="phone"
          [(ngModel)]="person.phone"
          autocomplete="tel"
          class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <!-- Fin Numéro de téléphone -->
    <!-- Début Genre -->
    <div class="col-span-full">
      <fieldset>
        <legend
          class="text-sm font-semibold leading-6 text-gray-900"
          i18n="@@FORM_STUDENT_GENDER"
        >
          {{ "FORM_STUDENT_GENDER" | translate
          }}<span class="text-daradjaAlert">*</span>
        </legend>

        <div class="mt-6 space-y-6">
          <div class="flex items-center gap-x-3">
            <input
              id="girlGender"
              name="gender"
              type="radio"
              class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              value="FEMALE"
              formControlName="gender"
              [(ngModel)]="person.gender"
            />
            <label
              for="girlGender"
              i18n="@@FORM_STUDENT_GIRL"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              {{ "FORM_STUDENT_GIRL" | translate }}</label
            >
          </div>
          <div class="flex items-center gap-x-3">
            <input
              id="boyGender"
              name="gender"
              type="radio"
              class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              value="MALE"
              formControlName="gender"
              [(ngModel)]="person.gender"
            />
            <label
              for="boyGender"
              class="block text-sm font-medium leading-6 text-gray-900"
              i18n="@@FORM_STUDENT_BOY"
            >
              {{ "FORM_STUDENT_BOY" | translate }}</label
            >
          </div>
          <div class="flex items-center gap-x-3">
            <input
              id="otherGender"
              name="gender"
              type="radio"
              class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              value="OTHER"
              formControlName="gender"
              [(ngModel)]="person.gender"
            />
            <label
              for="otherGender"
              i18n="@@FORM_STUDENT_OTHER"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              {{ "FORM_STUDENT_OTHER" | translate }}</label
            >
          </div>
        </div>
      </fieldset>
      <div *ngIf="gender?.invalid" class="text-daradjaAlert">
        <div
          i18n="@@FORM_STUDENT_GENDER_REQUIRED"
          *ngIf="gender?.errors?.['required']"
        >
          {{ "FORM_STUDENT_GENDER_REQUIRED" | translate }}
        </div>
      </div>
    </div>
    <!-- Fin Genre -->
    <!-- Début Date de naissance -->
    <div *ngIf="createMode" class="sm:col-span-3 sm:col-start-1">
      <label
        for="birthDate"
        i18n="@@FORM_STUDENT_BIRTHDATE"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_BIRTHDATE" | translate }}</label
      >
      <div class="mt-2">
        <app-date-picker
          [selectedDate]="getDateOfBirth()"
          (selectedDateChange)="updateDateOfBirth($event)"
          [startYear]="startYearBirth()"
          [endYear]="endYearBirth()"
        ></app-date-picker>
      </div>
    </div>
    <!-- Fin Date de naissance -->
    <!-- Début Date d’arrivée en France -->
    <div *ngIf="createMode" class="sm:col-span-3">
      <label
        for="arrivalDate"
        i18n="@@FORM_STUDENT_ARRIVAL_DATE"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_ARRIVAL_DATE" | translate }}</label
      >
      <div class="mt-2">
        <app-date-picker
          [selectedDate]="getDateArrival()"
          (selectedDateChange)="updateDateArrival($event)"
          [startYear]="startYearArrival()"
          [endYear]="endYearArrival()"
        ></app-date-picker>
      </div>
    </div>
    <!-- Fin Date d’arrivée en France -->
    <!-- Début addresse postale -->
    <div class="col-span-full">
      <label
        for="postalAddress"
        i18n="@@FORM_STUDENT_POSTAL_ADDRESS"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_POSTAL_ADDRESS" | translate
        }}<span class="text-daradjaAlert">*</span></label
      >
      <div class="mt-2">
        <input
          type="text"
          name="postalAddress"
          id="postalAddress"
          formControlName="postalAddress"
          *ngIf="person.address"
          [(ngModel)]="person.address.postalAddress"
          placeholder="64 Rue Vauvenargues"
          autocomplete="street-address"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
        <div
          *ngIf="
            postalAddress?.invalid &&
            (postalAddress?.dirty || postalAddress?.touched)
          "
          class="text-daradjaAlert"
        >
          <div
            i18n="@@FORM_STUDENT_POSTAL_ADDRESS_REQUIRED"
            *ngIf="postalAddress?.errors?.['required']"
          >
            {{ "FORM_STUDENT_POSTAL_ADDRESS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- Fin addresse postale -->
    <!-- Début Complément addresse -->
    <div class="col-span-full">
      <label
        for="additionalAddress"
        i18n="@@FORM_STUDENT_COMPLEMENT_ADDRESS"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_COMPLEMENT_ADDRESS" | translate }}</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="additionalAddress"
          id="additionalAddress"
          formControlName="additionalAddress"
          placeholder="Bâtiment A, Étage 5, Porte 52"
          autocomplete="street-address"
          *ngIf="person.address"
          [(ngModel)]="person.address.additionalAddress"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <!-- Fin Complément addresse -->
    <!-- Début Ville -->
    <div class="sm:col-span-2 sm:col-start-1">
      <label
        for="city"
        i18n="@@FORM_STUDENT_CITY"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_CITY" | translate }}</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="city"
          id="city"
          placeholder="Paris"
          formControlName="city"
          *ngIf="person.address"
          [(ngModel)]="person.address.city"
          autocomplete="address-level2"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <!-- Fin Ville -->
    <!-- Début État / Province / Région -->
    <div class="sm:col-span-2">
      <label
        for="region"
        i18n="@@FORM_STUDENT_REGION"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_REGION" | translate }}</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="region"
          formControlName="region"
          *ngIf="person.address"
          [(ngModel)]="person.address.region"
          placeholder="Ile de France"
          id="region"
          autocomplete="address-level1"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <!-- Fin État / Province / Région -->
    <!-- Début Code postal -->
    <div class="sm:col-span-2">
      <label
        for="postal-code"
        i18n="@@FORM_STUDENT_POSTAL_CODE"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_POSTAL_CODE" | translate }}</label
      >
      <div class="mt-2">
        <input
          type="text"
          name="zipCode"
          id="zipCode"
          placeholder="75018"
          formControlName="zipCode"
          *ngIf="person.address"
          [(ngModel)]="person.address.zipCode"
          autocomplete="postal-code"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        />
      </div>
    </div>
    <!-- Fin Code postal -->
    <!-- Début Pays -->
    <div class="col-span-full">
      <label
        for="country"
        i18n="@@FORM_STUDENT_COUNTRY"
        class="block text-sm font-semibold leading-6 text-gray-900"
      >
        {{ "FORM_STUDENT_COUNTRY" | translate }}</label
      >
      <div class="mt-2">
        <select
          type="text"
          name="country"
          id="country"
          formControlName="country"
          *ngIf="person.address"
          [(ngModel)]="person.address.country"
          placeholder="Bâtiment A, Étage 5, Porte 52"
          autocomplete="country-name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
        >
          <option *ngFor="let country of countries" [ngValue]="country">
            {{ country }}
          </option>
        </select>
      </div>
    </div>
    <!-- Fin Pays -->
    <button
      *ngIf="createMode"
      cdkStepperNext
      class="btn bg-daradjaMagenta text-white rounded"
      [disabled]="!contactForm.valid"
      [ngClass]="{
        'bg-daradjaDisabled': !contactForm.valid,
        'bg-daradjaMagenta': contactForm.valid
      }"
      i18n="@@FORM_NEXT"
    >
      {{ "FORM_NEXT" | translate }}
    </button>
  </div>
</form>
