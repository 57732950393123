<div class="container">
  <h1 class="font-title text-center p-2 text-3xl text-white bg-daradjaMagenta" i18n="@@DOCUMENT_TITLE">
    {{ "DOCUMENT_TITLE" | translate }}
  </h1>

  <!-- Premier pdf -->
  <div class="flex flex-col mx-auto my-5 md:flex-row">
    <div class="w-full md:w-1/2">
      <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@DOCUMENT_SUBTITLE_PDF_1">
        {{ "DOCUMENT_SUBTITLE_PDF_1" | translate }}
      </h2>
      <a
        href="/assets/pdf/Rapport_moral_2019.pdf"
        download="Rapport_moral_2019.pdf"
        i18n="@@DOCUMENT_DOWNLOAD"
        class="bg-daradjaMagenta text-white text-center ml-5 px-4 py-2 rounded-md inline-block hover:bg-daradjaMain">
        {{ "DOCUMENT_DOWNLOAD" | translate }}</a>

        <pdf-viewer
          [src]="urlPdf1"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [page]="pageNumberPdf1"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          style="height: 800px"
          (after-load-complete)="afterLoadComplete($event, 1)"
          (pageChange)="afterPageChange($event, 1)">
        </pdf-viewer>

      <div class="text-center mt-3">
        <label for="pageNumber">{{ "DOCUMENT_PAGE" | translate }} </label>
          <input
            min="1"
            max="{{ totalPages1 }}"
            type="number"
            id="pageNumberPdf1"
            class="rounded"
            [(ngModel)]="pageNumberPdf1"
            step="1"
            pattern="[0-9]+"
            oninput="this.value = this.value.replace(/[^0-9]/g, '');"/>
        <label >
          {{ "DOCUMENT_ON" | translate }}<span></span> {{ totalPages1 }}
        </label>
      </div>
    </div>

    <!-- Second pdf -->
    <div class=" w-full md:w-1/2 mt-4 md:mt-0">
      <h2 class="font-semibold text-3xl my-5 text-daradjaMagenta" i18n="@@DOCUMENT_SUBTITLE_PDF_2">
        {{ "DOCUMENT_SUBTITLE_PDF_2" | translate }}
      </h2>
      <a
        href="/assets/pdf/Rapport_moral_2018.pdf"
        download="Rapport_moral_2018.pdf"
        i18n="@@DOCUMENT_DOWNLOAD"
        class="bg-daradjaMagenta text-white text-center ml-5 px-4 py-2 rounded-md inline-block hover:bg-daradjaMain">
       {{ "DOCUMENT_DOWNLOAD" | translate }}</a>
  
        <pdf-viewer
          [src]="urlPdf2"
          [rotation]="0"
          [original-size]="false"
          [show-all]="true"
          [fit-to-page]="false"
          [page]="pageNumberPdf2"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="false"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          style="height: 800px"
          (after-load-complete)="afterLoadComplete($event, 2)"
          (pageChange)="afterPageChange($event, 2)">
        </pdf-viewer>

      <div class="text-center mt-3">
        <label for="pageNumber">{{ "DOCUMENT_PAGE" | translate }} </label>
        <input
          min="1"
          max="{{ totalPages2 }}"
          type="number"
          id="pageNumberPdf2"
          class="rounded"
          [(ngModel)]="pageNumberPdf2"
          step="1"
          pattern="[0-9]+"
          oninput="this.value = this.value.replace(/[^0-9]/g, '');"/>
        <label>
          {{ "DOCUMENT_ON" | translate }}<span></span> {{ totalPages2 }}
        </label>
      </div>
    </div>
</div>