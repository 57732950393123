import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'projects/front-end/src/environments/environment';

export const maintenanceGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const allOk = !environment.isUnderMaintenance;

  if (!allOk) {
    router.navigate(['/maintenance']);
  }

  return allOk;
};
