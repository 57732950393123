import { Component, ContentChild, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ErrorValidationLabelComponent } from '../../components/error-validation-label/error-validation-label.component';
import { CommonModule, NgIf } from '@angular/common';
import { EnvoiModalService } from '../../components/envoi-modal/envoi-modal.service';
import { UserModule } from '../../services/user/user.module';
import { PasswordStrengthEnum } from '../../constants/password-strengh.enum';
import { Router } from '@angular/router';
import { UserCustomService } from '../../services/user/user-custom.service';
import { MatIconModule } from '@angular/material/icon';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { StorageService } from '../../services/authentication/storage.service';
import { JwtHelperService } from '../../services/helpers/jwt-helper.service';

@Component({
  selector: 'app-changement-mdp',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ErrorValidationLabelComponent,
    UserModule,
    MatIconModule,
  ],
  templateUrl: './changement-mdp.component.html',
  styleUrl: './changement-mdp.component.scss',
})
export class ChangementMdpComponent {
  @Input() token: string | null;
  form: FormGroup;
  oldPassword: string = '';
  newPassword: string = '';
  newPasswordVerify: string = '';
  dataModal: any;
  strengthEnum: PasswordStrengthEnum;
  strengthLabel = '';
  showPassword = false;
  inputType = 'password';
  isLoggedIn = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserCustomService,
    private envoiModalService: EnvoiModalService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private jwtHelperService: JwtHelperService,
  ) {
    this.dataModal = {
      titre: 'MODAL_FORM_UPDATE_TITLE',
      successContent: 'MODAL_CHANGE_PWD_SUCCESS',
      errorContent: 'MODAL_FORM_SUBMIT_ERROR',
    };

    this.form = this.fb.group({
      oldPassword: [this.oldPassword],
      newPassword: [
        this.newPassword,
        [Validators.required, Validators.minLength(12)],
      ],
      newPasswordVerify: [
        this.newPasswordVerify,
        [Validators.required, Validators.minLength(12)],
      ],
    });

    this.strengthEnum = PasswordStrengthEnum.WEAK;
    this.token = null;
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    if (this.isLoggedIn)
      this.form.get('oldPassword')?.addValidators([Validators.required]);
  }

  ngOnInit() {
    let tokenSession: string | null = null;
    if (!this.token) tokenSession = this.storageService.getToken();
    if (!this.token && !tokenSession) this.router.navigate(['/forbidden']);
  }

  submit() {
    if (!this.token) this.dataModal.successContent = 'MODAL_CHANGE_PWD_SUCCESS';
    this.envoiModalService.open(this.dataModal);
    let successHandler = {
      next: () => {
        this.envoiModalService.showSuccess();
      },
      error: () => this.envoiModalService.showError(),
    };
    if (this.token) {
      let user = {
        token: this.token,
        password: this.newPassword,
      };

      this.userService.resetPasswordCustom(user).subscribe(successHandler);
    } else {
      let user = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      this.userService.changePasswordCustom(user).subscribe(successHandler);
    }
  }

  passwordMissmatch(): boolean {
    return (
      this.newPasswordVerify !== '' &&
      this.newPassword !== this.newPasswordVerify
    );
  }

  newPasswordIsTheSame(): boolean {
    return this.newPassword !== '' && this.newPassword === this.oldPassword;
  }

  isSubmitDisabled(): boolean {
    return (
      this.passwordMissmatch() ||
      !this.form.valid ||
      this.strengthEnum === PasswordStrengthEnum.WEAK
    );
  }

  get getOldPasswordControl() {
    return this.form.get('oldPassword');
  }

  get getNewPasswordControl() {
    return this.form.get('newPassword');
  }

  get getNewPasswordVerifyControl() {
    return this.form.get('newPasswordVerify');
  }
  onPasswordInput(event: any) {
    const password = event.target.value;
    this.evaluatePasswordStrength(password);
  }

  evaluatePasswordStrength(password: string) {
    let strength = 0;
    if (password.length >= 8) {
      strength += 1;
    }
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }
    if (/[a-z]/.test(password)) {
      strength += 1;
    }
    if (/[0-9]/.test(password)) {
      strength += 1;
    }
    if (/[\W]/.test(password)) {
      strength += 1;
    }

    switch (strength) {
      case 3:
        this.strengthEnum = PasswordStrengthEnum.MODERATE;
        this.strengthLabel = 'PWD_MODERATE';
        break;
      case 4:
      case 5:
        this.strengthEnum = PasswordStrengthEnum.STRONG;
        this.strengthLabel = 'PWD_STRONG';
        break;
      default:
        this.strengthEnum = PasswordStrengthEnum.WEAK;
        this.strengthLabel = 'PWD_WEAK';
    }
  }

  PasswordStrengthEnum() {
    return PasswordStrengthEnum;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }
}
