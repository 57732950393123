import { Observable, Subject, of } from 'rxjs';
import { AppTutor } from '../../models/app-tutor.model';
import { Injectable } from '@angular/core';
import { MockHelperService } from '../helpers/mock-helper.service';

@Injectable({
  providedIn: 'root',
})
export class TutorMockDataService {
  private throwError = false;

  constructor(private mockHelperService: MockHelperService<AppTutor>) {}

  apiTutorsPost(tutor: AppTutor): Observable<AppTutor> {
    console.log(tutor);
    return this.mockHelperService.simuleAppel(this.throwError, tutor);
  }

  apiTutorsIdPut(id: string, tutor: AppTutor): Observable<AppTutor> {
    console.log(tutor);
    return this.mockHelperService.simuleAppel(this.throwError, tutor);
  }
}
