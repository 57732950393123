import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-speaker',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './speaker.component.html',
  styleUrl: './speaker.component.scss',
})
export class SpeakerComponent {
  @Input()
  name!: string;
  @Input()
  role!: string;
  @Input()
  imageUrl!: string;
}
