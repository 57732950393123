import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-envoi-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './envoi-modal.component.html',
  styleUrl: './envoi-modal.component.scss',
})
export class EnvoiModalComponent {
  titre = 'Envoi';
  showSpinner = true;
  success = false;
  successContent = 'Votre demande a été envoyée.';
  errorContent = 'Une erreur est survenue.';
  info = '';
  redirectAfterCloseUrl: string = '/accueil';

  constructor(private router: Router) {}

  showSuccess(redirectUrlOnModalClose?: string) {
    this.showSpinner = false;
    this.success = true;
    if (redirectUrlOnModalClose)
      this.redirectAfterCloseUrl = redirectUrlOnModalClose;
  }

  showError(redirectUrlOnModalClose?: string) {
    this.showSpinner = false;
    this.success = false;
    if (redirectUrlOnModalClose)
      this.redirectAfterCloseUrl = redirectUrlOnModalClose;
  }

  close() {
    this.router.navigate([this.redirectAfterCloseUrl]);
  }
}
