import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppPerson } from '../../models/app-person.model';
import { AppAddress } from '../../models/app-address.model';
import { RandomStringService } from './random-string.service';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  dataModal = {
    titre: 'MODAL_FORM_SUBMIT_TITLE',
    successContent: 'MODAL_FORM_SUBMIT_SUCCESS',
    errorContent: 'MODAL_FORM_SUBMIT_ERROR',
    info: 'MODAL_FORM_SUBMIT_INFO',
  };
  constructor(private randomStringService: RandomStringService) {}

  createData(contactForm: FormGroup, roleSelectionForm: FormGroup): any {
    if (contactForm.valid && roleSelectionForm.valid) {
      const selectedRole = roleSelectionForm.get('selectedRole')?.value;
      const personData: AppPerson = {
        firstname: contactForm.get('firstName')?.value,
        lastname: contactForm.get('lastName')?.value,
        birthAt: contactForm.get('birthAt')?.value,
        gender: contactForm.get('gender')?.value,
        phone: contactForm.get('phone')?.value,
        arrivedFrenchAt: contactForm.get('arrivedFrenchAt')?.value,
        email: contactForm.get('email')?.value,
        updatedAt: new Date(),
        createdAt: new Date(),
        roles: [selectedRole],
        activated: true,
      };
      console.log(personData);

      const addressData: AppAddress = {
        postalAddress: contactForm.get('postalAddress')?.value,
        additionalAddress: contactForm.get('additionalAddress')?.value,
        city: contactForm.get('city')?.value,
        zipCode: contactForm.get('zipCode')?.value,
        region: contactForm.get('region')?.value,
        country: contactForm.get('country')?.value,
      };

      let combinedData = {
        address: addressData,
        ...personData,
      };
      return combinedData;
    }
    throw Error('Erreur, formulaire inavlide');
  }
}
