import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
//import { PdfViewControl } from '../utils/PdfViewControl.interface';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [PdfViewerModule, FormsModule, TranslateModule],
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
})
export class DocumentsComponent {
  urlPdf1 = '/assets/pdf/Rapport_moral_2019.pdf';
  urlPdf2 = '/assets/pdf/Rapport_moral_2018.pdf';

  pageNumberPdf1: number = 1;
  pageNumberPdf2: number = 1;
  totalPages1: number = 1;
  totalPages2: number = 1;

  afterLoadComplete(pdf: PDFDocumentProxy, pdfNumber: number) {
    if (pdfNumber === 1) {
      this.totalPages1 = pdf.numPages;
    } else if (pdfNumber === 2) {
      this.totalPages2 = pdf.numPages;
    }
  }

  afterPageChange(event: any, pdfNumber: number) {
    if (pdfNumber === 1) {
      this.pageNumberPdf1 = event;
    } else if (pdfNumber === 2) {
      this.pageNumberPdf2 = event;
    }
  }
  ngOnInit() {}
}
