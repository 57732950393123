import { NgModule } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StudentMockDataService } from './student-mock-data.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '../interceptor/authentication-interceptor';
import { IStudentService } from './I-student.service';
import { StudentService } from '../api/student.service';

@NgModule({
  providers: [
    {
      provide: IStudentService,
      useClass: environment.useMockData
        ? StudentMockDataService
        : StudentService,
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ],
})
export class StudentModule {}
