<form [formGroup]="form">
  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto h-30 w-auto"
        src="assets/images/Logo-Daradja-Moyen.png"
        alt="Your Company"
      />
      <h2
        class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
        i18n="@@CHANGE_PWD_CHANGE"
      >
        {{ "CHANGE_PWD_CHANGE" | translate }}
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div *ngIf="isLoggedIn">
        <label
          for="oldpassword"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_OLD"
          >{{ "CHANGE_PWD_OLD" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="oldpassword"
            name="oldpassword"
            [type]="inputType"
            [(ngModel)]="oldPassword"
            formControlName="oldPassword"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>

        <app-error-validation-label
          [control]="getOldPasswordControl"
        ></app-error-validation-label>
      </div>
      <div>
        <label
          for="newpassword"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW"
          >{{ "CHANGE_PWD_NEW" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newpassword"
            name="newpassword"
            [type]="inputType"
            [(ngModel)]="newPassword"
            formControlName="newPassword"
            required
            (input)="onPasswordInput($event)"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
        <div
          *ngIf="strengthEnum"
          [ngClass]="{
            'text-daradjaAlert': strengthEnum === PasswordStrengthEnum().WEAK,
            'text-daradjaYellow':
              strengthEnum === PasswordStrengthEnum().MODERATE,
            'text-daradjaSuccess':
              strengthEnum === PasswordStrengthEnum().STRONG
          }"
        >
          Force du mot de passe : {{ strengthLabel | translate }}
        </div>
        <app-error-validation-label
          [control]="getNewPasswordControl"
        ></app-error-validation-label>
        <div class="text-daradjaAlert" *ngIf="newPasswordIsTheSame()">
          {{ "IS_SAME_PWD" | translate }}
        </div>
      </div>
      <div>
        <label
          for="newPasswordVerify"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW_VERIFY"
          >{{ "CHANGE_PWD_NEW_VERIFY" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newPasswordVerify"
            name="newPasswordVerify"
            [type]="inputType"
            [(ngModel)]="newPasswordVerify"
            formControlName="newPasswordVerify"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <app-error-validation-label
        [control]="getNewPasswordVerifyControl"
      ></app-error-validation-label>
      <div class="text-daradjaAlert" *ngIf="passwordMissmatch()">
        {{ "MISSMATCH_PWD" | translate }}
      </div>
      <div class="mt-2">
        <button
          (click)="toggleShowPassword()"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <svg
            *ngIf="!showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>

          <svg
            *ngIf="showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        </button>
      </div>
      <div class="mt-2">
        <button
          i18n="@@FORM_SUBMIT"
          type="submit"
          (click)="submit()"
          [disabled]="isSubmitDisabled()"
          [ngClass]="{
            'bg-daradjaDisabled': isSubmitDisabled(),
            'bg-daradjaMain': !isSubmitDisabled()
          }"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          {{ "FORM_SUBMIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
