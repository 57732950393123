import { AutoMap } from '@automapper/classes';
import { AppAddress } from './app-address.model';
import { AppPerson } from './app-person.model';
import { MentorProgramEnum } from '../constants/mentor-program.enum';

export class AppStudent extends AppPerson {
  @AutoMap()
  bacCountry?: string;
  @AutoMap()
  lastDiploma?: string;
  @AutoMap()
  titleDiploma?: string;
  @AutoMap()
  school?: string;
  @AutoMap()
  preparedDiploma?: string;
  @AutoMap()
  futureWork?: string;
  @AutoMap()
  comment?: string;
  studentBags?: StudentBag[];
  @AutoMap()
  mentorProgram?: MentorProgramEnum;

  emailContact: boolean = false;
  @AutoMap()
  telContact?: boolean;
}

export class StudentBag {
  name?: string;
  value?: string;
  student?: string;
}
