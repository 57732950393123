<div *ngIf="roleSelectionForm.get('selectedRole')!.value === 'ROLE_TUTOR'">
  <!-- Début Formulaire -->
  <form [formGroup]="tutorForm" class="mx-auto">
    <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-6">
      <!-- Début Université / Etablissement actuel -->
      <div class="col-span-full">
        <label
          for="school"
          i18n="@@FORM_STUDENT_UNIVERSITY"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_STUDENT_UNIVERSITY" | translate }}</label
        >
        <div class="mt-2.5">
          <input
            type="text"
            name="school"
            id="school"
            placeholder="Université Sorbonne Paris Nord"
            formControlName="school"
            autocomplete="family-name"
            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin Université / Etablissement actuel -->
      <!-- Début discipline -->
      <div class="col-span-full">
        <label
          for="discipline"
          i18n="@@FORM_TUTOR_DISCIPLINE"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_TUTOR_DISCIPLINE" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="discipline"
            id="discipline"
            formControlName="subject"
            placeholder="Philosophie"
            autocomplete="discipline"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin discipline -->
      <!-- Début Dernier diplôme obtenu -->
      <div class="col-span-full">
        <label
          for="lastDiploma"
          i18n="@@FORM_STUDENT_LAST_QUALIFICATION"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_STUDENT_LAST_QUALIFICATION" | translate }}</label
        >
        <div class="mt-2">
          <select
            type="text"
            name="lastDiploma"
            id="lastDiploma"
            formControlName="lastDiploma"
            autocomplete="country-name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          >
            <option
              *ngFor="let qualification of qualifications"
              value="{{ qualification }}"
            >
              {{ qualification | translate }}
            </option>
          </select>
        </div>
      </div>
      <!-- Fin Dernier diplôme obtenu -->
      <!-- Début vie en France -->
      <div class="col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_TUTOR_ALWAYS_LIVED_FRANCE"
          >
            {{ "FORM_TUTOR_ALWAYS_LIVED_FRANCE" | translate }}
          </legend>

          <div class="mt-6 space-y-6">
            <div class="flex items-center gap-x-3">
              <input
                id="yes-lived"
                name="yes-lived"
                type="radio"
                [formControlName]="alwaysLivedInFrance"
                value="true"
                class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              />
              <label
                for="yes-lived"
                i18n="@@FORM_TUTOR_ALWAYS_LIVED_FRANCE_YES"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {{ "FORM_TUTOR_ALWAYS_LIVED_FRANCE_YES" | translate }}</label
              >
            </div>
            <div class="flex items-center gap-x-3">
              <input
                id="no-lived"
                name="no-lived"
                type="radio"
                [formControlName]="alwaysLivedInFrance"
                value="false"
                class="h-4 w-4 border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
              />
              <label
                i18n="@@FORM_TUTOR_ALWAYS_LIVED_FRANCE_NO"
                for="no-lived"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {{ "FORM_TUTOR_ALWAYS_LIVED_FRANCE_NO" | translate }}</label
              >
            </div>
          </div>
        </fieldset>
      </div>
      <!-- Fin vie en France -->
      <!-- Début pays lontemps vécu -->
      <div class="col-span-full">
        <label
          for="countryLivedLongest"
          i18n="@@FORM_TUTOR_WHERE_LIVED_LONGEST"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_TUTOR_WHERE_LIVED_LONGEST" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="countryLivedLongest"
            id="countryLivedLongest"
            formControlName="longestCountry"
            placeholder="Burkina Faso"
            autocomplete="countryLivedLongest"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin pays lontemps vécu -->
      <!-- Début quelque chose à ajouter-->
      <div class="col-span-full">
        <label
          for="remarque"
          i18n="@@FORM_TUTOR_SOMETHING_TO_ADD"
          class="block text-sm font-semibold leading-6 text-gray-900"
        >
          {{ "FORM_TUTOR_SOMETHING_TO_ADD" | translate }}</label
        >
        <div class="mt-2">
          <input
            type="text"
            name="remarque"
            id="remarque"
            formControlName="comment"
            autocomplete="remarque"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMagenta sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <!-- Fin quelque chose à ajouter -->
      <!-- Début Domaines dans lesquels vous pouvez aider -->
      <div class="col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_TUTOR_AREA_CAN_HELP"
          >
            {{ "FORM_TUTOR_AREA_CAN_HELP" | translate }}
          </legend>
          <div class="mt-6 space-y-6">
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="meetFriends"
                  name="meetFriends"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                  formControlName="meetFriends"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  i18n="@@FORM_TUTOR_MEET_FRIENDS"
                  for="comments"
                  class="font-medium text-gray-900"
                >
                  {{ "FORM_TUTOR_MEET_FRIENDS" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="administrativeFormalities"
                  name="administrativeFormalities"
                  formControlName="administrativeFormalities"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="administrativeFormalities"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_ADMINISTRATIVE_FORMALITIES"
                >
                  {{ "FORM_TUTOR_ADMINISTRATIVE_FORMALITIES" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="logement"
                  name="logement"
                  formControlName="logement"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="logement"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_HOUSING"
                >
                  {{ "FORM_TUTOR_HOUSING" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="budget"
                  name="budget"
                  formControlName="budget"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="budget"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_BUDGET"
                >
                  {{ "FORM_TUTOR_BUDGET" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="
                              "
                  name="health"
                  formControlName="health"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="health"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_HEALTH"
                >
                  {{ "FORM_TUTOR_HEALTH" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="lessons"
                  name="lessons"
                  formControlName="lessons"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="lessons"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_LESSONS"
                >
                  {{ "FORM_TUTOR_LESSONS" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="trainingStudies"
                  name="trainingStudies"
                  formControlName="trainingStudies"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="trainingStudies"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_TRAINING_STUDIES"
                >
                  {{ "FORM_TUTOR_TRAINING_STUDIES" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="professionalProject"
                  name="professionalProject"
                  formControlName="professionalProject"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="professionalProject"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_PROFESSIONAL_PROJECT"
                >
                  {{ "FORM_TUTOR_PROFESSIONAL_PROJECT" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="findInternship"
                  name="findInternship"
                  formControlName="findInternship"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="findInternship"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_FIND_INTERNSHIP"
                >
                  {{ "FORM_TUTOR_FIND_INTERNSHIP" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="studentJob"
                  name="studentJob"
                  formControlName="studentJob"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="studentJob"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_STUDENT_JOB"
                >
                  {{ "FORM_TUTOR_STUDENT_JOB" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="playSport"
                  name="playSport"
                  formControlName="playSport"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="playSport"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_PLAY_SPORT"
                >
                  {{ "FORM_TUTOR_PLAY_SPORT" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="culturalActivities"
                  name="culturalActivities"
                  formControlName="culturalActivities"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="culturalActivities"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_CULTURAL_ACTIVITIES"
                >
                  {{ "FORM_TUTOR_CULTURAL_ACTIVITIES" | translate }}
                </label>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="other"
                  name="other"
                  formControlName="other"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="other"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_HELP_OTHER"
                >
                  {{ "FORM_TUTOR_HELP_OTHER" | translate }}
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <!-- Fin Domaines dans lesquels vous pouvez aider  -->
      <!-- Début Comment souhaitez-vous être recontacté·e par Daradja  -->
      <div class="col-span-full">
        <fieldset>
          <legend
            class="text-sm font-semibold leading-6 text-gray-900"
            i18n="@@FORM_TUTOR_CHOOSE_CONTACTED"
          >
            {{ "FORM_TUTOR_CHOOSE_CONTACTED" | translate }}
          </legend>
          <div class="mt-6 space-y-6">
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="contactByMail"
                  name="contactByMail"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                  formControlName="contactByMail"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  i18n="@@FORM_TUTOR_MAIL"
                  for="comments"
                  class="font-medium text-gray-900"
                >
                  {{ "FORM_TUTOR_MAIL" | translate }}
                </label>
                <p class="text-gray-500" i18n="@@FORM_TUTOR_WAY_MAIL_MORE">
                  {{ "FORM_TUTOR_WAY_MAIL_MORE" | translate }}
                </p>
              </div>
            </div>
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  id="contactByPhone"
                  name="contactByPhone"
                  formControlName="contactByPhone"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-daradjaMagenta focus:ring-daradjaMagenta"
                />
              </div>
              <div class="text-sm leading-6">
                <label
                  for="contactByPhone"
                  class="font-medium text-gray-900"
                  i18n="@@FORM_TUTOR_PHONE"
                >
                  {{ "FORM_TUTOR_PHONE" | translate }}</label
                >
                <p class="text-gray-500" i18n="@@FORM_TUTOR_WAY_PHONE_MORE">
                  {{ "FORM_TUTOR_WAY_PHONE_MORE" | translate }}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <!-- Fin Comment souhaitez-vous être recontacté·e par Daradja  -->

      <div>
        <label
          for="newpassword"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW"
          >{{ "CHANGE_PWD_NEW" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newpassword"
            name="newpassword"
            [type]="inputType"
            [(ngModel)]="newPassword"
            formControlName="newPassword"
            required
            (input)="onPasswordInput($event)"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
        <div
          *ngIf="strengthEnum"
          [ngClass]="{
            'text-daradjaAlert': strengthEnum === PasswordStrengthEnum().WEAK,
            'text-daradjaYellow':
              strengthEnum === PasswordStrengthEnum().MODERATE,
            'text-daradjaSuccess':
              strengthEnum === PasswordStrengthEnum().STRONG
          }"
        >
          Force du mot de passe : {{ strengthLabel | translate }}
        </div>
        <app-error-validation-label
          [control]="getNewPasswordControl"
        ></app-error-validation-label>
        <div class="text-daradjaAlert" *ngIf="newPasswordIsTheSame()">
          {{ "IS_SAME_PWD" | translate }}
        </div>
      </div>
      <div>
        <label
          for="newPasswordVerify"
          class="block text-sm font-medium leading-6 text-gray-900"
          i18n="@@CHANGE_PWD_NEW_VERIFY"
          >{{ "CHANGE_PWD_NEW_VERIFY" | translate }}</label
        >
        <div class="mt-2">
          <input
            id="newPasswordVerify"
            name="newPasswordVerify"
            [type]="inputType"
            [(ngModel)]="newPasswordVerify"
            formControlName="newPasswordVerify"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-daradjaMain sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <app-error-validation-label
        [control]="getNewPasswordVerifyControl"
      ></app-error-validation-label>
      <div class="text-daradjaAlert" *ngIf="passwordMissmatch()">
        {{ "MISSMATCH_PWD" | translate }}
      </div>
      <div class="mt-2">
        <button
          (click)="toggleShowPassword()"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <svg
            *ngIf="!showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>

          <svg
            *ngIf="showPassword"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="black"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        </button>
      </div>
      <!-- Début By selecting this, you agree to our privacy policy. -->
      <div class="flex gap-x-4 sm:col-span-6">
        <div class="flex h-6 items-center">
          <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
          <button
            type="button"
            class="bg-gray-200 flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            role="switch"
            [class.bg-daradjaMagenta]="agreeToPolicies"
            [class.bg-gray-200]="!agreeToPolicies"
            aria-checked="false"
            (click)="toggleAgreeToPolicies()"
          >
            <span class="sr-only">Agree to policies</span>
            <!-- Enabled: "translate-x-3.5", Not Enabled: "translate-x-0" -->
            <span
              aria-hidden="true"
              [ngClass]="{
                'translate-x-3.5': agreeToPolicies,
                'translate-x-0': !agreeToPolicies
              }"
              class="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
            ></span>
          </button>
        </div>
        <label
          class="text-sm leading-6 text-gray-600"
          id="switch-1-label"
          i18n="@@FORM_STUDENT_POLICIES"
        >
          {{ "FORM_STUDENT_POLICIES" | translate
          }}<span class="text-daradjaAlert">*</span>
        </label>
      </div>
      <!-- Fin By selecting this, you agree to our privacy policy. -->
      <button
        cdkStepperPrevious
        class="btn text-white bg-daradjaMagenta rounded"
        i18n="@@FORM_PREVIOUS"
      >
        {{ "FORM_PREVIOUS" | translate }}
      </button>
      <button
        type="submit"
        class="btn text-white rounded"
        [ngClass]="{
          'bg-daradjaDisabled': isSubmitDisabled(),
          'bg-daradjaMagenta': !isSubmitDisabled()
        }"
        [disabled]="isSubmitDisabled()"
        i18n="@@FORM_SUBMIT"
        (click)="onSubmit()"
      >
        {{ "FORM_SUBMIT" | translate }}
      </button>
    </div>
  </form>
</div>
